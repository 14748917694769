<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
  >
    <div class="left_title" style="cursor: auto;">
      <div class="title">{{ $t("Dashboard.BasicStatisticsCountryMarket") }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <el-button 
          size="small"
          :type="allStatus ? 'primary' : ''" 
          @click="allDataEvent()"
        >{{ $t('Dashboard.whole') }}</el-button>
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf()">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onBasicStatistics()"
        />
      </div>
    </div>
    <div class="basicBox">
      <div class="itemBox order">
        <div class="member">
          <span>{{ $tc('Dashboard.Orders', $isOrderTitle()) }}</span>
          {{ topData.order }}
        </div>
        <div class="rightContent">
          <div class="item">
            {{ isDefaultNode ? $t('Dashboard.Shipping') : $t("order.pending") }}
            <span>{{ topData.transport }}</span>
          </div>
          <div class="item" v-show="isDefaultNode">
            {{ $t('Dashboard.Producing') }}
            <span>{{ topData.production }}</span>
          </div>
          <div class="item">
            {{ $t('Dashboard.Completed') }}
            <span>{{ topData.completed }}</span>
          </div>
        </div>
      </div>
      <div class="itemBox total">
        <div class="member">
          <span>{{ !artConfig.isAmountNumberMode ? $t('Dashboard.Total') : $t('order.Quantity') }}</span>
          {{ topData.total }}
        </div>
        <div class="compare">
          <div class="item">
            <span :style="`color: ${topData.thanYear > 0 ? '#48AC42' : '#C84154'}`">
              <i class="iconfont icon-shangsheng" v-if="topData.thanYear > 0"></i>
              <i class="iconfont icon-xiajiang" v-else></i>
              {{ topData.thanYear }}%
            </span>
            <p>than {{ year }}</p>
          </div>
          <div class="item">
            <span :style="`color: ${topData.thanSeason > 0 ? '#48AC42' : '#C84154'}`">
              <i class="iconfont icon-shangsheng" v-if="topData.thanSeason > 0"></i>
              <i class="iconfont icon-xiajiang" v-else></i>
              {{ topData.thanSeason }}%
            </span>
            <p>than Q{{ season }}</p>
          </div>
        </div>
      </div>
      <div class="itemBox" v-if="type === 'country' || type === 'product'">
        <div class="title">
          <i class="iconfont icon-top3kehu"></i>
          {{
            companyCurrentType == "1" || companyCurrentType == "2"
              ? $t("Dashboard.MainCustomers")
              : $t("Dashboard.MainSupplier")
          }} top3
        </div>
        <div class="info">
          <div
            class="infoItem" 
            v-for="(item, index) in mainCustomerList" 
            :key="index"
            @click="goCustomerDetail(item)">
            <img
              :src="item.companyLogoUrl"
            />
            <p>{{item.aliasName}}</p>
          </div>
        </div>
      </div>
      <div class="itemBox" v-if="type === 'customer' || type === 'product'">
        <div class="title">
          <i class="iconfont icon-guojiatop"></i>
          {{ $t("Dashboard.MainCountry") }} top3
        </div>
        <div class="info">
          <div
            class="infoItem" 
            v-for="(item, index) in mainCountryList" 
            :key="index"
            @click="goCompanyDetail(item)">
            <img
              :src="item.nationalFlag"
              style="border-radius: 2px;height: auto;"
            />
            <p>{{item.countryCn}}</p>
          </div>
        </div>
      </div>
      <div class="itemBox" v-if="type !== 'product'">
        <div class="title">
          <i class="iconfont icon-a-retop1"></i>
          {{ $t("Dashboard.MainProducts") }} top3
        </div>
        <div class="info">
          <div
            class="infoItem product" 
            v-for="(item, index) in mainProductList" 
            :key="index"
            :title="`${item.itemNo} | ${item.type} | ${item.size}`">
            <p>{{item.itemNo}} | {{item.type}} | {{item.size}} </p>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { 
  basicStatistics, 
  getCountryTop3,
  getCompanyTop3,
  getCiSkuTop3
} from '@/api/chart';
import { formatNum, pickerOptions } from '@/utils/utils';

export default {
  name: "statistics",
  data() {
    return {
      allStatus: false,
      exportLoading: false,
      echartsViewId: "exportStatisticsMarketPdf",
      data: {
        country: '',
        customer: '',
        itemNo: '',
        type: '',
        size: '',
        time: []
      },
      topData: {},
      mainCustomerList: [],
      mainCountryList: [],
      mainProductList: [],
      chartLoading: false,
      year: dayjs().format("YYYY") - 1,
      season: Math.ceil(dayjs().format("MM") / 3) - 1 || 4,
    };
  },
  inject: ['getConfig', 'getTimeConfig'],
  props: {
    type: {
      type: String | Number,
      default: "",
    },
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.exportLoading = true;
        this.onBasicStatistics();
      },
      deep: true,
    },
    'artConfig.isAmountNumberMode'() {
      this.onBasicStatistics();
    },
  },
  computed: {
    ...mapGetters(['country', 'companyCurrentType', 'isDefaultNode']),
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  mounted() {

  },
  methods: {
    allDataEvent() {
      this.allStatus = !this.allStatus;
      if(this.allStatus) {
        this.data.time = [];
      } else {
        if(this.data.country) {
          this.data.time = this.getTimeConfig().globalTimeCountry;
        }
        if(this.data.customer) {
          this.data.time = this.getTimeConfig().globalTimeCustomer;
        }
        if(this.data.itemNo) {
          this.data.time = this.getTimeConfig().globalTimeProduct;
        }
      }
    },
    goCustomerDetail(row) {
      this.$router.push({
        name: 'Customer',
        query: {
          companyId: row.companyId
        },
      })
    },
    goCompanyDetail(row) {
      this.$router.push(`/dashboard/1?countryId=${row.iso3}`);
    },
    onBasicStatistics() {
      this.chartLoading = true;
      const { 
        time, 
        country, 
        customer, 
        itemNo,
        type,
        size,
      } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
      basicStatistics({
        country,
        customer,
        itemNo,
        type,
        size,
        startTime,
        endTime,
        queryType: this.artConfig.isAmountNumberMode
      })
        .then((data) => {
          this.chartLoading = false;
          let amount = [];
          for(let i in data.amount) {
            amount.push({
              key: i,
              value: data.amount[i]
            })
          }
          this.topData = {
            ...data,
            currencyAmountVos: amount,
            total: this.artConfig.isAmountNumberMode ? Number(data.totalAmount.USD) : formatNum(Number(data.totalAmount.USD).toFixed(2)),
            thanYear: Number(data.thanYear).toFixed(2),
            thanSeason: Number(data.thanQuarter).toFixed(2),
          };
        })
        .finally(() => (this.chartLoading = false));
      this.getTop3Data();
    },
    getTop3Data() {
      const { time } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
      if(this.type === 'country') {
        getCiSkuTop3({
          startTime,
          endTime,
          limit: 3,
          country: this.data.country
        }).then(data => {
          this.mainProductList = data;
        })
        getCompanyTop3({
          startTime,
          endTime,
          limit: 3,
          country: this.data.country
        }).then(data => {
          this.mainCustomerList = data;
        })
      }
      if(this.type === 'customer') {
        getCiSkuTop3({
          startTime,
          endTime,
          limit: 3,
          customer: this.data.customer
        }).then(data => {
          this.mainProductList = data;
        })
        getCountryTop3({
          startTime,
          endTime,
          limit: 3,
          customer: this.data.customer
        }).then(data => {
          this.mainCountryList = data;
        })
      }
      if(this.type === 'product') {
        getCompanyTop3({
          startTime,
          endTime,
          limit: 3,
          itemNo: this.data.itemNo,
          type: this.data.type,
          size: this.data.size
        }).then(data => {
          this.mainCustomerList = data;
        })
        getCountryTop3({
          startTime,
          endTime,
          limit: 3,
          itemNo: this.data.itemNo,
          type: this.data.type,
          size: this.data.size
        }).then(data => {
          this.mainCountryList = data;
        })
      }
    },
    //导出(pdf)
    savePdf() {
      this.$refs.echartsBtns.style.display = "none";
      setTimeout(() => {
        // setAttribute 需要一定时间生效
        html2canvas(document.getElementById("exportStatisticsMarketPdf"), {
          useCORS: true
        }).then((canvas) => {
          this.$refs.echartsBtns.style.removeProperty('display');
          const oImg = new Image();
          oImg.src = canvas.toDataURL(); // 导出图片
          let dataURL = canvas.toDataURL("image/png");
          // 下载图片
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = oImg.src;
          // 设置下载标题
          a.download = "我的文件";
          a.click();
        });
      }, 200);
    },
  },
};
</script>
<style lang="less" scoped>
.basicBox {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-template-rows: 178px 178px;
  grid-gap: 14px;
  margin-top: 16px;
  .itemBox {
    background: #F7F9FC;
    border-radius: 4px;
    padding: 16px;
    .member {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      line-height: 28px;
      color: #122545;
      font-weight: 600;
      span {
        font-size: 14px;
        line-height: 20px;
        color: #757D8A;
        font-weight: 400;
        margin-bottom: 4px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      gap: 2px;
      font-size: 12px;
      font-weight: 500;
      color: #637381;
      i {
        font-size: 18px;
        color: #FF8311;
      }
    }
    .info {
      margin-top: 20px;
      padding-left: 2.5px;
      .infoItem {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        line-height: 18px;
        color: #122545;
        font-weight: 500;
        margin-bottom: 20px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &.product {
          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #49AC42;
          }
        }
        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        p {
          flex: 1;
          min-width: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    &.order {
      .rightContent {
        margin-top: 17px;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          color: #122545;
          margin-bottom: 12px;
          white-space: nowrap;
          span {
            font-size: 14px;
            display: block;
            width: 50%;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            background: #076F49;
            border-radius: 50%;
          }
          &:nth-child(1) {
            &::before {
              background: #DC3545;
            }
          }
          &:nth-child(2) {
            &::before {
              background: #48AC42;
            }
          }
          &:nth-child(3) {
            &::before {
              background: #2E4793;
            }
          }
        }
      }
    }
    &.total {
      position: relative;
      .compare {
        position: absolute;
        bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 4px;
        .item {
          span {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
            .iconfont {
              font-size: 12px;
            }
            .icon-xiajiang {
              color: #C84154;
            }
            .icon-shangsheng {
              color: #48AC42;
            }
          }
          p {
            font-size: 12px;
            color: #757D8A;
          }
        }
      }
    }
  }
}
</style>
