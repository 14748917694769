<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">{{ $t("Dashboard.PMIIndexNumber") }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <div 
          class="screen" 
          style="margin-top: 0;margin-right: 10px;" 
          v-if="artConfig.isVipMode != 0">
          <div class="translateButton">
            <el-tabs class="special_tabs" stretch v-model="updateFreq">
              <el-tab-pane :label="$t(`Dashboard.Monthly`)" name="0" />
              <el-tab-pane :label="$t(`Dashboard.week`)" name="1" />
            </el-tabs>
          </div>
          <chat-select
            v-model="data.pmiIds"
            filterable
            size="small"
            class="customSelect"
            appendToBody
            multiple
            collapse-tags
            style="width: 180px;height: 32px"
            :placeholder="$t('el.home.Please select')"
          >
            <chat-option
              v-for="item in pmiList"
              :key="item.pmiId"
              :label="`${item.nameCn}(${item.nameEn})`"
              :value="item.pmiId"
            >
              <div class="option_list">
                <div>{{ item.nameCn }}({{ item.nameEn }})</div>
                <div>【{{ item.country }}】</div>
              </div>
            </chat-option>
          </chat-select>
        </div>
        <toolTipIcon 
          v-if="artConfig.isVipMode == 0"
          :icon="contrastActive ? 'el-icon-minus' : 'el-icon-plus'" 
          :tooltipText="$t('addContrast')"
          @click="setContrast()"
        />
        <toolTipIcon 
          v-if="artConfig.isVipMode == 0" 
          icon="iconfont icon-download" 
          :tooltipText="$t('ExportFile')" 
          isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="getPmiList()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen" v-if="artConfig.isVipMode == 0">
      <div class="translateButton">
        <el-tabs class="special_tabs" stretch v-model="updateFreq">
          <el-tab-pane :label="$t(`Dashboard.Monthly`)" name="0" />
          <el-tab-pane :label="$t(`Dashboard.week`)" name="1" />
        </el-tabs>
      </div>
      <chat-select
        v-model="data.pmiIds"
        filterable
        size="small"
        class="customSelect"
        appendToBody
        multiple
        collapse-tags
        style="width: 270px;height: 32px"
        :placeholder="$t('home.Please select')"
      >
        <chat-option
          v-for="item in pmiList"
          :key="item.pmiId"
          :label="`${item.nameCn}(${item.nameEn})`"
          :value="item.pmiId"
        >
          <div class="option_list">
            <div>{{ item.nameCn }}({{ item.nameEn }})</div>
            <div>【{{ item.country }}】</div>
          </div>
        </chat-option>
      </chat-select>
      <el-date-picker
        style="width: 260px"
        v-model="data.time"
        size="small"
        type="daterange"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
    </div>
    <el-row style="margin-top: 22px">
      <div
        :id="echartsViewId"
        class="commodityPrice"
        :style="`height: ${enlarge == false ? '300px' : '70vh'}`"
      ></div>
    </el-row>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { pickerOptions } from "@/utils/utils";
import { getPmiDataApi, getPmiListApi } from "@/api/chart";

export default {
  name: 'commodityPrice',
  components: {},
  data() {
    return {
      myChart: null,
      enlarge: false,
      contrastActive: false,
      echartsViewId: 'pmiIndexNumber_',
      captureId: 'capturePmi_',
      isFullscreen: false, // 是否为全屏状态,
      exportLoading: false,
      pmiList: [],
      echartsData: [],
      data: {
        pmiIds: [],
        time: [dayjs().startOf('year'), dayjs()],
        country: null
      },
      updateFreq: '0',
      chartLoading: false,
    };
  },
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  inject: ['getConfig'],
  computed: {
    in18() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.onStatisticalChart();
      },
      deep: true,
    },
    in18() {},
    'artConfig.echarts_type'() {
      this.myChart.resize();
    },
    enlarge() {
      this.setEchartsOption(this.echartsData);
    },
    updateFreq() {
      this.getPmiList();
    }
  },
  mounted() {
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
    this.getPmiList();
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document
          .getElementById(this.captureId)
          .classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    setContrast() {
      let arrIndex = this.$parent.$parent.$parent.contrastConfig.findIndex(item => item.name === 'pmiIndexNumber')
      if(arrIndex !== -1) {
        this.$parent.$parent.$parent.contrastConfig.splice(arrIndex, 1);
        this.contrastActive = false;
        return;
      }
      if(this.$parent.$parent.$parent.contrastConfig.length >= 2) return;
      this.contrastActive = true;
      this.$parent.$parent.$parent.contrastConfig.push({
        name: "pmiIndexNumber",
        title: this.$t("Dashboard.PMIIndexNumber"),
        echartsApi: getPmiDataApi,
        params: {
          pmiIds: this.data.pmiIds.join(","),
          updateFreq: this.updateFreq
        },
        options: this.myChart.getOption()
      })
    },
    //导出(pdf)
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.$t("Dashboard.PMIIndexNumber");
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      let header = [
        "",
        ...chartOption.series.map(item => item.name)
      ];
      let data = [];
      chartOption.xAxis[0].data.forEach((item, index) => {
        data.push([item])
      })
      data.forEach((item, index) => {
        header.forEach((row, idx) => {
          if(row) {
            item[idx] = chartOption.series[idx - 1].data[index];
          }
        })
      })
      let filename = this.$t("Dashboard.PMIIndexNumber");
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    getPmiList() {
      getPmiListApi({
        updateFreq: this.updateFreq
      }).then((data) => {
        this.pmiList = data;
        this.data.pmiIds = [data[0].pmiId]
        if(data.length >= 7) {
          this.data.pmiIds = [data[0].pmiId, data[6].pmiId]
        }
      })
    },
    onStatisticalChart() {
      this.chartLoading = true;
      if(!document.getElementById(this.echartsViewId)) return;
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      const { pmiIds, time } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getPmiDataApi({
        pmiIds: pmiIds.join(","), 
        startTime, 
        endTime,
        updateFreq: this.updateFreq
      })
        .then((data) => {
          this.echartsData = data;
          this.setEchartsOption(data);
          this.chartLoading = false;
        })
        .finally(() => (this.chartLoading = false));
    },
    setEchartsOption(data) {
      let arr = [];
      let shis = data.filter(item => item.data.length > 0)[0];
      data.forEach(item => {
        if(!item.data.length) {
          item.data = shis.data.map(row => ({
            ...row,
            index: null
          }))
        }
      })
      data.forEach((vo) => {
        arr.push(...vo.data)
      });
      let aggregate = _.groupBy(arr, 'nameCn');
      let seriesList = [];
      for (let index in aggregate) {
        seriesList.push({
          name: index,
          data: aggregate[index]?.map((v) => {
            return v?.index;
          }),
        });
      }
      const seriesMark = this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {}
      this.myChart.setOption(
        {
          grid: [
            {
              top: 40,
              left: '2%',
              right: '7%',
              bottom: '14%',
              containLabel: true,
            },
          ],
          tooltip: {
            trigger: 'axis',
            confine: true,
            //   axisPointer: {
            //     type: 'cross',
            //   },
            // position: function (pt) {
            //   return [pt[0], '10%'];
            // },
            formatter: function (params, ticket, callback) {
              let list = [];
              params.forEach((v) => {
                if (Number(v.value)) {
                  list.push(
                    `<div>${v.marker} ${v.seriesName}: ${v.value}</div>`
                  );
                }
              });
              return `
            <div>
            ${params[0].name}
            ${list.join(' ')}
            </div>
            `;
            },
          },
          legend: {
            type: 'scroll',
            data: seriesList.map((v) => v.name),
          },
          yAxis: {
            type: 'value',
            scale: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
              show: false,
            },
          },
          xAxis: {
            data: data.map((vo) => {
              return dayjs(vo.updateDate).format(this.updateFreq === '1' ? 'YYYY.MM.DD' : 'YYYY.MM');
            }),
            // type: 'category',
            boundaryGap: false,
            nameTextStyle: {},
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
            },
            axisLabel: {},
          },
          series: seriesList.map((vo) => {
            return {
              ...vo,
              type: 'line',
              smooth: true,
              symbol: 'none',
              showSymbol: false,
              connectNulls: true,
              animationEasing: 'cubicInOut',
              ...seriesMark
            };
          }),
          dataZoom: [
            {
              show: true,
              type: 'slider',
              left: 'center',
              bottom: '0%',
              start: 0,
              end: 100,
            },
          ],
        },
        true
      );

      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>
.translateButton {
  background: #F7F9FC;
}
</style>
