<template>
  <el-card
    class="Commodity" 
    id="exportProductStatisticsPdf"
    v-if="productValue">
    <div class="left_title" style="cursor: auto;">
      <div class="title">{{ $t("Dashboard.statisticsProductMarket") }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf()">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
          </template>
        </toolTipIcon>
      </div>
    </div>
    <div class="countryInfoBox" v-if="topData">
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.skuItemNo') }}</div>
        <div class="content">
          {{ topData.itemNo }}
        </div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.skuType') }}</div>
        <div class="content">{{ topData.type }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.skuSize') }}</div>
        <div class="content">{{ topData.size }}</div>
      </div>
      <div class="productImage">
        
      </div>
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import html2canvas from "html2canvas";

export default {
  name: "statistics",
  data() {
    return {
      echartsViewId: "exportProductStatisticsPdf",
      exportLoading: false,
      topData: null,
      productValue: null,
      year: dayjs().format("YYYY") - 1,
      season: Math.ceil(dayjs().format("MM") / 3) - 1 || 4,
    };
  },
  props: [],
  watch: {
    productValue(val) {
      this.onBasicStatistics(val);
    }
  },
  methods: {
    onBasicStatistics(val) {
      this.topData = val;
    },
    //导出(pdf)
    savePdf() {
      this.$refs.echartsBtns.style.display = "none";
      setTimeout(() => {
        // setAttribute 需要一定时间生效
        html2canvas(document.getElementById("exportProductStatisticsPdf"), {
          useCORS: true
        }).then((canvas) => {
          this.$refs.echartsBtns.style.removeProperty('display');
          const oImg = new Image();
          oImg.setAttribute('crossOrigin', 'anonymous')
          oImg.src = canvas.toDataURL(); // 导出图片
          let dataURL = canvas.toDataURL("image/png");
          // 下载图片
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = oImg.src;
          // 设置下载标题
          a.download = "我的文件";
          a.click();
        });
      }, 200);
    },
  },
};
</script>
<style lang="less" scoped>
.countryInfoBox {
  background-position: right 20px;
  background-repeat: no-repeat;
  margin-top: 10px;
  overflow: hidden;
  .infoItem {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .label {
      width: 100px;
      font-size: 12px;
      line-height: 18px;
      color: #65748B;
    }
    .content {
      flex: 1;
      min-width: 0;
      color: #122545;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
      text-align: right;
    }
  }
  .productImage {
    width: 100%;
    height: 250px;
    background: #F7F9FC;
    margin-top: 20px;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    .el-image {
      width: auto;
      height: 100%;
    }
  }
}
</style>
