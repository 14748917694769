<template>
  <div class="Commodity">
    <div class="top">
      <div class="order">
        <div class="left">
          <div class="title">
            <div class="icon">
              <i class="iconfont icon-dingdanshu1"></i>
            </div>
            {{ $tc('Dashboard.Orders', $isOrderTitle()) }}
          </div>
          <div class="number">{{ topData.order || 0 }}</div>
        </div>
        <div class="right">
          <div class="item" >
            <div class="title">
              {{ isDefaultNode ? $t('Dashboard.Shipping') : $t("order.pending") }}
            </div>
            <span>{{ topData.transport || 0 }}</span>
          </div>
          <div class="item" v-show="isDefaultNode">
            <div class="title">
              {{ $t('Dashboard.Producing') }}
            </div>
            <span>{{ topData.production || 0 }}</span>
          </div>
          <div class="item">
            <div class="title">
              {{ $t('Dashboard.Completed') }}
            </div>
            <span>{{ topData.completed || 0 }}</span>
          </div>
        </div>
      </div>
      <div class="itemBox" v-if="type === 'country' || type === 'product'">
        <div class="title">
          <i class="iconfont icon-top3kehu"></i>
          {{
            companyCurrentType == "1" || companyCurrentType == "2"
              ? $t("Dashboard.MainCustomers")
              : $t("Dashboard.MainSupplier")
          }} top3
        </div>
        <div class="info">
          <div
            class="infoItem" 
            v-for="(item, index) in mainCustomerList" 
            :key="index"
            @click="goCustomerDetail(item)">
            <img :src="item.companyLogoUrl" />
            <p class="line1">{{item.aliasName}}</p>
          </div>
        </div>
      </div>
      <div class="itemBox" v-if="type === 'customer' || type === 'product'">
        <div class="title">
          <i class="iconfont icon-guojiatop"></i>
          {{ $t("Dashboard.MainCountry") }} top3
        </div>
        <div class="info country">
          <div
            class="infoItem" 
            v-for="(item, index) in mainCountryList" 
            :key="index"
            @click="goCompanyDetail(item)">
            <img :src="item.nationalFlag" style="border-radius: 0;height: auto;" />
            <p class="line1">{{item.countryCn}}</p>
          </div>
        </div>
      </div>
      <div class="itemBox" v-if="type !== 'product'">
        <div class="title">
          <i class="iconfont icon-a-retop1"></i>
          {{ $t("Dashboard.MainProducts") }} top3
        </div>
        <div class="info product">
          <div
            class="infoItem" 
            v-for="(item, index) in mainProductList" 
            :key="index"
            :title="`${item.itemNo} | ${item.type} | ${item.size}`">
            <p class="line1">{{item.itemNo}} | {{item.type}} | {{item.size}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="member">
        <div class="member_text">
          {{ !artConfig.isAmountNumberMode ? $t('Dashboard.Total') : $t('order.Quantity') }}
        </div>
        <div class="member_num">
          {{ topData.total || 0 }}
        </div>
      </div>
      <div class="compare">
        <div class="item">
          <span :style="`color: ${topData.thanYear > 0 ? '#48AC42' : '#C84154'}`">
            <i class="iconfont icon-shangsheng" v-if="topData.thanYear > 0"></i>
            <i class="iconfont icon-xiajiang" v-else></i>
            {{ topData.thanYear }}%
          </span>
          <pickerSelect ref="pickerSelect" @updateCompare="updateCompare" />
        </div>
        <div class="item">
          <span :style="`color: ${topData.thanSeason > 0 ? '#48AC42' : '#C84154'}`">
            <i class="iconfont icon-shangsheng" v-if="topData.thanSeason > 0"></i>
            <i class="iconfont icon-xiajiang" v-else></i>
            {{ topData.thanSeason }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { 
  basicStatistics, 
  getCountryTop3,
  getCompanyTop3,
  getCiSkuTop3
} from '@/api/chart';
import { formatNum } from '@/utils/utils';
import pickerSelect from "@/components/pickerSelect"

export default {
  name: "statistics",
  components: {
    pickerSelect
  },
  data() {
    return {
      data: {
        country: '',
        customer: '',
        itemNo: '',
        type: '',
        size: '',
        time: []
      },
      topData: {},
      mainCustomerList: [],
      mainCountryList: [],
      mainProductList: []
    };
  },
  props: {
    type: {
      type: String | Number,
      default: "",
    },
  },
  inject: ['getConfig'],
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.onBasicStatistics();
      },
      deep: true,
    },
    'artConfig.isAmountNumberMode'() {
      this.onBasicStatistics();
    },
  },
  computed: {
    ...mapGetters(['country', 'companyCurrentType', 'isDefaultNode']),
    artConfig() {
      return this.getConfig();
    }
  },
  mounted() {

  },
  methods: {
    updateCompare(value) {
      basicYearStatistics({
        oldYear: value[0],
        nowYear: value[1]
      }).then((data) => {
        this.topData.thanYear = Number(data).toFixed(2);
      })
    },
    goCustomerDetail(row) {
      this.$router.push({
        name: 'Customer',
        query: {
          companyId: row.companyId
        },
      })
    },
    goCompanyDetail(row) {
      this.$router.push(`/dashboard/1?countryId=${row.iso3}`);
    },
    onBasicStatistics() {
      this.chartLoading = true;
      const { 
        time, 
        country, 
        customer, 
        itemNo,
        type,
        size,
      } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
      basicStatistics({
        country,
        customer,
        itemNo,
        type,
        size,
        startTime,
        endTime,
        queryType: this.artConfig.isAmountNumberMode
      })
        .then((data) => {
          this.chartLoading = false;
          let amount = [];
          for(let i in data.amount) {
            amount.push({
              key: i,
              value: data.amount[i]
            })
          }
          this.topData = {
            ...data,
            currencyAmountVos: amount,
            total: this.artConfig.isAmountNumberMode ? Number(data.totalAmount.USD) : formatNum(Number(data.totalAmount.USD).toFixed(2)),
            thanYear: Number(data.thanYear).toFixed(2),
            thanSeason: Number(data.thanQuarter).toFixed(2),
          };
        })
        .finally(() => (this.chartLoading = false));
      this.getTop3Data();
    },
    getTop3Data() {
      const { time } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : '';
      if(this.type === 'country') {
        getCiSkuTop3({
          startTime,
          endTime,
          limit: 3,
          country: this.data.country
        }).then(data => {
          this.mainProductList = data;
        })
        getCompanyTop3({
          startTime,
          endTime,
          limit: 3,
          country: this.data.country
        }).then(data => {
          this.mainCustomerList = data;
        })
      }
      if(this.type === 'customer') {
        getCiSkuTop3({
          startTime,
          endTime,
          limit: 3,
          customer: this.data.customer
        }).then(data => {
          this.mainProductList = data;
        })
        getCountryTop3({
          startTime,
          endTime,
          limit: 3,
          customer: this.data.customer
        }).then(data => {
          this.mainCountryList = data;
        })
      }
      if(this.type === 'product') {
        getCompanyTop3({
          startTime,
          endTime,
          limit: 3,
          itemNo: this.data.itemNo,
          type: this.data.type,
          size: this.data.size
        }).then(data => {
          this.mainCustomerList = data;
        })
        getCountryTop3({
          startTime,
          endTime,
          limit: 3,
          itemNo: this.data.itemNo,
          type: this.data.type,
          size: this.data.size
        }).then(data => {
          this.mainCountryList = data;
        })
      }
    }
  }
};
</script>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;
  .order {
    width: 46%;
    height: 300px;
    background: #F7F9FC;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .left {
      width: 50%;
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        .icon {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          background: #1B2B59;
          color: #ffffff;
          line-height: 21px;
          i {
            font-size: 20px;
          }
        }
      }
      .number {
        font-size: 32px;
        font-weight: 700;
        line-height: 37px;
        margin-top: 16px;
        color: #1B2B59;
      }
    }
    .right {
      width: 50%;
      .item {
        padding: 40px 0;
        border-bottom: 1px solid #ffffff;
        display: flex;
        justify-content: space-between;
        &:nth-child(1) {
          .title {
            &::before {
              background: #DC3545;
            }
          }
        }
        &:nth-child(2) {
          .title {
            &::before {
              background: #48AC42;
            }
          }
        }
        &:nth-child(3) {
          .title {
            &::before {
              background: #2E4793;
            }
          }
        }
        .title {
          display: flex;
          align-items: center;
          gap: 9px;
          font-size: 16px;
          line-height: 16px;
          color: #122545;
          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
        span {
          font-size: 20px;
          line-height: 16px;
          font-weight: 700;
          color: #122545;
        }
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border: none;
        }
      }
    }
  }
  .itemBox {
    width: 25%;
    height: 300px;
    border-radius: 4px;
    padding: 20px;
    background: #F7F9FC;
    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      line-height: 28px;
      color: #637381;
      font-weight: 500;
      i {
        font-size: 20px;
        color: #FF8311;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      gap: 36px;
      .infoItem {
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
        p {
          font-size: 20px;
          line-height: 18px;
          font-weight: 500;
          flex: 1;
          min-width: none;
          word-break: break-all;
        }
      }
    }
  }
}
.Commodity {
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    background: #F7F9FC;
    border-radius: 4px;
    padding: 30px 30px;
    .member {
      width: 55%;
      display: flex;
      align-items: center;
      gap: 12px;
      border-right: 1px solid #ffffff;
      .member_text {
        font-size: 16px;
        color: #122545;
        white-space: nowrap;
      }
      .member_num {
        font-size: 40px;
        font-weight: 900;
        line-height: 46px;
        color: #122545;
      }
    }
    .compare {
      width: 40%;
      .item {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 32px;
          font-weight: 500;
          line-height: 37px;
          display: flex;
          align-items: center;
          gap: 8px;
          i {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
