<template>
  <div>
    <div class="basticInfo">
      <statistics ref="statisticsAll" />
    </div>
    <div class="Statistical_cart_box">
      <div class="top">
        <supplierVipData ref="componentTotal1" echartsType="0" />
        <supplierAmount ref="componentTotal2" echartsType="0" />
      </div>
      <div class="bottom">
        <exportProportion ref="componentTotal3" echartsType="0" />
        <skuAmount ref="componentTotal4" echartsType="0" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import skuUnitPrice from '../components/skuUnitPrice.vue';
import skuAmount from '../components/skuAmount.vue';
import exchangeRate from '../components/exchangeRate.vue';
import commodityPrice from '../components/commodityPrice.vue';
import exportData from '../components/exportData.vue';
import exportProportion from '../components/exportProportion.vue';
import supplierAmount from '../components/supplierAmount.vue';
import purchasAmount from '../components/purchasAmount.vue';
import statistics from '../components/statisticsVip.vue';
import supplierConparison from '../components/supplierConparison.vue';
import bdiIndexNumber from "../components/bdiIndexNumber.vue";
import orderStatusSummary from "../components/orderStatusSummary.vue";
import pmiIndexNumber from '../components/pmiIndexNumber.vue';
import supplierVipData from "../components/supplierVipData.vue";

export default {
  name: 'dashboardAll',
  components: {
    purchasAmount,
    statistics,
    supplierConparison,
    supplierAmount,
    exportData,
    exportProportion,
    commodityPrice,
    exchangeRate,
    skuAmount,
    skuUnitPrice,
    bdiIndexNumber,
    pmiIndexNumber,
    orderStatusSummary,
    supplierVipData
  },
  inject: ['getTimeConfig'],
  computed: {
    ...mapGetters([]),
    timeConfig() {
      return this.getTimeConfig();
    },
  },
  data() {
    return {
      dashboardList: [11, 5, 8, 9],
      dashboardObject: {
        0: pmiIndexNumber,
        1: orderStatusSummary,
        2: bdiIndexNumber,
        3: commodityPrice,
        4: supplierConparison,
        5: supplierAmount,
        6: exchangeRate,
        7: exportData,
        8: exportProportion,
        9: skuAmount,
        10: skuUnitPrice,
        11: supplierVipData
      }
    }
  },
  mounted() {
    this.setCountryValue();
  },
  watch: {
    'timeConfig.globalTimeAll': {
      handler(val) {
        this.setTime(val);
      }
    }
  },
  methods: {
    setCountryValue() {
      this.$nextTick(() => {
        this.$refs.statisticsAll.data.time = this.timeConfig.globalTimeAll;
        this.$nextTick(() => {
          this.$refs.componentTotal1.data.country = ''
          this.$refs.componentTotal2.data.country = ''
          this.$refs.componentTotal3.data.country = ''
          this.$refs.componentTotal4.data.country = ''
        })
      })
    },
    setTime(val) {
      this.$refs.statisticsAll.data.time = val;
      this.$refs.componentTotal1.data.time = val
      this.$refs.componentTotal2.data.time = val
      this.$refs.componentTotal3.data.time = val
      this.$refs.componentTotal4.data.time = val
    }
  }
}
</script>

<style lang="less" scoped>
</style>