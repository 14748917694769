<template>
  <el-card
    v-loading="chartLoading"
    ref="exportPdfTwo"
    :id="captureId"
    class="Commodity"
  >
    <div class="top_right_box">
      <div class="left_title" style="cursor: auto;">
        <div class="title">{{ title }}</div>
        <div class="echartsBtns" ref="echartsBtns">
          <toolTipIcon 
            :icon="contrastActive ? 'el-icon-minus' : 'el-icon-plus'" 
            :tooltipText="$t('addContrast')"
            @click="setContrast()"
          />
          <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
            <template slot="popperMenuItem">
              <div class="popperMenuItem" @click="savePdf('png')">
                <i class="el-icon-download" />
                <span>{{ $t('Files.ExportPng') }}</span>
              </div>
              <div class="popperMenuItem" @click="savePdf('excel')">
                <i class="el-icon-download" />
                <span>{{ $t('Files.ExportExcel') }}</span>
              </div>
            </template>
          </toolTipIcon>
          <toolTipIcon 
            icon="iconfont icon-shuaxin" 
            :tooltipText="$t('Refresh')"
            @click="onGetAmount()"
          />
          <toolTipIcon 
            :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
            :tooltipText="enlarge ? $t('retract') : $t('expand')"
            @click="onEnlarge()"
          />
        </div>
      </div>
      <div class="screen">
        <div class="translateButton">
          <el-tabs class="special_tabs" v-model="data.dateType">
            <el-tab-pane :label="$t(`Dashboard.Monthly`)" name="0" />
            <el-tab-pane :label="$t(`Dashboard.Quarterly`)" name="1" />
            <el-tab-pane :label="$t(`Dashboard.Yearly`)" name="2" />
          </el-tabs>
        </div>
        <el-date-picker
          style="width: 240px"
          v-model="data.time"
          type="daterange"
          size="small"
          :start-placeholder="$t('Dashboard.Startfrom')"
          :end-placeholder="$t('Dashboard.Endoftime')"
          :picker-options="pickerData"
        />
      </div>
    </div>
    <div class="chart-wrapper" style="margin-top: 22px;">
      <div
        :ref="echartsViewId"
        :id="echartsViewId"
        class="chartBox"
        :style="{
          height: !enlarge ? `${echartsType == 0 ? (artConfig.isVipMode == 0 ? '220' : '300') : '340'}px` : '70vh'
        }"
      />
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { pickerOptions } from "@/utils/utils";
import { getAmount } from "@/api/chart";
import { mapGetters } from "vuex";
export default {
  name: 'purchasAmount',
  components: {},
  data() {
    return {
      myChart: null,
      contrastActive: false,
      echartsViewId: 'chartBox_',
      captureId: 'exportPdfTwo_',
      enlarge: false,
      exportLoading: false,
      echartsData: [],
      data: {
        time: [dayjs().startOf('year'), dayjs()],
        country: null,
        customer: '',
        itemNo: '',
        dateType: '0'
      },
      title: '',
      chartLoading: false,
      countDatas: []
    };
  },
  inject: ['getConfig'],
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["companyCurrentType"]),
    i8n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig()
    }
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.chartLoading = true;
        this.onGetAmount();
      },
      deep: true,
    },
    i8n() {
      this.onGetTitle();
    },
    'companyCurrentType': {
      handler() {
        this.onGetTitle();
      },
      immediate: true
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.myChart && this.myChart.resize();
      }, 1);
    },
    'artConfig.isAmountNumberMode'() {
      this.onGetTitle();
      this.onGetAmount();
    },
    enlarge() {
      this.setEchartsOption(this.echartsData);
    }
  },
  mounted() {
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    setContrast() {
      let arrIndex = this.$parent.contrastConfig.findIndex(item => item.name === 'purchasAmount')
      if(arrIndex !== -1) {
        this.$parent.contrastConfig.splice(arrIndex, 1);
        this.contrastActive = false;
        return;
      }
      if(this.$parent.contrastConfig.length >= 2) return;
      this.contrastActive = true;
      this.$parent.contrastConfig.push({
        name: "purchasAmount",
        echartsApi: getAmount,
        title: this.title,
        params: {
          country: this.data.country,
          companyId: this.data.companyId,
          itemNo: this.data.itemNo,
          dateType: this.data.dateType,
          queryType: this.artConfig.isAmountNumberMode
        },
        options: this.myChart.getOption()
      })
    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document.getElementById(this.captureId).classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document.getElementById(this.captureId).classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 300);
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.title;
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      var header = chartOption.xAxis[0].data;
      var data = [chartOption.series[0].data];
      var filename = this.title;
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    onGetTitle() {
      if (this.companyCurrentType == '1') {
        this.title = !this.artConfig.isAmountNumberMode ? this.$t('Dashboard.MonthlyPurchaseAmount') : this.$t('Dashboard.MonthlyPurchaseNumber');
      } else {
        this.title = !this.artConfig.isAmountNumberMode ? this.$t('Dashboard.ShipmentAmount') : this.$t('Dashboard.ShipmentQuantity');
      }
    },
    onGetAmount() {
      this.chartLoading = true;
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      const { 
        dateType, 
        time, 
        country, 
        customer, 
        itemNo,
        type,
        size 
      } = this.data;
      console.log()
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getAmount({ 
        dateType, 
        startTime, 
        endTime, 
        country, 
        customer, 
        itemNo,
        type,
        size,
        queryType: this.artConfig.isAmountNumberMode
      }).then(({ data, statistics }) => {
          this.echartsData = data;
          this.setEchartsOption(data);
          this.chartLoading = false;
          this.countDatas = [{
            name: "",
            data: [
              [this.$t("Dashboard.maxValue"), statistics.max.value, statistics.max.keys.join("，")],
              [this.$t("Dashboard.minValue"), statistics.min.value, statistics.min.keys.join("，")],
              [this.$t("Dashboard.avgValue"), statistics.avg, `${startTime}~${endTime}`]
            ]
          }]
        })
        .finally(() => (this.chartLoading = false));
    },
    setEchartsOption(data) {
      let amountList = [];
      let dateList = [];
      data?.forEach((v, i) => {
        if (this.companyCurrentType === '2') {
          amountList.push(Number(v.orderNumber).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2));
        } else {
          amountList.push(Number(v.amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2));
        }
        dateList.push(v.date);
      });
      const seriesMark = this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {};
      this.myChart.setOption({
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) => {
            var htmlStr = `<div>${params[0].name}</div>`;
            for (var i = 0; i < params.length; i++) {
              htmlStr += `
                <p>
                  ${params[i].marker}${params[i].value}
                  ${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}
                </p>
              `;
            }
            return htmlStr;
          },
        },
        grid: {
          top: '5%',
          left: '3%',
          right: '3%',
          bottom: '2%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: dateList,
          axisTick: {
            alignWithLabel: true,
            show: false,
          },
          axisLine: {
            lineStyle: { color: '#B0B8BF' },
          },
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: { color: '#B0B8BF' },
            show: false,
          },
        },
        series: {
          data: amountList.length ? amountList : ['0'],
          type: 'bar',
          barWidth: '40%',
          ...seriesMark
        },
      }, true);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>

</style>
