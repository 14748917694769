<template>
  <el-card
    class="Commodity"
    v-loading="exportLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">
        {{ $t("Dashboard.SKU(unit price)") }}
        <el-tooltip class="item" effect="dark" placement="top">
          <i class="el-icon-info"></i>
          <template #content>
            型号、size、type：显示指定类型的sku的数据信息<br />
          </template>
        </el-tooltip>
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon 
          :icon="contrastActive ? 'el-icon-minus' : 'el-icon-plus'" 
          :tooltipText="$t('addContrast')"
          @click="setContrast()"
        />
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onStatisticalChart()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen">
      <chat-select
        v-model="data.skuCode"
        v-if="artConfig.echarts_type !== '3'"
        v-selectLazyLoad="selectPageLoad"
        filterable
        class="customSelect"
        size="small"
        multiple
        collapse-tags
        style="width: 300px"
        :placeholder="$t('home.Please select')"
      >
        <el-option
          v-for="item in skuList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </chat-select>
      <el-date-picker
        style="width: 300px"
        type="daterange"
        size="small"
        v-model="data.time"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
    </div>
    <el-row style="background: #fff; margin-top: 10px">
      <div
        :id="echartsViewId"
        class="skuUnitPrice"
        :style="`height: ${this.enlarge == false ? '305px' : '70vh'}`"
      />
    </el-row>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import * as echarts from "echarts";
import html2canvas from "html2canvas";
import { uniqBy } from "lodash";
import { getSkuParam, skuUnitPriceList } from "@/api/chart";
import { pickerOptions, new_date } from "@/utils/utils";

export default {
  name: 'skuUnitPrice',
  components: {},
  data() {
    return {
      myChart: null,
      contrastActive: false,
      echartsViewId: 'skuUnitPrice_',
      captureId: 'capturenight_',
      enlarge: false,
      exportLoading: false,
      itemNosList: [],
      sizesList: [],
      typesList: [],
      echartsData: [],
      data: {
        country: null,
        customer: '',
        time: [dayjs().startOf('year'), dayjs()],
        skuCode: []
      },
      skuStr: [],
      skuList: [],
      currentSelectPage: 1
    };
  },
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  inject: ['getConfig'],
  computed: {
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.onStatisticalChart();
      },
      deep: true,
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    enlarge() {
      this.setEchartsOption(this.echartsData);
    },
    currentSelectPage() {
      this.getSkuList();
    }
  },
  mounted() {
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    setContrast() {
      let arrIndex = this.$parent.$parent.$parent.contrastConfig.findIndex(item => item.name === 'skuUnitPrice')
      if(arrIndex !== -1) {
        this.$parent.$parent.$parent.contrastConfig.splice(arrIndex, 1);
        this.contrastActive = false;
        return;
      }
      if(this.$parent.$parent.$parent.contrastConfig.length >= 2) return;
      this.contrastActive = true;
      console.log(this.data.skuCode)
      let arr = this.data.skuCode.length ? this.data.skuCode : this.skuList.filter((i, index) => index < 5).map(item => item.value);
      arr = arr.map(item => JSON.parse(item))
      this.$parent.$parent.$parent.contrastConfig.push({
        name: "skuUnitPrice",
        title: this.$t("Dashboard.SKU(unit price)"),
        echartsApi: skuUnitPriceList,
        params: {
          customer: this.data.customer,
          country: this.data.country,
          skuList: arr
        },
        options: this.myChart.getOption()
      })
    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document
          .getElementById(this.captureId)
          .classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.$t("Dashboard.SKU(unit price)");
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      let header = [
        ...chartOption.xAxis[0].data
      ];
      let aggregate = _.groupBy(this.echartsData.map(item => ({
        ...item,
        date: dayjs(item.date).format("YYYY-MM-DD")
      })), 'date');
      let data = [];
      let nums = [];
      for(let i in aggregate) {
        nums.push(aggregate[i])
      }
      let maxArray = nums.reduce((prev, next) => {
        return prev.length > next.length ? prev : next
      })
      maxArray.forEach(item => {
        data.push([])
      })
      data.forEach((item, index) => {
        header.forEach((row, idx) => {
          if(row) {
            item[idx] = aggregate[row][index] ? `${aggregate[row][index]?.itemNo}|${aggregate[row][index]?.type}|${aggregate[row][index]?.size}: ${aggregate[row][index]?.unitPrice}(USD)` : "";
          }
        })
      })
      console.log(data);
      let filename = this.$t("Dashboard.SKU(unit price)");
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    selectPageLoad() {
      this.currentSelectPage++;
    },
    getSkuList() {
      let { time, country, customer } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      return new Promise((res, rej) => {
        getSkuParam({ 
          startTime, 
          endTime, 
          country, 
          customer,
          currentPage: this.currentSelectPage,
          pageSize: 20,
        }).then(
          (data) => {
            this.skuList = uniqBy(this.skuList.concat(data.list.map((item, index) => ({
              label: `${item.itemNo} | ${item.type} | ${item.size}`,
              value: JSON.stringify(item)
            }))), item => item.value);
            res(this.skuList.filter((i, index) => index < 5).map(item => item.value))
          }
        );
      })
    },
    async onStatisticalChart() {
      this.exportLoading = true;
      let { 
        time, 
        skuCode, 
        country, 
        customer,
      } = this.data;
      let skuCodes = []
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      this.currentSelectPage = 1;
      if(this.artConfig.echarts_type !== '3') {
        if(!this.echartsData.length) {
          skuCodes = await this.getSkuList();
        }
      }
      let arr = skuCode.length ? skuCode : skuCodes;
      arr = arr.map(item => JSON.parse(item))
      skuUnitPriceList({ 
        startTime, 
        endTime, 
        skuList: arr, 
        country, 
        customer
      }).then((data) => {
        this.echartsData = data;
        this.setEchartsOption(data)
        this.exportLoading = false;
      }).finally(() => (this.exportLoading = false));
    },
    setEchartsOption(data) {
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      let arr = [];
      let currencyList = [];
      let list = [];
      data.forEach((vo) => {
        arr.push({
          ...vo,
          id: vo.itemNo+' | '+vo.type+' | '+vo.size
        });
      });
      let aggregate = _.groupBy(arr, 'id');
      for (let index in aggregate) {
        currencyList.push(index);
        list.push({
          name: index,
          data: aggregate[index].map((res) => {
            return [dayjs(res.date).format('YYYY-MM-DD'), res.unitPrice];
          }),
        });
      }
      let timeArr = list.map(item => {
        return item.data.map(row => row[0])
      })
      let xAixsArray = [];
      if(data.length) {
        xAixsArray = timeArr.reduce((a, b) => a.concat(b));
      }
      let xAixsSort = xAixsArray.sort((a, b) => Date.parse(a) - Date.parse(b))
      let symbolSize = !this.enlarge ? 5 : 15;
      this.myChart.setOption(
        {
          grid: [
            {
              top: 40,
              left: '2%',
              right: '7%',
              bottom: '14%',
              containLabel: true,
            },
          ],
          tooltip: {
            trigger: 'axis',
            enterable: true, // 鼠标是否可进入提示框浮层中
            hideDelay: 60, // 浮层隐藏的延迟
            confine: true,
            formatter: function (params) {
              var htmlStr = '';
              for (var i = 0; i < params.length; i++) {
                htmlStr += `
                  <div class="content">
                    ${params[i].marker}
                    <div class="value">${params[i].seriesName}：${params[i].value[1]}(USD)</div>
                  </div>
                `;
              }
              return `
                <div>${params[0].name}</div>
                <div class="echartsTooltipBox">
                  ${htmlStr}
                </div>
              `;
            }
          },
          xAxis: {
            //   type: 'time',
            data: [...new Set(xAixsSort)],
            type: 'category',
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
            },
          },
          yAxis: {
            type: 'value',
            scale: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
              show: false,
            },
          },
          legend: {
            data: currencyList,
            type: 'scroll'
          },
          series: list.map((res) => {
            return {
              ...res,
              type: 'scatter',
              symbolSize,
            };
          }),
          dataZoom: [
            {
              show: true,
              type: 'slider',
              left: 'center',
              bottom: '0%',
              start: 0,
              end: 100,
            },
          ],
        },
        true
      );
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>
.sku_amount_left_screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#skuUnitPrice {
  width: 100%;
  height: 350px;
}

.Commodity.fullscreen .skuUnitPrice {
  width: 100%;
  height: 800px !important;
}
</style>
