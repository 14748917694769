<template>
  <div class="extraBox">
    <div v-for="(item, index) in dashboardList" :key="item">
      <keep-alive>
        <component
          :is="dashboardObject[item]"
          ref="componentCountryTotal"
          echartsType="5"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import skuUnitPrice from '../components/skuUnitPrice.vue';
import skuAmount from '../components/skuAmount.vue';
import exchangeRate from '../components/exchangeRate.vue';
import commodityPrice from '../components/commodityPrice.vue';
import exportData from '../components/exportData.vue';
import exportProportion from '../components/exportProportion.vue';
import supplierAmount from '../components/supplierAmount.vue';
import supplierConparison from '../components/supplierConparison.vue';
import bdiIndexNumber from "../components/bdiIndexNumber.vue";
import orderStatusSummary from "../components/orderStatusSummary.vue";
import pmiIndexNumber from "../components/pmiIndexNumber.vue";
import supplierVipData from "../components/supplierVipData.vue";

export default {
  name: 'dashboardCountry',
  components: {
    supplierConparison,
    supplierAmount,
    exportData,
    exportProportion,
    commodityPrice,
    exchangeRate,
    skuAmount,
    skuUnitPrice,
    bdiIndexNumber,
    orderStatusSummary,
    pmiIndexNumber,
    supplierVipData
  },
  data() {
    return {
      dashboardList: [0, 2, 6, 3],
      dashboardObject: {
        0: pmiIndexNumber,
        1: orderStatusSummary,
        2: bdiIndexNumber,
        3: commodityPrice,
        4: supplierConparison,
        5: supplierAmount,
        6: exchangeRate,
        7: exportData,
        8: exportProportion,
        9: skuAmount,
        10: skuUnitPrice,
        11: supplierVipData
      }
    }
  },
  inject: ['getConfig', 'getTimeConfig'],
  computed: {
    ...mapGetters([]),
    actConfig() {
      return this.getConfig();
    },
    timeConfig() {
      return this.getTimeConfig();
    },
  },
  mounted () {
    this.setCountryValue(this.actConfig.countryId);
  },
  activated() {
    this.setCountryValue(this.actConfig.countryId);
  },
  watch: {
    'timeConfig.globalTimeExtraData': {
      handler(val) {
        this.$nextTick(() => {
          console.log(val);
          this.setTime(val);
        })
      },
      deep: true
    }
  },
  methods: {
    setTime(val) {
      for (let i = 0; i < this.$refs.componentCountryTotal.length; i++) {
        const element = this.$refs.componentCountryTotal[i];
        element.data.time = val;
      }
    },
    setCountryValue(countryId) {
      this.$nextTick(() => {
        for (let i = 0; i < this.$refs.componentCountryTotal.length; i++) {
          const element = this.$refs.componentCountryTotal[i];
          element.data = {
            ...element.data,
            country: countryId
          };
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.extraBox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(452px, 1fr));
  gap: 24px;
  padding-bottom: 24px;
}
</style>