<template>
  <div>
    <div class="basticInfo">
      <statistics ref="statisticsAll" />
      <purchasAmount ref="componentTotals" echartsType="0" />
    </div>
    <draggable
      v-model="dashboardList"
      animation="200"
      ghostClass="ghost"
      group="people"
      handle=".Commodity .left_title"
      @end="updateDraggable"
    >
      <transition-group class="Statistical_cart_box">
        <div v-for="(item, index) in dashboardList" :key="item">
          <keep-alive>
            <component
              :key="index"
              :is="dashboardObject[item]"
              ref="componentTotal"
              echartsType="0"
            ></component>
          </keep-alive>
        </div>
      </transition-group>
    </draggable>
    <el-badge 
      v-if="contrastConfig.length"
      :value="contrastConfig.length" 
      :hidden="!contrastConfig.length" 
      class="contrastDialogBadge">
      <div class="contrastDialogBadgeBox" @click="seeContrastDialog"></div>
    </el-badge>
    <contrastDialog ref="contrastDialog" />
  </div>
</template>

<script>
import skuUnitPrice from '../components/skuUnitPrice.vue';
import skuAmount from '../components/skuAmount.vue';
import exchangeRate from '../components/exchangeRate.vue';
import commodityPrice from '../components/commodityPrice.vue';
import exportData from '../components/exportData.vue';
import exportProportion from '../components/exportProportion.vue';
import supplierAmount from '../components/supplierAmount.vue';
import purchasAmount from '../components/purchasAmount.vue';
import statistics from '../components/statistics.vue';
import supplierConparison from '../components/supplierConparison.vue';
import bdiIndexNumber from "../components/bdiIndexNumber.vue";
import orderStatusSummary from "../components/orderStatusSummary.vue";
import contrastDialog from '../dialog/contrastDialog.vue';
import pmiIndexNumber from '../components/pmiIndexNumber.vue';
import { mapGetters } from 'vuex';
import { setPersonalization } from "@/api/common";
export default {
  name: 'dashboardAll',
  components: {
    purchasAmount,
    statistics,
    supplierConparison,
    supplierAmount,
    exportData,
    exportProportion,
    commodityPrice,
    exchangeRate,
    skuAmount,
    skuUnitPrice,
    bdiIndexNumber,
    pmiIndexNumber,
    orderStatusSummary,
    contrastDialog
  },
  inject: ['getTimeConfig'],
  computed: {
    ...mapGetters(["chartOrder", "personalization"]),
    timeConfig() {
      return this.getTimeConfig();
    },
  },
  data() {
    return {
      dashboardList: [],
      dashboardObject: {
        0: pmiIndexNumber,
        // 1: orderStatusSummary,
        2: bdiIndexNumber,
        3: commodityPrice,
        4: supplierConparison,
        5: supplierAmount,
        6: exchangeRate,
        7: exportData,
        8: exportProportion,
        9: skuAmount,
        10: skuUnitPrice,
      },
      contrastConfig: []
    }
  },
  mounted() {
    this.setCountryValue();
  },
  watch: {
    chartOrder: {
      handler(val) {
        this.dashboardList = val.all.filter(item => item != 1);
      },
      deep: true,
      immediate: true
    },
    'timeConfig.globalTimeAll': {
      handler(val) {
        this.setTime(val);
      }
    }
  },
  methods: {
    updateDraggable() {
      setPersonalization({
        ...this.personalization,
        dashboardOrder: `${this.dashboardList}|${this.chartOrder.country}|${this.chartOrder.customer}|${this.chartOrder.product}`
      })
    },
    seeContrastDialog() {
      this.$refs.contrastDialog.dialogVisible = true;
      this.$refs.contrastDialog.contrastConfig = this.contrastConfig;
    },
    setCountryValue() {
      this.$nextTick(() => {
        this.$refs.statisticsAll.data.time = this.timeConfig.globalTimeAll;
        this.$refs.componentTotals.data.country = '';
        this.$nextTick(() => {
          for (let i = 0; i < this.$refs.componentTotal.length; i++) {
            const element = this.$refs.componentTotal[i];
            element.data.country = '';
          }
        })
      })
    },
    setTime(val) {
      this.$refs.statisticsAll.data.time = val;
      this.$refs.componentTotals.data.time = val;
      for (let i = 0; i < this.$refs.componentTotal.length; i++) {
        const element = this.$refs.componentTotal[i];
        element.data.time = val;
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>