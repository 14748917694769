<template>
  <div class="statisticsBox">
    <div class="left">
      <div class="top">
        <div class="item">
          <div class="title">
            <div class="icon">
              <i class="iconfont icon-chengyuanshu1"></i>
            </div>
            {{ $t('Dashboard.Member') }}
          </div>
          <div class="number">
            {{ topData.member || 0 }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            <div class="icon">
              <i class="iconfont icon-gongyingshangshu1"></i>
            </div>
            {{
              companyCurrentType == '1' || companyCurrentType == '2'
                ? $t('Dashboard.Customer')
                : $t('Dashboard.Supplier')
            }}
          </div>
          <div class="number">
            {{ topData.customer || 0 }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            <div class="icon">
              <i class="iconfont icon-guojiashu"></i>
            </div>
            {{ $t('Dashboard.country') }}
          </div>
          <div class="number">
            {{ topData.country || 0 }}
          </div>
        </div>
        <div class="item">
          <div class="title">
            <div class="icon">
              <i class="iconfont icon-chanpinshu"></i>
            </div>
            {{ $t('Dashboard.Product') }}
          </div>
          <div class="number">
            {{ topData.sku || 0 }}
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="member">
          <div class="member_text">
            {{ !artConfig.isAmountNumberMode ? $t('Dashboard.Total') : $t('order.Quantity') }}
          </div>
          <el-popover placement="right" :width="500" trigger="hover">
            <el-table stripe :data="topData.currencyAmountVos">
              <el-table-column
                prop="key"
                :label="$t('Dashboard.Currency')"
              />
              <el-table-column prop="value" :label="$t('Dashboard.Amount')" />
            </el-table>
            <div slot="reference" class="member_num">
              {{ topData.total }}
            </div>
          </el-popover>
        </div>
        <div class="compare">
          <div class="item">
            <span :style="`color: ${topData.thanYear <= 0 ? '#48AC42' : '#C84154'}`">
              <i class="iconfont icon-xiajiang" v-if="topData.thanYear <= 0"></i>
              <i class="iconfont icon-shangsheng" v-else></i>
              {{ topData.thanYear }}%
            </span>
            <div class="quarter">
              <pickerSelect 
                :type="quarterType"
                :valueQuarter.sync="byQuarter" 
                ref="pickerSelect" 
                width="100%"
              />
              <div class="btns">
                <el-button :type="quarterType == 'seasonSame' ? 'primary' : ''" size="mini" @click="quarterType = 'seasonSame'">{{ $t('home.Quarter on quarter') }}</el-button>
                <el-button :type="quarterType == 'seasonRing' ? 'primary' : ''" size="mini" @click="quarterType = 'seasonRing'">{{ $t('home.Quarter to quarter ratio') }}</el-button>
                <el-button :type="quarterType == 'year' ? 'primary' : ''" size="mini" @click="quarterType = 'year'">{{ $t('home.Year on year') }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="member">
        <div class="title">
          <div class="icon">
            <i class="iconfont icon-dingdanshu1"></i>
          </div>
          {{ $tc('Dashboard.Orders', $isOrderTitle()) }}
        </div>
        <div class="number">{{ topData.order || 0 }}</div>
      </div>
      <div class="bottomBox">
        <div class="item">
          <div class="title">
            {{ isDefaultNode ? $t('Dashboard.Shipping') : $t("order.pending") }}
          </div>
          <span>{{ topData.transport }}</span>
        </div>
        <div class="item" v-show="isDefaultNode">
          <div class="title">
            {{ $t('Dashboard.Producing') }}
          </div>
          <span>{{ topData.production }}</span>
        </div>
        <div class="item">
          <div class="title">
            {{ $t('Dashboard.Completed') }}
          </div>
          <span>{{ topData.completed }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { formatNum } from '@/utils/utils';
import { basicStatistics, basicYearStatistics, basicSeasonStatistics } from '@/api/chart';

import pickerSelect from "@/components/pickerSelect"

export default {
  name: 'statisticsVip',
  components: {
    pickerSelect
  },
  data() {
    return {
      data: {
        time: [],
      },
      topData: {},
      yearData: this.$getGradeData(1800),
      chartLoading: false,
      byYear: "",
      byQuarterCopy: "",
      quarterType: "year",
      byQuarter: [new Date().getFullYear() - 1, new Date().getFullYear()],
      // season: Math.ceil(dayjs().format('MM') / 3) - 1 || 4,
    };
  },
  watch: {
    data: {
      handler() {
        if(this.$route.name != 'Dashboard') return;
        this.onBasicStatistics();
      },
      deep: true
    },
    byQuarter: {
      handler(val) {
        this.byQuarterCopy = val;
        this.updateCompare(val)
      },
      deep: true
    },
    quarterType(val) {
      if(val == 'year') {
        this.byQuarter = [new Date().getFullYear() - 1, new Date().getFullYear()];
      }
      if(val == 'seasonSame') {
        var currMonth= new Date().getMonth() + 1;
        var currQuarter = Math.floor((currMonth % 3 == 0 ? (currMonth/3) : (currMonth / 3 + 1)));
        this.byQuarter = [new Date().getFullYear() - 1, currQuarter, new Date().getFullYear(), currQuarter];
      }
      if(val == 'seasonRing') {
        var currMonth= new Date().getMonth() + 1;
        var currQuarter = Math.floor((currMonth % 3 == 0 ? (currMonth/3) : (currMonth / 3 + 1)));
        if(currQuarter > 1) {
          this.byQuarter = [new Date().getFullYear(), currQuarter - 1,  new Date().getFullYear(), currQuarter];
        } else {
          this.byQuarter = [new Date().getFullYear() - 1, 4,  new Date().getFullYear(), currQuarter];
        }
      }
    },
    'artConfig.isAmountNumberMode'() {
      this.onBasicStatistics();
      this.updateCompare(this.byQuarterCopy);
    },
  },
  inject: ['getConfig'],
  computed: {
    ...mapGetters(["companyCurrentType", "isDefaultNode"]),
    artConfig() {
      return this.getConfig();
    }
  },
  methods: {
    updateCompare(value) {
      if(this.quarterType == 'year') {
        basicYearStatistics({
          oldYear: value[0],
          nowYear: value[1]
        }).then((data) => {
          this.topData.thanYear = Number(data).toFixed(2);
        });
      } else {
        basicSeasonStatistics({
          oldQuarter: `${value[0]}-Q${value[1]}`,
          nowQuarter: `${value[2]}-Q${value[3]}`
        }).then((data) => {
          this.topData.thanYear = Number(data).toFixed(2);
        });
      }
    },
    onBasicStatistics() {
      this.chartLoading = true;
      const { time } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      this.$refs.pickerSelect.clear();
      this.quarterType = "year";
      basicStatistics({
        startTime,
        endTime,
        queryType: this.artConfig.isAmountNumberMode
      })
        .then((data) => {
          let amount = [];
          for(let i in data.amount) {
            amount.push({
              key: i,
              value: data.amount[i]
            })
          }
          this.topData = {
            ...data,
            currencyAmountVos: amount,
            total: this.artConfig.isAmountNumberMode ? Number(data.totalAmount.USD) : formatNum(Number(data.totalAmount.USD).toFixed(2)),
            thanYear: Number(data.thanYear).toFixed(2),
            thanSeason: Number(data.thanQuarter).toFixed(2),
          };
        })
        .finally(() => (this.chartLoading = false));
    }
  },
};
</script>
<style lang="less" scoped>
.statisticsBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .top {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      .item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
        padding: 20px 16px;
        border-radius: 4px;
        background: #F7F9FC;
        &:nth-child(1) {
          .title {
            color: #004C97;
            .icon {
              background: #004C97;
            }
          }
          .number {
            color: #004C97;
          }
        }
        &:nth-child(2) {
          .title {
            color: #076F49;
            .icon {
              background: #076F49;
            }
          }
          .number {
            color: #076F49;
          }
        }
        &:nth-child(3) {
          .title {
            color: #004C97;
            .icon {
              background: #004C97;
            }
          }
          .number {
            color: #004C97;
          }
        }
        &:nth-child(4) {
          .title {
            color: #076F49;
            .icon {
              background: #076F49;
            }
          }
          .number {
            color: #076F49;
          }
        }
        .title {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 16px;
          line-height: 22px;
          .icon {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 20px;
              color: #ffffff;
            }
          }
        }
        .number {
          font-size: 32px;
          line-height: 37px;
          font-weight: 700;
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      background: #F7F9FC;
      border-radius: 4px;
      padding: 24px 30px;
      .member {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ffffff;
        .member_text {
          font-size: 16px;
          color: #122545;
          white-space: nowrap;
        }
        .member_num {
          font-size: 40px;
          font-weight: 900;
          line-height: 46px;
          color: #122545;
          display: inline-block;
          cursor: pointer;
        }
      }
      .compare {
        flex: 1;
        .item {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-items: center;
          gap: 8px;
          .quarter {
            display: flex;
            flex-direction: column;
            .btns {
              display: flex;
              justify-content: space-between;
              flex-wrap: nowrap;
              .el-button {
                padding: 4px 10px;
              }
            }
          }
          span {
            font-size: 32px;
            font-weight: 500;
            line-height: 37px;
            display: flex;
            align-items: center;
            gap: 8px;
            i {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .right {
    width: 260px;
    height: 100%;
    background: #F7F9FC;
    border-radius: 4px;
    padding: 20px 16px;
    .member {
      .title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        line-height: 22px;
        color: #1B2B59;
        .icon {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #1B2B59;
          i {
            font-size: 20px;
            color: #ffffff;
          }
        }
      }
      .number {
        font-size: 32px;
        line-height: 37px;
        font-weight: 700;
        color: #1B2B59;
        margin-top: 16px;
      }
    }
    .bottomBox {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        border-bottom: 1px solid #ffffff;
        &:last-child {
          padding-top: 15px;
          padding-bottom: 0;
          border: none;
        }
        &:nth-child(1) {
          .title {
            &::before {
              background: #DC3545;
            }
          }
        }
        &:nth-child(2) {
          .title {
            &::before {
              background: #48AC42;
            }
          }
        }
        &:nth-child(3) {
          .title {
            &::before {
              background: #2E4793;
            }
          }
        }
        .title {
          display: flex;
          align-items: center;
          gap: 9px;
          font-size: 16px;
          line-height: 16px;
          color: #122545;
          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }
        span {
          font-size: 20px;
          line-height: 16px;
          font-weight: 700;
          color: #122545;
        }
      }
    }
  }
}
</style>
