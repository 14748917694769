<template>
  <el-card
    class="Commodity"
    :ref="captureId"
    v-loading="exportLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">
        {{ $t("Dashboard.ProductionLogisticsAnalysis") }}
        <div class="echartsTypeTabs">
          <div class="item" :class="isPieChart ? 'active' : ''" @click="isPieChart = true">
            <i class="iconfont icon-shujukanban1"></i>
          </div>
          <div class="item" :class="!isPieChart ? 'active' : ''" @click="isPieChart = false">
            <i class="iconfont icon-shujukanban"></i>
          </div>
        </div>
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onskuAmountList()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen">
      <el-select
        size="small"
        v-model="data.startStage"
        appendToBody
        style="width: 120px;"
        :placeholder="$t('Dashboard.Matter')"
        @change="stageChange1"
      >
        <el-option
          v-for="item in orderStatusOptions.filter((item, index) => index < (data.endStage - 1))"
          :key="item.id"
          :label="$t(item.label)"
          :value="item.id"
        />
      </el-select>
      <el-select
        size="small"
        v-model="data.endStage"
        appendToBody
        style="width: 120px;"
        :placeholder="$t('Dashboard.Matter')"
        @change="stageChange2"
      >
        <el-option
          v-for="item in orderStatusOptions.filter((item, index) => index > (data.startStage - 1))"
          :key="item.id"
          :label="$t(item.label)"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-if="artConfig.echarts_type === '2'"
        size="small"
        v-model="data.country"
        clearable
        appendToBody
        style="width: 120px;"
        :placeholder="$t('home.Pleaseselectcountry')"
      >
        <el-option
          v-for="item in countryOptions"
          :key="item.iso3"
          :label="$isFindData(country, item.iso3, 'value', 'key')"
          :value="item.iso3"
        />
      </el-select>
      <el-select
        v-if="artConfig.echarts_type === '1'"
        size="small"
        v-model="data.customer"
        clearable
        appendToBody
        style="width: 120px;"
        :placeholder="
          companyCurrentType == '1' || companyCurrentType == '2'
            ? $t('home.PleaseselectCustomer')
            : $t('home.PleaseselectSupplier')
        "
      >
        <el-option
          v-for="(item, index) in customerOptions"
          :key="index"
          :label="item.aliasName"
          :value="item.companyId"
        >
          <div class="option_list">
            <div>{{ item.aliasName }}</div>
            <div>{{ $t(`home.${$companyTypes[item.companyType]}`) }}</div>
          </div>
        </el-option>
      </el-select>
      <el-date-picker
        style="width: 260px" 
        type="daterange" 
        v-model="data.time" 
        size="small"
        :start-placeholder="$t('Dashboard.Startfrom')" 
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData" 
      />
    </div>
    <div style="margin-top: 22px;position: relative;">
      <div
        :id="echartsViewPieId"
        class="orderStausEcharts"
        :style="`height: ${
          this.enlarge == false ? '300px' : '70vh'
        }; opacity: ${isPieChart ? '1' : '0'}; z-index: ${
          isPieChart ? '2' : '1'
        }; position: absolute;`"
      />
      <ul
        :class="this.enlarge ? 'enlarge_statistics' : 'statistics'"
        :style="`opacity: ${isPieChart ? '1' : '0'}; z-index: 0;`"
      >
        <li>
          <div class="title">{{$t('Dashboard.TotalAveage')}}</div>
          <span>{{ chartTotal }}</span>
        </li>
        <li>
          <div class="title">{{ $t('Dashboard.quantity') }}</div>
          <span>{{ numberCountry }}</span>
        </li>
        <li>
          <div class="title">{{ $t('Dashboard.Proportion') }}</div>
          <span>{{ proportion }}%</span>
        </li>
      </ul>
      <div
        :id="echartsViewBarId"
        class="orderStausEcharts"
        :style="`height: ${
          this.enlarge == false ? '300px' : '70vh'
        }; opacity: ${!isPieChart ? '1' : '0'}; z-index: ${
          !isPieChart ? '2' : '1'
        };`"
      />
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import * as echarts from "echarts";
import html2canvas from "html2canvas";
import { 
  orderStatusSummary,
  getCountryTop3,
  getCompanyTop3,
} from "@/api/chart";
import { pickerOptions } from "@/utils/utils";
import { orderBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: 'skuAmount',
  components: {},
  data() {
    return {
      chart: null,
      chartTwo: null,
      echartsViewPieId: 'orderStatusPieChart_',
      echartsViewBarId: 'orderStatusHistogramName_',
      captureId: 'captureOrderStatus_',
      enlarge: false,
      exportLoading: false,
      isPieChart: true,
      chartTotal: 0,
      numberCountry: 0,
      proportion: 0,
      countryOptions: [],
      customerOptions: [],
      orderStatusData: [],
      data: {
        country: null,
        customer: '',
        itemNo: '',
        time: [dayjs().startOf('year'), dayjs()],
        startStage: 1,
        endStage: 7
      },
    };
  },
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  inject: ['getConfig'],
  computed: {
    ...mapGetters(["orderStatusOptions", "country", "companyCurrentType"]),
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj, oldObj) {
        if(this.$route.name != 'Dashboard') return;
        this.exportLoading = true;
        this.onskuAmountList();
      },
      deep: true,
    },
    enlarge() {
      this.onPieChart(this.orderStatusData);
      this.onHistogram(this.orderStatusData);
    },
    'artConfig.echarts_type'(val) {
      setTimeout(() => {
        this.chart.resize();
        this.chartTwo.resize();
      }, 1);
    }
  },
  mounted() {
    this.echartsViewPieId = this.echartsViewPieId + this.echartsType;
    this.echartsViewBarId = this.echartsViewBarId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.chart) {
      this.chart.resize();
    }
    if(this.chartTwo) {
      this.chartTwo.resize();
    }
  },
  methods: {
    stageChange1(val) {

    },
    stageChange2(val) {

    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document
          .getElementById(this.captureId)
          .classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
        if (!document.fullscreenElement) {
          setTimeout(() => {
            this.chart.resize();
            this.chartTwo.resize();
          }, 1);
        }
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
        if (!document.fullscreenElement) {
          setTimeout(() => {
            this.chart.resize();
            this.chartTwo.resize();
          }, 1);
        }
      }
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            this.exportLoading = false;
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.$t("Dashboard.ProductionLogisticsAnalysis");
            a.click();
          });
        }, 200);
        return;
      }
      if(!this.isPieChart) {
        let chartOption = this.chartTwo.getOption();
        let header = [
          ...chartOption.xAxis[0].data
        ];
        let data = [chartOption.series[0].data];
        let filename = this.$t("Dashboard.ProductionLogisticsAnalysis");
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      } else {
        let chartOption = this.chart.getOption();
        let header = chartOption.series[0].data.map(item => item.name.split(" ")[0]);
        let data = [chartOption.series[0].data.map(item => item.value.toFixed(2))];
        let filename = this.$t("Dashboard.ProductionLogisticsAnalysis");
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      }
    },
    onskuAmountList() {
      this.exportLoading = true;
      const { 
        time, 
        startStage,
        endStage,
        country, 
        customer, 
        itemNo
      } = this.data;
      if(this.echartsType === '2') {
        getCountryTop3({
          customer
        }).then((data) => {
          this.countryOptions = data;
        })
      }
      if(this.echartsType === '1') {
        getCompanyTop3({
          country
        }).then((data) => {
          this.customerOptions = data;
        })
      }
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      this.chart = this.$echartsInit(document.getElementById(this.echartsViewPieId));
      orderStatusSummary({
        startTime,
        endTime,
        startStage,
        endStage,
        country,
        customer,
        itemNo
      })
        .then((data) => {
          this.orderStatusData = data;
          this.onPieChart(this.orderStatusData);
          this.onHistogram(this.orderStatusData);
          this.exportLoading = false;
        })
        .finally(() => (this.exportLoading = false));
    },
    onPieChart(data = []) {
      let total = 0;
      let proportion = 0;
      let arr = orderBy(
        data,
        (v) => {
          return Number(v.days);
        },
        'desc'
      )?.map((v,index) => {
        total += Number(v?.days);
        proportion += Number(v?.proportion) || 0;
        return {
          value: Number(v?.days),
          proportion: v?.proportion || 0,
          name: `${this.$t(`${this.orderStatusOptions[index].label}`)} ${Number(v?.days).toFixed(1)}`
        };
      });
      this.chartTotal = Number(total).toFixed(1) || '0';
      this.proportion = Number(proportion).toFixed(2);
      this.numberCountry = arr.length;
      const chartData = !this.enlarge
        ? {
          labelLine: {
            show: false,
          },
          label: {
            show: false,
            position: "center",
          },
        }
        : {};
      this.chart.setOption(
        {
          tooltip: {
            trigger: 'item',
            confine: true,
            formatter: function (params) {
              var htmlStr = `
                <div class="echartsTooltipPieBox">
                  <div class="content">
                    <div class="label">${params.name.split(" ")[0]}</div>
                    <div class="value">${params.name.split(" ")[1]}</div>
                  </div>
                </div>
              `;
              return htmlStr;
            },
          },
          legend: {
            left: 'center',
            bottom: '0px',
            type: 'scroll',
          },
          series: [
            {
              type: 'pie',
              left: '-25%',
              bottom: "15%",
              radius: '90%',
              avoidLabelOverlap: false,
              data: arr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              ...chartData,
            },
          ],
        },
        true
      );
      this.chart.on("legendselectchanged", (params) => {
        let proportionValue = 0;
        let chartTotal = 0;
        arr.map((v) => {
          const { name, proportion, value } = v;
          for (let key in params?.selected) {
            if (name == key && params?.selected[key]) {
              proportionValue += Number(proportion);
              chartTotal += Number(value);
            }
          }
        });
        let objectis = Object.values(params.selected).filter((item) => item);
        this.numberCountry = objectis.length;
        this.proportion = proportionValue.toFixed(2);
        this.chartTotal = Number(chartTotal).toFixed(1);
      });
      // chart.setOption
      window.addEventListener("resize", () => this.chart.resize());
    },
    onHistogram(data) {
      this.chartTwo = this.$echartsInit(document.getElementById(this.echartsViewBarId));
      const seriesMark = this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {}
      this.chartTwo.setOption({
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: [
          {
            top: '10%',
            left: 40,
            right: '3%',
            bottom: '14%',
            containLabel: true,
          }
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
            },
            data: data.map((v, index) => {
              return this.$t(`${this.orderStatusOptions[index].label}`);
            })
          },
          { gridIndex: 1, show: false },
        ],
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: { color: '#B0B8BF' },
            show: false,
          },
        },
        dataZoom: [
          {
            show: true,
            type: 'slider',
            left: 'center',
            bottom: '0%',
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            data: data.map((v) => {
              return Number(v?.days).toFixed(1);
            }),
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: 2.64,
              },
            },
            ...seriesMark
          },
        ],
      });
      window.addEventListener("resize", () => this.chartTwo.resize());
    }
  },
};
</script>
<style lang="less" scoped>
.Commodity {
  position: relative;

  .echarts_type {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 130px;
    font-size: 12.25px;
    font-weight: 400;
    color: rgba(46, 63, 79, 0.5);
    padding: 6px 12px;
    border: 0.89px solid rgba(46, 63, 79, 0.1);
    overflow: hidden;
  }
}

.sku_amount_left_screen {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

#skuAmountPieChart {
  width: 100%;
  height: 350px;
}

.Commodity.fullscreen .skuAmountPieChart {
  width: 100%;
  height: 800px !important;
}

.orderStausEcharts {
  width: 100%;
  height: 350px;
}

/deep/.el-input-number.is-controls-right .el-input-number__increase {
  line-height: 15px;
}

/deep/.el-input-number.is-controls-right .el-input-number__decrease {
  line-height: 15px;
}

/deep/.el-input-number {
  line-height: 30px;
}
</style>
