<template>
  <el-card 
    :id="echartsViewId" 
    class="Commodity"
    v-loading="chartLoading">
    <div class="left_title" style="cursor: auto;">
      <div class="title">{{ $t("Dashboard.BasicStatistics") }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <el-button 
          size="small"
          :type="allStatus ? 'primary' : ''" 
          @click="allDataEvent()"
        >{{ $t('Dashboard.whole') }}</el-button>
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <!-- <div class="popperMenuItem" @click="savePdf('excel')"> -->
              <!-- <i class="el-icon-download" /> -->
              <!-- <span>{{ $t('Files.ExportExcel') }}</span> -->
            <!-- </div> -->
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onBasicStatistics()" 
        />
      </div>
    </div>
    <div class="three_box">
      <div class="leftBox"> 
        <div class="member">
          <div class="left">
            <i class="iconfont icon-chengyuanshu1"></i>
          </div>
          <div class="right">
            <div class="member_text">{{ $t('Dashboard.Member') }}</div>
            <div class="member_num">{{ topData.member || 0 }}</div>
          </div>
        </div>
        <div class="member">
          <div class="left">
            <i class="iconfont icon-gongyingshangshu1"></i>
          </div>
          <div class="right">
            <div class="member_text">
              {{
                companyCurrentType == '1' || companyCurrentType == '2'
                  ? $t('Dashboard.Customer')
                  : $t('Dashboard.Supplier')
              }}
            </div>
            <div class="member_num">{{ topData.customer || 0 }}</div>
          </div>
        </div>
      </div>
      <div class="leftBox"> 
        <div class="member">
          <div class="left">
            <i class="iconfont icon-guojiashu"></i>
          </div>
          <div class="right">
            <div class="member_text">
              {{ $t('Dashboard.country') }}
            </div>
            <div class="member_num">{{ topData.country }}</div>
          </div>
        </div>
        <div class="member">
          <div class="left">
            <i class="iconfont icon-chanpinshu"></i>
          </div>
          <div class="right">
            <div class="member_text">
              {{ $t('Dashboard.Product') }}
            </div>
            <div class="member_num">{{ topData.sku }}</div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="member">
          <div class="left">
            <i class="iconfont icon-dingdanshu1"></i>
          </div>
          <div class="right">
            <div class="member_text">{{ $tc('Dashboard.Orders', $isOrderTitle()) }}</div>
            <div class="member_num">{{ topData.order }}</div>
          </div>
        </div>
        <div class="rightContent">
          <div class="item">
            {{ isDefaultNode ? $t('Dashboard.Shipping') : $t("order.pending") }}
            <span>{{ topData.transport }}</span>
          </div>
          <div class="item" v-show="isDefaultNode">
            {{ $t('Dashboard.Producing') }}
            <span>{{ topData.production }}</span>
          </div>
          <div class="item">
            {{ $t('Dashboard.Completed') }}
            <span>{{ topData.completed }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="three_box_bottom">
      <div class="left">
        <el-popover :disabled="Boolean(artConfig.isAmountNumberMode)" placement="right" :width="500" trigger="hover">
          <el-table stripe :data="topData.currencyAmountVos">
            <el-table-column
              prop="key"
              :label="$t('Dashboard.Currency')"
            />
            <el-table-column prop="value" :label="$t('Dashboard.Amount')" />
          </el-table>
          <div slot="reference" class="member">
            <div class="member_text">
              {{ !artConfig.isAmountNumberMode ? $t('Dashboard.Total') : $t('order.Quantity') }}
            </div>
            <div class="member_num">
              {{ topData.total }}
            </div>
          </div>
        </el-popover>
      </div>
      <div class="compare">
        <div class="item">
          <span :style="`color: ${thanYear > 0 ? '#48AC42' : '#C84154'}`">
            <i class="iconfont icon-shangsheng" v-if="thanYear > 0"></i>
            <i class="iconfont icon-xiajiang" v-else></i>
            {{ thanYear }}%
          </span>
          <div class="quarter">
            <pickerSelect 
              :type="quarterType"
              :valueQuarter.sync="byQuarter" 
              ref="pickerSelect" 
              width="100%"
            />
            <div class="btns">
              <el-button :type="quarterType == 'seasonSame' ? 'primary' : ''" size="mini" @click="quarterType = 'seasonSame'">{{ $t('home.Quarter on quarter') }}</el-button>
              <el-button :type="quarterType == 'seasonRing' ? 'primary' : ''" size="mini" @click="quarterType = 'seasonRing'">{{ $t('home.Quarter to quarter ratio') }}</el-button>
              <el-button :type="quarterType == 'year' ? 'primary' : ''" size="mini" @click="quarterType = 'year'">{{ $t('home.Year on year') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { basicStatistics, basicYearStatistics, basicSeasonStatistics } from '@/api/chart';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { formatNum, pickerOptions } from '@/utils/utils';

import pickerSelect from "@/components/pickerSelect"

export default {
  name: 'statistics',
  components: {
    pickerSelect
  },
  data() {
    return {
      allStatus: false,
      echartsViewId: 'exportStatisticsPdf_0',
      exportLoading: false,
      data: {
        time: [],
      },
      topData: {},
      yearData: this.$getGradeData(1800),
      chartLoading: false,
      byYear: "",
      byQuarterCopy: "",
      quarterType: "year",
      thanYear: "",
      byQuarter: [new Date().getFullYear() - 1, new Date().getFullYear()],
      season: Math.ceil(dayjs().format('MM') / 3) - 1 || 4,
    };
  },
  inject: ['getConfig', 'getTimeConfig'],
  props: [],
  watch: {
    data: {
      handler() {
        if(this.$route.name != 'Dashboard') return;
        this.onBasicStatistics();
      },
      deep: true
    },
    byQuarter: {
      handler(val) {
        this.byQuarterCopy = val;
        this.updateCompare(val)
      },
      deep: true,
      immediate: true
    },
    quarterType(val) {
      if(val == 'year') {
        this.byQuarter = [new Date().getFullYear() - 1, new Date().getFullYear()];
      }
      if(val == 'seasonSame') {
        var currMonth= new Date().getMonth() + 1;
        var currQuarter = Math.floor((currMonth % 3 == 0 ? (currMonth/3) : (currMonth / 3 + 1)));
        this.byQuarter = [new Date().getFullYear() - 1, currQuarter, new Date().getFullYear(), currQuarter];
      }
      if(val == 'seasonRing') {
        var currMonth= new Date().getMonth() + 1;
        var currQuarter = Math.floor((currMonth % 3 == 0 ? (currMonth/3) : (currMonth / 3 + 1)));
        if(currQuarter > 1) {
          this.byQuarter = [new Date().getFullYear(), currQuarter - 1,  new Date().getFullYear(), currQuarter];
        } else {
          this.byQuarter = [new Date().getFullYear() - 1, 4,  new Date().getFullYear(), currQuarter];
        }
      }
    },
    'artConfig.isAmountNumberMode'() {
      this.onBasicStatistics();
      this.updateCompare(this.byQuarterCopy);
    },
  },
  computed: {
    ...mapGetters(["companyCurrentType", "isDefaultNode"]),
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  methods: {
    updateCompare(value) {
      if(this.quarterType == 'year') {
        basicYearStatistics({
          oldYear: value[0],
          nowYear: value[1],
          queryType: this.artConfig.isAmountNumberMode
        }).then((data) => {
          this.thanYear = Number(data).toFixed(2);
        });
      } else {
        basicSeasonStatistics({
          oldQuarter: `${value[0]}-Q${value[1]}`,
          nowQuarter: `${value[2]}-Q${value[3]}`,
          queryType: this.artConfig.isAmountNumberMode
        }).then((data) => {
          this.thanYear = Number(data).toFixed(2);
        });
      }
    },
    allDataEvent() {
      this.allStatus = !this.allStatus;
      if(this.allStatus) {
        this.data.time = [];
      } else {
        this.data.time = this.getTimeConfig().globalTimeAll;
      }
    },
    onBasicStatistics() {
      this.chartLoading = true;
      const { time } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      // this.$refs.pickerSelect.clear();
      basicStatistics({
        startTime,
        endTime,
        queryType: this.artConfig.isAmountNumberMode
      })
        .then((data) => {
          let amount = [];
          for(let i in data.amount) {
            amount.push({
              key: i,
              value: data.amount[i]
            })
          }
          this.topData = {
            ...data,
            currencyAmountVos: amount,
            total: this.artConfig.isAmountNumberMode ? Number(data.totalAmount.USD) : formatNum(Number(data.totalAmount.USD).toFixed(2)),
            thanYear: Number(data.thanYear).toFixed(2),
            thanSeason: Number(data.thanQuarter).toFixed(2),
          };
        })
        .finally(() => (this.chartLoading = false));
    },
    //导出(pdf)
    savePdf(type) {
      this.$refs.echartsBtns.style.display = "none";
      setTimeout(() => {
        // setAttribute 需要一定时间生效
        html2canvas(document.getElementById(this.echartsViewId), {
          useCORS: true
        }).then((canvas) => {
          this.$refs.echartsBtns.style.removeProperty('display');
          const oImg = new Image();
          oImg.setAttribute('crossOrigin', 'anonymous')
          oImg.src = canvas.toDataURL(); // 导出图片
          canvas.toDataURL('image/png');
          // 下载图片
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.href = oImg.src;
          // 设置下载标题
          a.download = '我的文件';
          a.click();
        });
      }, 200);
    },
  },
};
</script>
<style lang="less" scoped>
.Commodity {
  .three_box {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    margin-top: 16px;
    .leftBox {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      .member {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
        background: #F7F9FC;
        border-radius: 4px;
        padding: 16px;
        height: 77px;
        .left {
          width: 44px;
          height: 44px;
          background: #e4eeee;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 45px;
            color: #076F49;
          }
        }
        .right {
          .member_text {
            font-size: 14px;
            line-height: 20px;
            color: #757D8A;
            white-space: nowrap;
          }
          .member_num {
            font-size: 24px;
            line-height: 28px;
            color: #122545;
            font-weight: 700;
          }
        }
      }
    }
    .rightBox {
      width: 50%;
      background: #F7F9FC;
      border-radius: 4px;
      padding: 16px;
      .rightContent {
        display: flex;
        flex-direction: column;
        margin-top: 18px;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 16px;
          color: #122545;
          margin-bottom: 12px;
          white-space: nowrap;
          span {
            font-size: 14px;
            display: block;
            width: 50%;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;
          }
          &:last-child {
            margin-bottom: 0;
          }
          &::before {
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            background: #076F49;
            border-radius: 50%;
          }
          &:nth-child(1) {
            &::before {
              background: #DC3545;
            }
          }
          &:nth-child(2) {
            &::before {
              background: #48AC42;
            }
          }
          &:nth-child(3) {
            &::before {
              background: #2E4793;
            }
          }
        }
      }
      .member {
        width: 40%;
        display: flex;
        gap: 8px;
        .left {
          width: 44px;
          height: 44px;
          background: #e4eeee;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 40px;
            color: #076F49;
          }
        }
        .right {
          .member_text {
            font-size: 14px;
            line-height: 20px;
            color: #757D8A;
            white-space: nowrap;
          }
          .member_num {
            font-size: 24px;
            line-height: 28px;
            color: #122545;
            font-weight: 700;
          }
        }
      }
    }
  }

  .three_box_bottom {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: 58px;

    .member {
      display: flex;
      flex-direction: column;
     
      .member_text {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #757D8A;
      }

      .member_num {
        font-size: 32px;
        line-height: 38px;
        font-weight: 800;
        color: #122545;
        max-width: 280px;
        word-break: break-all;
      }
    }

    .compare {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 5px;
      .item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        .quarter {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .btns {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            .el-button {
              padding: 4px 10px;
            }
          }
        }
        span {
          font-size: 24px;
          line-height: 28px;
          font-weight: 500;
          display: flex;
          .icon-xiajiang {
            color: #C84154;
          }
          .icon-shangsheng {
            color: #48AC42;
          }
        }
        p {
          font-size: 14px;
          color: #757D8A;
        }
      }
    }
  }
}
</style>
