<template>
  <div>
    <div v-if="!isEmty">
      <div class="basticInfo">
        <countryStatistics ref="countryStatistics" />
        <statistics ref="statisticsCountryMarket" type="country" />
        <purchasAmount ref="componentCountryTotals" echartsType="1" />
      </div>
      <draggable
        v-model="dashboardList"
        animation="200"
        ghostClass="ghost"
        group="people"
        handle=".Commodity .left_title"
        @end="updateDraggable"
      >
        <transition-group class="Statistical_cart_box">
          <div v-for="(item, index) in dashboardList" :key="item">
            <keep-alive>
              <component
                :is="dashboardObject[item]"
                ref="componentCountryTotal"
                echartsType="1"
              ></component>
            </keep-alive>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div v-else class="empty_box">
      <el-card>
        <el-empty 
          :image="$emptyImage"
          :image-size="90"
        ></el-empty>
      </el-card>
    </div>
    <el-badge 
      v-if="contrastConfig.length"
      :value="contrastConfig.length" 
      :hidden="!contrastConfig.length" 
      class="contrastDialogBadge">
      <div class="contrastDialogBadgeBox" @click="seeContrastDialog"></div>
    </el-badge>
    <contrastDialog ref="contrastDialog" />
  </div>
</template>

<script>
import { getCountryDetail } from "@/api/chart";
import purchasAmount from '../components/purchasAmount.vue';
import statistics from '../components/statisticsCountryMarket.vue';
import countryStatistics from "../components/countryStatistics.vue"
import skuUnitPrice from '../components/skuUnitPrice.vue';
import skuAmount from '../components/skuAmount.vue';
import exchangeRate from '../components/exchangeRate.vue';
import commodityPrice from '../components/commodityPrice.vue';
import exportData from '../components/exportData.vue';
import exportProportion from '../components/exportProportion.vue';
import supplierAmount from '../components/supplierAmount.vue';
import supplierConparison from '../components/supplierConparison.vue';
import bdiIndexNumber from "../components/bdiIndexNumber.vue";
import orderStatusSummary from "../components/orderStatusSummary.vue";
import pmiIndexNumber from "../components/pmiIndexNumber.vue";
import contrastDialog from '../dialog/contrastDialog.vue';
import { mapGetters } from "vuex";
import { setPersonalization } from "@/api/common";
export default {
  name: 'dashboardCountry',
  components: {
    purchasAmount,
    statistics,
    countryStatistics,
    supplierConparison,
    supplierAmount,
    exportData,
    exportProportion,
    commodityPrice,
    exchangeRate,
    skuAmount,
    skuUnitPrice,
    bdiIndexNumber,
    orderStatusSummary,
    contrastDialog,
    pmiIndexNumber
  },
  data() {
    return {
      isEmty: false,
      dashboardList: [],
      dashboardObject: {
        0: pmiIndexNumber,
        // 1: orderStatusSummary,
        2: bdiIndexNumber,
        3: commodityPrice,
        4: supplierConparison,
        5: supplierAmount,
        6: exchangeRate,
        7: exportData,
        8: exportProportion,
        9: skuAmount,
        10: skuUnitPrice,
      },
      contrastConfig: []
    }
  },
  inject: ['getConfig', 'getTimeConfig'],
  computed: {
    ...mapGetters(['chartOrder', 'personalization']),
    actConfig() {
      return this.getConfig();
    },
    timeConfig() {
      return this.getTimeConfig();
    },
  },
  mounted() {
    this.setCountryValue(this.actConfig.countryId);
  },
  watch: {
    chartOrder: {
      handler(val) {
        this.dashboardList = val.country.filter(item => item != 1);
      },
      deep: true,
      immediate: true
    },
    'actConfig.countryId'(val, old) {
      this.setCountryValue(val, this.actConfig.companyType)
    },
    'timeConfig.globalTimeCountry': {
      handler(val) {
        this.$nextTick(() => {
          this.setTime(val, true);
        })
      },
      deep: true
    }
  },
  methods: {
    updateDraggable() {
      setPersonalization({
        ...this.personalization,
        dashboardOrder: `${this.chartOrder.all}|${this.dashboardList}|${this.chartOrder.customer}|${this.chartOrder.product}`
      })
    },
    seeContrastDialog() {
      this.$refs.contrastDialog.dialogVisible = true;
      this.$refs.contrastDialog.contrastConfig = this.contrastConfig;
    },
    setTime(val, globalTime) {
      if(globalTime) {
        this.$refs.statisticsCountryMarket.data.time = val;
      }
      this.$refs.componentCountryTotals.data.time = val;
      for (let i = 0; i < this.$refs.componentCountryTotal.length; i++) {
        const element = this.$refs.componentCountryTotal[i];
        element.data.time = val;
      }
    },
    setCountryValue(countryId) {
      getCountryDetail(countryId).then((data) => {
        if(data) {
          this.isEmty = false;
          this.$nextTick(() => {
            this.setTime(this.timeConfig.globalTimeCountry, false);
            this.$refs.statisticsCountryMarket.data = {
              ...this.$refs.statisticsCountryMarket.data,
              country: countryId,
              time: this.timeConfig.globalTimeCountry
            };
            this.$refs.countryStatistics.topData = data;
            this.$refs.componentCountryTotals.data = {
              ...this.$refs.componentCountryTotals.data,
              country: countryId
            }
            for (let i = 0; i < this.$refs.componentCountryTotal.length; i++) {
              const element = this.$refs.componentCountryTotal[i];
              element.data = {
                ...element.data,
                country: countryId
              };
            }
          })
          return;
        }
        this.isEmty = true;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.empty_box {
  margin: 20px;
}
</style>