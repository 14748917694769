<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">
        {{ title }}
        <div class="echartsTypeTabs" v-if="artConfig.isVipMode != 0">
          <div class="item" :class="isPieChart ? 'active' : ''" @click="isPieChart = true">
            <i class="iconfont icon-shujukanban1"></i>
          </div>
          <div class="item" :class="!isPieChart ? 'active' : ''" @click="isPieChart = false">
            <i class="iconfont icon-shujukanban"></i>
          </div>
        </div>
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon v-if="artConfig.isVipMode == 0" icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onGetAmount()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen" v-if="artConfig.isVipMode == 0">
      <el-date-picker
        style="width: 260px"
        v-model="data.time"
        type="daterange"
        size="small"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
    </div>
    <div style="margin-top: 22px;position: relative;">
      <div
        :id="echartsViewPieId"
        class="amountChart"
        :style="{
          height: enlarge == false ? '300px' : '70vh',
          opacity: isPieChart ? '1' : '0',
          zIndex: isPieChart ? '2' : '1',
          position: 'absolute'
        }"
      />
      <ul v-if="isPieChart" :class="enlarge ? 'enlarge_statistics' : 'statistics'">
        <li>
          <div class="title">{{ !artConfig.isAmountNumberMode ? $t('Dashboard.Total') : $t('Dashboard.TotalNumber')}}</div>
          <span>{{ chartTotal }}</span>
        </li>
        <li>
          <div class="title">{{ $tc('Dashboard.SupplierNumber', companyCurrentType) }}</div>
          <span>{{ numberCustomer }}</span>
        </li>
        <li>
          <div class="title">{{ $t('Dashboard.Proportion') }}</div>
          <span>{{ proportion }}%</span>
        </li>
      </ul>
      <div
        :id="echartsViewBarId"
        class="amountChart"
        :style="{
          height: enlarge == false ? '300px' : '70vh',
          opacity: !isPieChart ? '1' : '0',
          zIndex: !isPieChart ? '1' : '0'
        }"
      />
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getAmount } from "@/api/chart";
import html2canvas from "html2canvas";
import { formatNum, pickerOptions, new_date } from "@/utils/utils";

export default {
  name: 'supplierAmount',
  data() {
    return {
      myChartPie: null,
      myChartBar: null,
      echartsViewPieId: 'amountChartPie_',
      echartsViewBarId: 'amountChartBar_',
      captureId: 'captureFive',
      chartTotal: 0,
      numberCustomer: 0,
      enlarge: false,
      exportLoading: false,
      proportion: 100,
      echartsData: [],
      isPieChart: true,
      data: {
        country: null,
        customer: '',
        itemNo: '',
        type: '',
        size: '',
        time: [dayjs().startOf('year'), dayjs()],
      },
      chartLoading: false,
    };
  },
  inject: ['getConfig'],
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(['companyCurrentType']),
    locale() {
      return this.$t('home.Noinformation');
    },
    title() {
      let str = this.companyCurrentType == '1' ? this.$t('Dashboard.customer') : this.$t('Dashboard.Supplier');
      return str+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`)
    },
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.chartLoading = true;
        this.onGetAmount();
      },
      deep: true,
    },
    enlarge() {
      this.onPieChart(this.echartsData);
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.myChartPie.resize();
        this.myChartBar.resize();
      }, 1);
    },
    'artConfig.isAmountNumberMode'() {
      this.onGetAmount();
    }
  },
  mounted() {
    this.echartsViewPieId = this.echartsViewPieId + this.echartsType;
    this.echartsViewBarId = this.echartsViewBarId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChartPie) {
      this.myChartPie.resize();
    }
    if(this.myChartBar) {
      this.myChartBar.resize();
    }
  },
  methods: {
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document.getElementById(this.captureId).classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      this.$nextTick(() => {
        this.myChartPie.resize();
        this.myChartBar.resize();
      });
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.title;
            a.click();
          });
        }, 200);
        return;
      }
      if(!this.isPieChart) {
        let chartOption = this.myChartBar.getOption();
        let header = [
          ...chartOption.xAxis[0].data
        ];
        let data = [chartOption.series[0].data];
        let filename = this.title;
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      } else {
        let chartOption = this.myChartPie.getOption();
        let header = chartOption.series[0].data.map(item => item.namedialog);
        let data = [chartOption.series[0].data.map(item => `${item.proportion}%:${item.value}`)];
        let filename = this.title;
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      }
    },
    onGetAmount() {
      this.chartLoading = true;
      const {  
        time, 
        country, 
        customer, 
        itemNo,
        type,
        size 
      } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getAmount({ 
        startTime, 
        endTime, 
        country, 
        customer, 
        itemNo,
        type,
        size,
        queryType: this.artConfig.isAmountNumberMode
      }).then(({data}) => {
        this.echartsData = data;
        this.onPieChart(data);
        this.onHistogram(data);
        this.chartLoading = false;
      }).finally(() => (this.chartLoading = false));
    },
    onHistogram(data) {
      this.myChartBar = this.$echartsInit(document.getElementById(this.echartsViewBarId));
      const seriesMark = this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {}
      this.myChartBar.setOption({
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: [
          {
            top: "5%",
            left: "3%",
            right: "3%",
            bottom: "14%",
            containLabel: true,
          }
        ],
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
            },
            data: data.map(v => v.aliasName || v.name)
          },
          { gridIndex: 1, show: false },
        ],
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: { color: '#B0B8BF' },
            show: false,
          },
        },
        dataZoom: [
          {
            show: true,
            type: 'slider',
            left: 'center',
            bottom: '0%',
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            data: data.map(v => ({
              value: Number(v?.amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2),
              itemStyle: {
                color: v.aliasName == '其他' || v.aliasName == 'other' ? '#E3E9F1' : null
              }
            })),
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: 2.64,
              },
            },
            ...seriesMark
          },
        ],
      });
    },
    onPieChart(data) {
      this.myChartPie = this.$echartsInit(document.getElementById(this.echartsViewPieId));
      let total = 0;
      let arr = [];
      data.forEach((v) => {
        total += Number(v.amount);
      });
      arr = data.map((item) => {
        const { aliasName, name, amount, companyId } = item;
        return {
          value: Number(amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2),
          companyId: companyId,
          name: `${ aliasName || name } ${Number((amount / total) * 100).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2)}%：${formatNum(Number(amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2)) || "0"}${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}`,
          proportion: Number((amount / total) * 100).toFixed(2),
          namedialog: aliasName || name,
          itemStyle: {
            color: aliasName == '其他' || aliasName == 'other' ? '#E3E9F1' : null
          }
        };
      });
      this.numberCustomer = arr.length;
      this.chartTotal = formatNum(Number(total).toFixed(2));
      const chartData = !this.enlarge
        ? {
            labelLine: {
              show: false,
            },
            label: {
              show: false,
              position: 'center',
            },
          }
        : {};
      this.myChartPie.setOption(
        {
          tooltip: {
            trigger: 'item',
            confine: true,
            formatter: function (params) {
              var htmlStr = `
                <div class="echartsTooltipPieBox">
                  <div class="content">
                    <div class="label">${params.name.split("：")[0]}</div>
                    <div class="value">${params.name.split("：")[1]}</div>
                  </div>
                </div>
              `;
              return htmlStr;
            },
          },
          legend: {
            left: 'center',
            bottom: '0px',
            type: 'scroll',
          },
          series: [
            {
              type: 'pie',
              left: '-25%',
              bottom: "15%",
              radius: '90%',
              avoidLabelOverlap: false,
              data: arr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              ...chartData,
            },
          ],
        },
        true
      );
      this.myChartPie.on("legendselectchanged", (params) => {
        let proportionValue = 0;
        let chartTotal = 0;
        arr.map((v) => {
          const { name, proportion, value } = v;
          for (let key in params?.selected) {
            if (name == key && params?.selected[key]) {
              proportionValue += Number(proportion);
              chartTotal += Number(value);
            }
          }
        });
        let objectis = Object.values(params.selected).filter(
          (item) => item
        );
        this.numberCustomer = objectis.length;
        this.proportion = proportionValue.toFixed(2);
        this.chartTotal = formatNum(Number(chartTotal).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2));
      });
      this.myChartPie.off('click');
      this.myChartPie.on('click', (params) => {
        if(params.data.companyId) {
          this.$confirm(this.$tc(
            'Dashboard.confirmSeeMessageFn',
            params.data.namedialog
          ), this.$t('Tips'), {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }).then(() => {
            this.$router.push(`/dashboard/2?countryId=${params.data.companyId}`);
          }).catch(() => {});
        }
      });
      window.addEventListener("resize", () => {
        this.myChartPie.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>
.amountChart {
  width: 100%;
}
</style>
