export default {
  data() {
    return {};
  },
  mounted() {
    this.setColumn();
  },
  methods: {
    setColumn() {
      let arr = []
      let echartsColumn = [{
        name: this.$t("Dashboard.BasicStatisticsCountryMarket"),
        key: 'marketInfo',
        children: this.isDefaultNode ? [{
          name: this.$tc("Dashboard.Orders", this.$isOrderTitle()),
          key: 'order'
        }, {
          name: this.$t("Dashboard.Shipping"),
          key: 'transport'
        }, {
          name: this.$t("Dashboard.Producing"),
          key: 'production'
        }, {
          name: this.$t("Dashboard.Completed"),
          key: 'completed'
        },  {
          name: this.$t("Dashboard.orderTotal"),
          key: 'total'
        }] : [{
          name: this.$tc("Dashboard.Orders", this.$isOrderTitle()),
          key: 'order'
        }, {
          name: this.$t("order.pending"),
          key: 'transport'
        }, {
          name: this.$t("Dashboard.Completed"),
          key: 'completed'
        },  {
          name: this.$t("Dashboard.orderTotal"),
          key: 'total'
        }]
      }, {
        name: this.companyCurrentType == '1' ? (!this.artConfig.isAmountNumberMode ? this.$t("Dashboard.MonthlyPurchaseAmount") : this.$t("Dashboard.MonthlyPurchaseNumber")) : (!this.artConfig.isAmountNumberMode ? this.$t("Dashboard.ShipmentAmount") : this.$t("Dashboard.ShipmentQuantity")),
        key: 'shipmentQuantity',
        type: 'line'
      }, {
        name: (this.companyCurrentType == '1' ? this.$t("Dashboard.PurchaseComparison") : this.$t("Dashboard.CustomerComparison"))+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`),
        key: 'supplierConparison',
        type: 'bar'
      }, {
        name: (this.companyCurrentType == '1' ? this.$t('Dashboard.customer') : this.$t('Dashboard.Supplier'))+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`),
        key: 'supplierAmount',
        type: 'pie'
      }, {
        name: this.$t("Dashboard.SKU(Amount)Pie"),
        key: 'skuAmount_Pie',
        type: 'pie'
      }, {
        name: this.$t("Dashboard.SKU(Amount)Bar"),
        key: 'skuAmount_Bar',
        type: 'bar'
      }, {
        name: this.$t("Dashboard.SKU(unit price)"),
        key: 'skuUnitPrice',
        type: 'line'
      }, {
        name: this.$t("Dashboard.ExportProportion")+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`),
        key: 'exportProportion',
        type: 'pie'
      }, {
        name: this.$t("Dashboard.Exportdata")+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`),
        key: 'exportData',
        type: 'bar'
      }];
      let countryColumn = [{
        name: this.$t("Dashboard.statisticsCountryMarket"),
        key: 'countryInfo',
        children: [{
          name: this.$t("Dashboard.Capital"),
          key: 'capitalCn'
        }, {
          name: this.$t("Dashboard.Area"),
          key: 'areaKm'
        }, {
          name: this.$t("Dashboard.Population"),
          key: 'population'
        }, {
          name: this.$t("Dashboard.Currency"),
          key: 'currency'
        }, {
          name: this.$t("Dashboard.Politics"),
          key: 'politics'
        }, {
          name: this.$t("Dashboard.GdpTotal"),
          key: 'gdp'
        }, {
          name: this.$t("Dashboard.GDP per capita"),
          key: 'gdpAvg'
        }, {
          name: this.$t("Dashboard.Calling code"),
          key: 'callingcode'
        }, {
          name: this.$t("Dashboard.Official languages"),
          key: 'language'
        }, {
          name: this.$t("Dashboard.Province"),
          key: 'province'
        }, {
          name: this.$t("Dashboard.Organization"),
          key: 'organization'
        }, {
          name: this.$t("Dashboard.Religion"),
          key: 'religion'
        }]
      }]
      let customerColumn = [{
        name: this.$t("Dashboard.statisticsCustomerMarket"),
        key: 'customerInfo',
        children: [{
          name: this.$t('Dashboard.CompanyEmail'),
          key: 'email'
        }, {
          name: this.$t('Dashboard.CompanyContact'),
          key: 'contact'
        }, {
          name: this.$t('Dashboard.VATNO'),
          key: 'vat'
        }, {
          name: this.$t('Dashboard.Country'),
          key: 'country'
        }, {
          name: this.$t('Dashboard.Address'),
          key: 'address'
        }, {
          name: this.$t('Dashboard.Website'),
          key: 'website'
        }]
      }]
      let productColumn = [{
        name: this.$t("Dashboard.statisticsProductMarket"),
        key: 'productInfo',
        children: [{
          name: this.$t('Dashboard.skuItemNo'),
          key: 'itemNo',
        }, {
          name: this.$t('Dashboard.skuSize'),
          key: 'size',
        }, {
          name: this.$t('Dashboard.skuType'),
          key: 'type',
        }]
      }]
      if(this.modelValue === '0') {
        arr = countryColumn.concat(echartsColumn)
      }
      if(this.modelValue === '1') {
        arr = customerColumn.concat(echartsColumn)
      }
      if(this.modelValue === '2') {
        arr = productColumn.concat(echartsColumn)
      }
      this.transTitle = [{label: '', minWidth: '100px'}];
      this.transData = [];
      this.tableData = [];
      this.originCountryTitle = arr;
      this.originCountryTitleCopy = arr;
    },
    newOptions(option, type, key, subscript) {
      const dom = document.getElementById('echarts'+ type + key + (subscript + 1))
      dom.removeAttribute("_echarts_instance_");
      if(dom) {
        let myChart = this.$echartsInit(dom);
        this.$nextTick(() => {
          myChart.setOption(option);
          myChart.resize();
          this.data[key].maxYValue.push(myChart.getModel().getComponent('yAxis')?.axis.scale._extent[1])
          window.addEventListener('resize', () => {
            // if(document.getElementById('echarts'+ type + key + (subscript + 1))) {
              setTimeout(() => {
                myChart.resize();
              }, 2000)
            // }
          })
        })
      }
    },
    setOptions(key) {
      let yAxisObject = (this.data[key]?.maxYValue && this.data[key]?.maxYValue.length) ? (this.artConfig.isNeat ? {
        max: Math.max(...this.data[key]?.maxYValue)
      } : {
        max: () => null
      }) : {};
      if(key === 'shipmentQuantitySearch') {
        this.purchasingAmountData.forEach((item, subscript) => {
          this.newOptions({
            tooltip: {
              trigger: 'axis',
              confine: true,
              axisPointer: {
                type: 'shadow',
              },
              formatter: (params) => {
                var htmlStr = `<div>${params[0].name}</div><div style="height: auto;max-height: 240px;overflow-y: overlay;">`;
                for (var i = 0; i < params.length; i++) {
                  htmlStr +=
                    '<p>' +
                    `${params[i].marker}` +
                    params[i].value +
                    `${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}` +
                    '</p>';
                }
                htmlStr += '</div>';
                return htmlStr;
              },
            },
            grid: {
              left: "0",
              top: "2%",
              right: "0",
              bottom: "0",
              containLabel: true,
            },
            xAxis: {
              type: 'category',
              type: 'category',
              data: item.xAxis.length ? item.xAxis : ['No information'],
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
              },
            },
            yAxis: {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
                show: false,
              },
              ...yAxisObject
            },
            series: [
              {
                data: item.data.length ? item.data : ['0'],
                type: 'bar',
                barWidth: '40%'
              },
            ]
          }, 'line', key, subscript)
        })
      }
      if(key === 'supplierConparisonSearch') {
        this.supplierComparisonData.forEach((item, subscript) => {
          let options = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
              },
              enterable: true, // 鼠标是否可进入提示框浮层中
              hideDelay: 60, // 浮层隐藏的延迟
              confine: true,
              formatter: (params) => {
                var htmlStr = '';
                for (var i = 0; i < params.length; i++) {
                  if(params[i].value != 0) {
                    htmlStr += `
                      <div class="content">
                        ${params[i].marker}
                        <div class="value">${params[i].seriesName}：${params[i].value}${!this.artConfig.isAmountNumberMode ? '(USD)' : ''})</div>
                      </div>
                    `;
                  }
                }
                return `
                  <div>
                    ${ !this.data[key].dateType ? "" : params[0].axisValue } 
                  </div>
                  <div class="echartsTooltipBox">
                    ${htmlStr}
                  </div>
                  ${this.data[key].dateType ? `<div class="echartsChatTotal">
                    total：${params.map(item => Number(item.value)).reduce(function (prev, cur) {
                        return prev + cur;
                    }, 0).toFixed(2)}
                  </div>` : ''}
                `;
              }
            },
            grid: {
              left: "0",
              top: "2%",
              right: "0",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: item.xAxis,
                axisTick: {
                  alignWithLabel: true,
                  show: false,
                },
                axisLine: {
                  lineStyle: { color: '#B0B8BF' },
                },
              },
              {
                gridIndex: 1,
                show: false,
              },
            ],
            yAxis: {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
                show: false,
              },
              ...yAxisObject
            },
            dataZoom: [
              {
                show: true,
                type: 'slider',
                left: 'center',
                top: '90%',
                start: 0,
                end: 100,
              },
            ],
            series: 
              this.data[key].dateType ? 
              item.data.map((v) => ({
                ...v,
                stack: 'vistors',
                type: 'bar',
                emphasis: {
                  focus: 'series'
                },
                itemStyle: {
                  color: v.name == 'other' && "rgba(154, 163, 172, .5)"
                }
              })) : 
              [{ data: item.data, type: 'bar', barWidth: '60%' }],
          }
          this.newOptions(options, 'bar', key, subscript)
        })
      }
      if(key === 'supplierAmountSearch') {
        this.supplierAmountData.forEach((item, subscript) => {
          let options = {
            tooltip: {
              trigger: 'item',
              confine: true,
              formatter: function (params) {
                var htmlStr = `
                  <div>${params.marker}${params.name}</div>
                `;
                return htmlStr;
              },
            },
            legend: {
              left: 'center',
              bottom: '20',
              type: 'scroll',
            },
            series: [
              {
                type: 'pie',
                bottom: '20',
                radius: '65%',
                label: {
                  show: false
                },
                data: item.data.map((row, idx) => {
                  return ({
                    value: row.value,
                    name: row.name,
                    itemStyle: {
                      color: row.sheName == 'other' ? "rgba(154, 163, 172, .5)" : null
                    }
                  })
                })
              },
            ],
          }
          this.newOptions(options, 'pie', key, subscript)
        })
      }
      if(key === 'skuAmount_PieSearch') {
        this.skuAmountPricePieData.forEach((item, subscript) => {
          this.newOptions({
            grid: {
              left: "0",
              top: "2%",
              right: "0",
              bottom: "15%",
              containLabel: true,
            },
            tooltip: {
              trigger: 'item',
              confine: true,
              formatter: function (params) {
                var htmlStr = `
                  <div>${params.marker}${params.name}</div>
                `;
                return htmlStr;
              },
            },
            legend: {
              left: 'center',
              bottom: '0',
              type: 'scroll'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                left: '-40%',
                avoidLabelOverlap: false,
                data: item.data,
                label: {
                  show: false,
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                }
              },
            ],
          }, 'pie', key, subscript)
        })
      }
      if(key === 'skuAmount_BarSearch') {
        this.skuAmountPriceBarData.forEach((item, subscript) => {
          this.newOptions({
            tooltip: {
              trigger: 'axis',
              confine: true,
              axisPointer: {
                type: 'shadow',
              },
            },
            grid: {
              left: "0",
              top: "2%",
              right: "0",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
                show: false,
              },
            },
            yAxis: [
              {
                type: 'category',
                data: item.yAxis,
                axisLabel: {
                  color: "#122545",
                  interval: 0,
                  formatter: function(value) {
                    if (value.length > 12) {
                      return value.substring(0, 12) + "...";
                    } else {
                      return value;
                    }
                  }
                },
                axisTick: {
                  alignWithLabel: true,
                  show: false,
                },
                axisLine: {
                  lineStyle: { color: '#B0B8BF' },
                },
              },
              { gridIndex: 1, show: false },
            ],
            dataZoom: [
              {
                show: true,
                type: 'slider',
                left: 'center',
                top: '90%',
                start: 0,
                end: 100,
              },
            ],
            series: [
              {
                data: item.data,
                type: 'bar',
                barWidth: '40%',
                label: {
                  show: true,
                  position: "right",
                  color: "#B0B8BF",
                  fontSize: "12px",
                  formatter: (params) => {
                    return item.amountProportion[params.dataIndex];
                  },
                }
              },
            ],
          }, 'bar', key, subscript)
        })
      }
      if(key === 'skuUnitPriceSearch') {
        this.skuUnitPriceData.forEach((item, subscript) => {
          this.newOptions({
            grid: {
              left: "0",
              top: "10%",
              right: "0",
              bottom: "12%",
              containLabel: true,
            },
            tooltip: {
              trigger: 'axis',
              enterable: true, // 鼠标是否可进入提示框浮层中
              hideDelay: 60, // 浮层隐藏的延迟
              confine: true,
              formatter: (params) => {
                var htmlStr = "";
                for (var i = 0; i < params.length; i++) {
                  htmlStr += `
                    <p>
                      ${params[i].marker}${params[i].seriesName}：${params[i].value[1]}(USD)
                    </p>
                  `;
                }
                htmlStr += '</div>';
                return htmlStr;
              }
            },
            xAxis: {
              //   type: 'time',
              data: [...new Set(item.xAixs)],
              type: 'category',
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
              },
            },
            yAxis: {
              type: 'value',
              scale: true,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
                show: false,
              },
              ...yAxisObject
            },
            legend: {
              data: item.legendData,
              type: 'scroll',
            },
            series: item.data.map((res) => {
              return {
                ...res,
                type: 'scatter',
                symbolSize: 5,
                animationEasing: 'cubicInOut',
              };
            }),
            dataZoom: [
              {
                show: true,
                type: 'slider',
                left: 'center',
                top: '90%',
                start: 0,
                end: 100,
              },
            ],
          }, 'line', key, subscript)
        })
      }
      if(key === 'exportProportionSearch') {
        this.exportProportionData.forEach((item, subscript) => {
          this.newOptions({
            tooltip: {
              trigger: 'item',
              confine: true,
              formatter: function (params) {
                var htmlStr = `
                  <div>${params.marker}${params.name}</div>
                `;
                return htmlStr;
              },
            },
            legend: {
              left: 'center',
              bottom: '20',
              type: 'scroll',
            },
            series: [
              {
                type: 'pie',
                bottom: '20',
                radius: '65%',
                avoidLabelOverlap: false,
                data: item.data,
                label: {
                  show: false,
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                }
              },
            ],
          }, 'pie', key, subscript)
        })
      }
      if(key === 'exportDataSearch') {
        this.exportBarData.forEach((item, subscript) => {
          console.log(item.xAxis)
          this.newOptions({
            tooltip: {
              trigger: 'axis',
              confine: true,
              axisPointer: {
                type: 'shadow',
              },
              formatter: (params) => {
                var htmlStr = "";
                for (var i = 0; i < params.length; i++) {
                  if(params[i].value != 0) {
                    htmlStr += `
                      <div class="content">
                        ${params[i].marker}
                        <div class="value">${params[i].seriesName}: ${params[i].value}${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}</div>
                      </div>
                    `;
                  }
                }
                return `
                  <div>${params[0].axisValue}</div>
                  <div class="echartsTooltipBox">
                    ${htmlStr}
                  </div>
                  ${this.data[key].dateType ? `<div class="echartsChatTotal">
                    total：${params.map(item => Number(item.value)).reduce(function (prev, cur) {
                        return prev + cur;
                    }, 0).toFixed(2)}
                  </div>` : ''}
                `;
              },
            },
            grid: {
              left: "0",
              top: "2%",
              right: "0",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: item.xAxis,
                axisTick: {
                  alignWithLabel: true,
                  show: false,
                },
                axisLine: {
                  lineStyle: { color: '#B0B8BF' },
                },
              },
              { gridIndex: 1, show: false },
            ],
            yAxis: {
              type: 'value',
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
                show: false,
              },
              ...yAxisObject
            },
            dataZoom: [
              {
                show: true,
                type: 'slider',
                left: 'center',
                top: '90%',
                start: 0,
                end: 100,
              },
            ],
            series: this.data[key].dateType
              ? item.data.map((vo) => ({
                  ...vo,
                  stack: 'vistors',
                  type: 'bar',
                  emphasis: {
                    focus: 'series'
                  },
                }))
              : [{ data: item.data, type: 'bar', barWidth: '60%' }],
          }, 'bar', key, subscript)
        })
      }
      if(key === 'orderStatusSummary_PieSearch') {
        this.orderStatusSummaryPieData.forEach((item, subscript) => {
          this.newOptions({
            grid: {
              left: "0",
              top: "2%",
              right: "0",
              bottom: "15%",
              containLabel: true,
            },
            tooltip: {
              trigger: 'item',
              confine: true,
              formatter: function (params) {
                var htmlStr = `<div>${params.seriesName}</div>
              <div>${params.marker}${params.name}</div><div style="height: auto;max-height: 240px;overflow-y: overlay;"></div>`;
                return htmlStr;
              },
            },
            legend: {
              left: 'center',
              bottom: '0',
              type: 'scroll'
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                avoidLabelOverlap: false,
                data: item.data,
                label: {
                  show: false,
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                }
              },
            ],
          }, 'pie', key, subscript)
        })
      }
      if(key === 'orderStatusSummary_BarSearch') {
        this.orderStatusSummaryBarData.forEach((item, subscript) => {
          console.log(item.xAxis)
          this.newOptions({
            tooltip: {
              trigger: 'axis',
              confine: true,
              axisPointer: {
                type: 'shadow',
              },
            },
            grid: {
              left: "0",
              top: "2%",
              right: "0",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: item.xAxis,
                axisTick: {
                  show: false,
                },
                axisLine: {
                  lineStyle: { color: '#B0B8BF' },
                  show: false,
                },
              },
              { gridIndex: 1, show: false },
            ],
            yAxis: {
              type: 'value',
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
              },
            },
            dataZoom: [
              {
                show: true,
                type: 'slider',
                left: 'center',
                top: '90%',
                start: 0,
                end: 100,
              },
            ],
            series: [
              {
                data: item.data,
                type: 'bar',
                barWidth: '40%'
              },
            ],
          }, 'bar', key, subscript)
        })
      }
    },
  },
};
