<template>
  <div>
    <div v-if="isVipModeType == 0" class="maxbox" ref="exportPdf">
      <div class="formSearch">
        <div class="tabs">
          <div class="title">{{ $t('home.Dashboard') }}</div>
          <el-divider direction="vertical"></el-divider>
          <div class="dashboardTabs">
            <div 
              class="item" 
              v-for="(item, index) in topTabs" 
              :key="index"
              @click="goDashboard(item.value)"
              :class="echarts_type == item.value ? 'active' : ''">
              {{ $t(`Dashboard.${item.name}`) }}
            </div>
          </div>
        </div>
        <div class="flexAlignCenter">
          <el-select
            style="width: 220px;"
            v-if="echarts_type === '1'"
            v-model="countryValue"
            filterable
            size="small"
            :placeholder="$t('home.Pleaseselectcountry')"
          >
            <el-option
              v-for="(item, index) in minuteCountry"
              :key="index"
              :label="item.countryName"
              :value="item.iso3"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 220px;"
            v-if="echarts_type === '2'"
            v-model="customerValue"
            filterable
            size="small"
            :placeholder="
              companyCurrentType == '1' || companyCurrentType == '2'
                ? $t('home.PleaseselectCustomer')
                : $t('home.PleaseselectSupplier')
            "
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.aliasName"
              :value="item.companyId"
            >
              <div class="option_list">
                <div>{{ item.aliasName }}</div>
                <div>{{ $t(`home.${$companyTypes[item.companyType]}`) }}</div>
              </div>
            </el-option>
          </el-select>
          <el-select
            style="width: 220px;"
            v-if="echarts_type === '3'"
            v-model="productValue"
            v-selectLazyLoad="selectPageLoad"
            filterable
            size="small"
            :placeholder="$t('home.PleaseInputProduct')"
          >
            <el-option
              v-for="(item, index) in skuList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>

          <template v-for="(value, key, index) in globalTimeArray">
            <el-date-picker
              :key="key"
              v-if="echarts_type == index"
              style="width: 220px;"
              type="daterange"
              size="small"
              v-model="globalTimeArray[key]"
              :start-placeholder="$t('Dashboard.Startfrom')"
              :end-placeholder="$t('Dashboard.Endoftime')"
              :picker-options="pickerData"
              @change="updateTimeData"
            />
          </template>
          <div
            v-if="echarts_type == '4'"
            class="beatBtn"
            :style="{
              background: !isNeat ? '#ffffff' : '#2e3f4f',
            }"
            @click="isNeatEvent"
          >
            <img
              :src="
                !isNeat
                  ? 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/WechatIMG31.png'
                  : 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/WechatIMG30.png'
              "
            />
          </div>
          <toolTipIcon 
            :icon="!isAmountNumberMode ? 'iconfont icon-dingdanshuliang' : 'iconfont icon-dingdanjine'"
            :tooltipText="!isAmountNumberMode ? $tc('home.OrderNumber', $isOrderTitle()) : $tc('home.OrderAmount', $isOrderTitle())"
            @click="isAmountNumberMode = !isAmountNumberMode ? 1 : 0"
          />
          <toolTipIcon 
            v-if="echarts_type !== '4'" 
            icon="iconfont icon-download" 
            :tooltipText="$t('ExportFile')" 
            isPopover
            v-loading="exportLoading">
            <template slot="popperMenuItem">
              <div class="popperMenuItem" @click="savePdf('png')">
                <i class="iconfont icon-daochuwenjian" />
                <span>{{ $t("Files.ExportPng") }}</span>
              </div>
              <div class="popperMenuItem" @click="savePdf('reportForms')">
                <i class="iconfont icon-daochuexcel" />
                <span>{{ $t("Files.ExportReportForms") }}</span>
              </div>
            </template>
          </toolTipIcon>
        </div>
      </div>
      <div class="top_box" v-if="echarts_type === '0'">
        <dashboardAll ref="dashboardAll" />
      </div>
      <div class="top_box" v-if="echarts_type === '1'">
        <dashboardCountry ref="dashboardCountry" />
      </div>
      <div class="top_box" v-if="echarts_type === '2'">
        <dashboardCustomer ref="dashboardCustomer" />
      </div>
      <div class="top_box" v-if="echarts_type === '3'">
        <dashboardProduct ref="dashboardProduct" />
      </div>
      <div v-if="echarts_type === '4'">
        <dashboardContrast ref="dashboardContrast" />
      </div>
    </div>
    <div v-else class="vipBox" ref="exportPdf">
      <div class="formSearch">
        <div class="dashboardTabs">
          <div 
            class="item" 
            v-for="(item, index) in topTabs.filter(item => item.value != 3)" 
            :key="index"
            @click="goDashboard(item.value)"
            :class="echarts_type == item.value ? 'active' : ''">
            {{ $t(`Dashboard.${item.name}`) }}
          </div>
        </div>
        <div class="flexAlignCenter">
          <el-select
            style="width: 150px;"
            v-if="echarts_type === '1'"
            v-model="countryValue"
            filterable
            size="small"
            :placeholder="$t('home.Pleaseselectcountry')"
          >
            <el-option
              v-for="(item, index) in minuteCountry"
              :key="index"
              :label="item.countryName"
              :value="item.iso3"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px;"
            v-if="echarts_type === '2'"
            v-model="customerValue"
            filterable
            size="small"
            :placeholder="
              companyCurrentType == '1' || companyCurrentType == '2'
                ? $t('home.PleaseselectCustomer')
                : $t('home.PleaseselectSupplier')
            "
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="index"
              :label="item.aliasName"
              :value="item.companyId"
            >
              <div class="option_list">
                <div>{{ item.aliasName }}</div>
                <div>{{ $t(`home.${$companyTypes[item.companyType]}`) }}</div>
              </div>
            </el-option>
          </el-select>
          <el-select
            style="width: 150px;"
            v-if="echarts_type === '3'"
            v-model="productValue"
            v-selectLazyLoad="selectPageLoad"
            filterable
            size="small"
            :placeholder="$t('home.PleaseInputProduct')"
          >
            <el-option
              v-for="(item, index) in skuList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <template v-for="(value, key, index) in globalTimeArray">
            <el-date-picker
              :key="key"
              v-if="echarts_type == index"
              style="width: 220px;"
              type="daterange"
              size="small"
              v-model="globalTimeArray[key]"
              :start-placeholder="$t('Dashboard.Startfrom')"
              :end-placeholder="$t('Dashboard.Endoftime')"
              :picker-options="pickerData"
              @change="updateTimeData"
            />
          </template>
          <div
            v-if="echarts_type == '4'"
            class="beatBtn"
            :style="{
              background: !isNeat ? '#ffffff' : '#2e3f4f',
            }"
            @click="isNeatEvent"
          >
            <img
              :src="
                !isNeat
                  ? 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/WechatIMG31.png'
                  : 'https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/WechatIMG30.png'
              "
            />
          </div>
          <toolTipIcon 
            :icon="!isAmountNumberMode ? 'iconfont icon-dingdanshuliang' : 'iconfont icon-dingdanjine'"
            :tooltipText="!isAmountNumberMode ? $tc('home.OrderNumber', $isOrderTitle()) : $tc('home.OrderAmount', $isOrderTitle())"
            @click="isAmountNumberMode = !isAmountNumberMode ? 1 : 0"
          />
          <toolTipIcon 
            v-if="echarts_type !== '4'" 
            icon="iconfont icon-download" 
            :tooltipText="$t('ExportFile')" 
            isPopover
            v-loading="exportLoading">
            <template slot="popperMenuItem">
              <div class="popperMenuItem" @click="savePdf('png')">
                <i class="iconfont icon-daochuwenjian" />
                <span>{{ $t("Files.ExportPng") }}</span>
              </div>
              <div class="popperMenuItem" @click="savePdf('reportForms')">
                <i class="iconfont icon-daochuexcel" />
                <span>{{ $t("Files.ExportReportForms") }}</span>
              </div>
            </template>
          </toolTipIcon>
        </div>
      </div>
      <div class="echartsCard" v-if="echarts_type === '0'">
        <dashboardAllVip ref="dashboardAll" />
      </div>
      <div class="echartsCard" v-if="echarts_type === '1'">
        <dashboardCountryVip ref="dashboardCountry" />
      </div>
      <div class="echartsCard" v-if="echarts_type === '2'">
        <dashboardCustomerVip ref="dashboardCustomer" />
      </div>
      <div class="echartsCard" v-if="echarts_type === '3'">
        <dashboardProductVip ref="dashboardProduct" />
      </div>
      <div v-if="echarts_type === '4'">
        <dashboardContrastVip ref="dashboardContrast" />
      </div>
      <div class="echartsCard" v-if="echarts_type === '5'">
        <dashboardExtraDataVip ref="dashboardExtraData" />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import "jspdf-autotable";

import mixinsIndex from "./mixins/index";

import { getSkuParam } from "../../api/chart";
import {
  getUserCustomList
} from '@/api/customer';
import { pickerOptions } from "../../utils/utils";
import "../../utils/msyh-normal";

import dashboardAll from "./dashboardTabItem/dashboardAll.vue";
import dashboardCountry from "./dashboardTabItem/dashboardCountry.vue";
import dashboardCustomer from "./dashboardTabItem/dashboardCustomer.vue";
import dashboardProduct from "./dashboardTabItem/dashboardProduct.vue";
import dashboardContrast from "./dashboardTabItem/dashboardContrast";
// vip
import dashboardAllVip from "./dashboardTabItemVip/dashboardAll.vue";
import dashboardCountryVip from "./dashboardTabItemVip/dashboardCountry.vue";
import dashboardCustomerVip from "./dashboardTabItemVip/dashboardCustomer.vue";
import dashboardProductVip from "./dashboardTabItemVip/dashboardProduct.vue";
import dashboardContrastVip from "./dashboardTabItemVip/dashboardContrast.vue";
import dashboardExtraDataVip from "./dashboardTabItemVip/dashboardExtraData.vue";

export default {
  name: "Dashboard",
  components: {
    dashboardAll,
    dashboardCountry,
    dashboardCustomer,
    dashboardProduct,
    dashboardContrast,
    dashboardAllVip,
    dashboardCountryVip,
    dashboardCustomerVip,
    dashboardProductVip,
    dashboardContrastVip,
    dashboardExtraDataVip
  },
  mixins: [mixinsIndex],
  data() {
    return {
      isNeat: false,
      isAmountNumberMode: 0,
      isVipModeType: "",
      echarts_type: "",
      countryValue: "",
      customerValue: "",
      productValue: "",
      countryOptions: [],
      companyList: [],
      skuList: [],
      currentSelectPage: 1,
      globalTimeArray: {
        globalTimeAll: [
          dayjs(`${dayjs().format("YYYY")}-01-01`).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
        globalTimeCountry: [
          dayjs(`${dayjs().format("YYYY")}-01-01`).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
        globalTimeCustomer: [
          dayjs(`${dayjs().format("YYYY")}-01-01`).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
        globalTimeProduct: [
          dayjs(`${dayjs().format("YYYY")}-01-01`).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
        globalTimeContrast: [
          dayjs(`${dayjs().format("YYYY")}-01-01`).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
        globalTimeExtraData: [
          dayjs(`${dayjs().format("YYYY")}-01-01`).format("YYYY-MM-DD"),
          dayjs().format("YYYY-MM-DD"),
        ],
      },
      exportLoading: false,
      modelContrastState: "",
      contrastSelectItem: [],
    };
  },
  provide() {
    return {
      getConfig: () => ({
        countryId: this.countryValue,
        companyId: this.customerValue,
        productId: this.productValue,
        echarts_type: this.echarts_type,
        isNeat: this.isNeat,
        modelContrastState: this.modelContrastState,
        contrastSelectItem: this.contrastSelectItem,
        companyList: this.companyList,
        isVipMode: this.isVipMode,
        isAmountNumberMode: this.isAmountNumberMode
      }),
      getTimeConfig: () => ({
        globalTimeAll: this.globalTimeArray.globalTimeAll,
        globalTimeCountry: this.globalTimeArray.globalTimeCountry,
        globalTimeCustomer: this.globalTimeArray.globalTimeCustomer,
        globalTimeProduct: this.globalTimeArray.globalTimeProduct,
        globalTimeContrast: this.globalTimeArray.globalTimeContrast,
        globalTimeExtraData: this.globalTimeArray.globalTimeExtraData
      }),
    };
  },
  computed: {
    ...mapGetters(["minuteCountry", "companyCurrentType", "chartVipColors", "isVipMode"]),
    pickerData() {
      return pickerOptions();
    },
    i18n() {
      return this.$i18n.locale;
    },
    topTabs() {
      if(this.isVipMode == 0) {
        return [{
          name: 'whole',
          value: '0'
        }, {
          name: 'Country',
          value: '1'
        }, {
          name: this.companyCurrentType == '1' || this.companyCurrentType == '2' ? 'customer' : 'supplier',
          value: '2'
        }, {
          name: 'Product',
          value: '3'
        }, {
          name: 'Conast',
          value: '4'
        }]
      } else {
        return [{
          name: 'whole',
          value: '0'
        }, {
          name: 'Country',
          value: '1'
        }, {
          name: this.companyCurrentType == '1' || this.companyCurrentType == '2' ? 'customer' : 'supplier',
          value: '2'
        }, {
          name: 'Conast',
          value: '4'
        }, {
          name: 'ExtraData',
          value: '5'
        }]
      }
    }
  },
  methods: {
    goDashboard(id) {
      this.$router.push(`/dashboard/${id}`);
    },
    handleCommand(command) {
      this.savePdf(command);
    },
    isNeatEvent() {
      this.isNeat = !this.isNeat;
    },
    selectPageLoad() {
      this.currentSelectPage++;
      this.getSkuParam();
    },
    // 获取sku数据
    getSkuParam() {
      const { globalTimeProduct } = this.globalTimeArray;
      let startTime =
        globalTimeProduct && globalTimeProduct?.length
          ? dayjs(globalTimeProduct[0]).format("YYYY-MM-DD HH:mm:ss")
          : "";
      let endTime =
        globalTimeProduct && globalTimeProduct?.length
          ? dayjs(globalTimeProduct[1]).format("YYYY-MM-DD HH:mm:ss")
          : "";
      getSkuParam({
        startTime, 
        endTime, 
        currentPage: this.currentSelectPage,
        pageSize: 20,
      }).then((data) => {
        this.skuList = this.skuList.concat(data.list.map((item) => ({
          label: `${item.itemNo} | ${item.type} | ${item.size}`,
          value: JSON.stringify(item),
        })));
      });
    },
    updateTimeData() {
      this.getSkuParam();
    },
    getRelation() {
      getUserCustomList({
        currentPage: 1,
        pageSize: 10000,
        companyType: this.companyCurrentType == '0' ? '1' : this.companyCurrentType == '1' ? '0' : this.companyCurrentType == '2' ? '1' : '0'
      }).then((data) => {
        console.log(data)
        this.companyList = data.list;
      }).finally(() => this.isLoading = false);
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.$nextTick(() => {
          this.echarts_type = this.$route.params.id;
          this.isVipModeType = this.isVipMode;
          if (this.$route.query.companyId) {
            this.customerValue = this.$route.query.companyId;
          }
          if (this.$route.query.countryId) {
            this.countryValue = this.$route.query.countryId;
          }
          if (this.$route.query.productId) {
            this.productValue = JSON.stringify({
              itemNo: this.$route.query.productId.split("|")[0],
              size: this.$route.query.productId.split("|")[1],
              type: this.$route.query.productId.split("|")[2],
            });
          }
          this.getRelation();
          this.getSkuParam();
        });
      },
      deep: true,
      immediate: true
    },
    minuteCountry: {
      handler(val) {
        this.countryValue = val[0]?.iso3;
      },
      immediate: true,
    },
    companyList(val) {
      if (this.customerValue) return;
      this.customerValue = val[0]?.companyId;
    },
    isVipMode(val) {
      if(this.$route.name != 'Dashboard') return;
      console.log(val);
      if(this.$route.path == '/dashboard/5' && val == 0) {
        this.$router.push("/dashboard/0")
      }
      this.isVipModeType = val;
      this.isAmountNumberMode = 0;
    },
    skuList(val) {
      if(!this.productValue) {
        this.productValue = val[0]?.value;
      } else {
        if(val.filter(item => item.value === this.productValue).length) {
          this.productValue = val.filter(item => item.value === this.productValue)[0]?.value;
        } else {
          this.productValue = ""
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import "./style/dashBoard.less";
</style>

<style lang="less">
@import "./style/common.less";
</style>
