<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">
        <div 
          class="tabItem" 
          :class="chatChangeType == 0 ? 'active' : ''" 
          @click="chatChangeType = 0"
        >{{ titleAmount.split("Sales")[1] || titleAmount.split("Sales")[0] }}</div>
        <div 
          class="tabItem" 
          :class="chatChangeType == 1 ? 'active' : ''" 
          @click="chatChangeType = 1"
        >{{ titleComparison.split("Sales")[1] || titleComparison.split("Sales")[0] }}</div>
        <div 
          class="tabItem" 
          :class="chatChangeType == 2 ? 'active' : ''" 
          @click="chatChangeType = 2"
        >{{ $t("Dashboard.Exportdata")+(`(${!artConfig.isAmountNumberMode ? $t('order.Amount') : $t('order.Quantity')})`) }}</div>
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <!-- <toolTipIcon  -->
          <!-- :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`"  -->
          <!-- :tooltipText="enlarge ? $t('retract') : $t('expand')" -->
          <!-- @click="onEnlarge()" -->
        <!-- /> -->
        <div class="left_screen" style="margin-right: 10px;">
          <div
            v-for="(item, index) in [
              { name: $t('Dashboard.Monthly'), title: '0' },
              {
                name: $t('Dashboard.Quarterly'),
                title: '1',
              },
              { name: $t('Dashboard.Yearly'), title: '2' },
            ]"
            :key="index"
            @click="onActive(item.title)"
            :class="`${data.dateType == item.title ? 'active' : ''}`"
          >
            {{ item.name }}
          </div>
        </div>
        <toolTipIcon
          icon="iconfont icon-shuaxin"
          :tooltipText="$t('Refresh')"
          @click="onGetAmount()"
        />
      </div>
    </div>
    <div class="chart-wrapper" style="margin-top: 22px">
      <div
        :id="echartsViewId"
        class="conparisonChartBox"
        :style="`height: ${this.enlarge == false ? '300px' : '70vh'}`"
      />
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import _ from "lodash";
import { mapGetters } from "vuex";
import { supplierComparison, getAmount, exportData } from "@/api/chart";
import { pickerOptions } from "@/utils/utils";

export default {
  name: 'supplierConparison',
  data() {
    return {
      myChart: null,
      contrastActive: false,
      echartsViewId: 'conparisonChartBox_',
      captureId: 'captureFour_',
      enlarge: false,
      echartsData: [],
      chatChangeType: "0",
      data: {
        country: null,
        customer: '',
        itemNo: '',
        type: '',
        size: '',
        time: [dayjs().startOf('year'), dayjs()],
        dateType: '0',
      },
      chartLoading: false,
      titleComparison: '',
      titleAmount: ''
    };
  },
  inject: ['getConfig'],
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["companyCurrentType", "country"]),
    i8n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(newVal, olVal) {
        if(this.$route.name != 'Dashboard') return;
        this.onGetAmount();
      },
      deep: true,
    },
    i8n() {
      this.onGetTitle();
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    'artConfig.isAmountNumberMode'() {
      this.onGetTitle();
      this.onGetAmount();
    },
    enlarge() {
      this.setEchartsOption(this.echartsData, this.data.dateType);
    },
    chatChangeType() {
      this.onGetAmount();
    }
  },
  mounted() {
    this.onGetTitle();
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    onActive(title) {
      this.data.dateType = title;
    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document.getElementById(this.captureId).classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document.getElementById(this.captureId).classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    onGetTitle() {
      if (this.companyCurrentType == '1') {
        this.titleComparison = this.$t('Dashboard.PurchaseComparison')+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`);
        this.titleAmount = !this.artConfig.isAmountNumberMode ? this.$t('Dashboard.MonthlyPurchaseAmount') : this.$t('Dashboard.MonthlyPurchaseNumber');
      } else {
        this.titleComparison = this.$t('Dashboard.CustomerComparison')+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`);
        this.titleAmount = !this.artConfig.isAmountNumberMode ? this.$t('Dashboard.ShipmentAmount') : this.$t('Dashboard.ShipmentQuantity');
      }
    },
    onGetAmount() {
      this.chartLoading = true;
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      const { 
        dateType, 
        time,  
        country, 
        customer, 
        itemNo,
        type,
        size  
      } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      let api = this.chatChangeType == 0 ? getAmount : this.chatChangeType == 1 ? supplierComparison : exportData
      api({ 
        dateType,
        startTime, 
        endTime, 
        country, 
        customer,
        itemNo,
        type,
        size,
        queryType: this.artConfig.isAmountNumberMode
      }).then(({data}) => {
        this.echartsData = data;
        this.setEchartsOption(data);
      }).finally(() => (this.chartLoading = false));
    },
    setEchartsOption(data) {
      let timeList = [];
      let seriesList = [];
      let childrenArray = [];
      let list = [];
      if(this.chatChangeType == 0) {
        data?.forEach((v, i) => {
          timeList.push(v.date);
          if (this.companyCurrentType === '2') {
            seriesList.push(Number(v.orderNumber).toFixed(2));
          } else {
            seriesList.push(Number(v.amount).toFixed(2));
          }
        });
      } else {
        data.forEach((v) => {
          timeList.push(v.date);
          let listData = this.chatChangeType == 1 ? v.customerList : v.countryList;
          if (listData) {
            let arr = listData.map((item) => ({
              date: item.date,
              value: Number(item.amount).toFixed(2),
              name: (item.aliasName || item.name) || this.$isFindData(
                this.country,
                item.country,
                "value",
                "key"
              )
            }));
            list.push(...arr);
          }
        });
        let aggregate = _.groupBy(list, "name");
        for(let i in aggregate) {
          childrenArray.push(i)
        }
        childrenArray.forEach(item => {
          seriesList.push({
            name: item,
            data: []
          })
        })
        seriesList.forEach((item) => {
          timeList.forEach((time, idx) => {
            let data = list.filter(row => item.name == row.name && time == row.date)[0];
            item.data[idx] = data?.value || 0;
          });
        });
      }
      const seriesMark = this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {}
      this.myChart.setOption(
        {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            enterable: true, // 鼠标是否可进入提示框浮层中
            hideDelay: 60, // 浮层隐藏的延迟
            confine: true,
            formatter: (params) => {
              var htmlStr = '';
              for (var i = 0; i < params.length; i++) {
                if(params[i].value != 0) {
                  htmlStr += `
                    <div class="content">
                      ${params[i].marker}
                      <div class="value">${this.chatChangeType != 0 ? params[i].seriesName+': ' : ''}${params[i].value}${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}</div>
                    </div>
                  `;
                }
              }
              return `
                <div>
                  ${ params[0].axisValue } 
                </div>
                <div class="echartsTooltipBox">
                  ${htmlStr}
                </div>
                <div class="echartsChatTotal">
                  total：${params.map(item => Number(item.value)).reduce(function (prev, cur) {
                      return prev + cur;
                  }, 0).toFixed(2)}
                </div>
              `;
            }
          },
          grid: [
            {
              top: '5%',
              left: '3%',
              right: '3%',
              bottom: '14%',
              containLabel: true,
            },
          ],
          xAxis: [
            {
              type: 'category',
              data: timeList,
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
              },
            },
            {
              gridIndex: 1,
              show: false,
            },
          ],
          yAxis: {
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
              show: false,
            },
          },
          dataZoom: [
            {
              show: true,
              type: 'slider',
              left: 'center',
              bottom: '0%',
              start: 0,
              end: 100,
            },
          ],
          series: this.chatChangeType != 0 ? seriesList.map((vo) => ({
            ...vo,
            type: "bar",
            stack: "vistors",
            emphasis: {
              focus: 'series'
            },
            ...seriesMark,
          })) : [
            {
              data: seriesList,
              type: "bar",
              barWidth: "60%",
              ...seriesMark,
            },
          ],
        },
        true
      );
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>
.conparisonChartBox {
  width: 100%;
  height: 350px;
}
.tabItem {
  color: #637381;
  font-size: 16px;
  cursor: pointer;
  transition: all .3s;
  white-space: nowrap;
  &.active {
    font-size: 20px;
    color: #122545;
  }
}
</style>
