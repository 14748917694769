<template>
  <div class="contrast_card">
    <div class="selectTypeModel" v-loading="selectLoading">
      <el-select
        v-model="modelValue" 
        clearable 
        :placeholder="$t('home.Please select')" 
        :disabled="tableLoading"
        @change="modelChange">
        <el-option
          v-for="item in modelOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select 
        v-model="checkboxValue" 
        multiple 
        collapse-tags
        filterable
        :maxCollapseTags="8"
        v-selectLazyLoad="selectPageLoad"
        :placeholder="$t('order.Type names to assign')"
        class="userSelect"
        @change="checkBoxChange">
        <el-option
          v-for="item in modelSelectOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          class="userSelectBox"
        ></el-option>
      </el-select>
    </div>
    <el-card>
      <div class="contrastTable">
        <el-table
          :data="transData" 
          class="contrastTable"
          height="calc(100vh - 210px)" 
          style="width: 100%"
          :span-method="arraySpanMethod"
          id="contrast_cardId"
          row-key="id"
          v-loading="tableLoading"
          ref="contrastTable"
          :row-class-name="rowClassName"
          :cell-class-name="cellClassName"
          @header-click="headerClick">
          <template #empty>
            <el-empty
              :description="$t('overallSituation.noData')"
              :image="$emptyImage"
              :image-size="90"
            ></el-empty>
          </template>
          <el-table-column
            v-for="(item,index) in transTitle" 
            :key="Math.random()" 
            :label="item.label" 
            :fixed="item.label === ''"
            :minWidth="item.type === 'title' ? '' : item.minWidth"
            :width="item.type === 'title' ? item.minWidth : ''"
            :class-name="item.type !== 'title' ? 'isBack' : ''"
            :show-header="false">
            <template #header="{column, $index}">
              <div v-if="item.label" class="headerInfo">
                <img v-if="item.url" :src="item.url" alt="">
                {{ item.label }}
              </div>
              <div v-else class="headerTitle">
                {{ 
                  (modelValue == '0' ? 
                  $t("Dashboard.MainCountry")+$t("Files.Name") : 
                  modelValue == '1' ? 
                  $t("Dashboard.MainCustomers")+$t("Files.Name") : 
                  modelValue == '2' ?
                  $t("Dashboard.MainProducts")+$t("Files.Name") : "")
                }}
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="!scope.row.type">
                <template v-if="typeof scope.row.value[index] == 'object'">
                  <div v-show="scope.row.isOpen" class="infoDataBox" :style="`
                        background-image: url(${scope.row.value[index].url});
                      `">
                    <div 
                      class="item" 
                      v-for="(item, idx) in originCountryTitleCopy[scope.$index].children"
                      :key="idx"
                    >{{ scope.row.value[index][item.key] || (typeof scope.row.value[index][item.key] == 'string' ? "　" : "0") }}</div>
                  </div>
                </template>
                <template v-else>
                  <div class="contrastTableTitle" @click="clickOpenTable(scope.row, scope.$index)">
                    {{scope.row.value[index]}}
                    <i :class="!scope.row.isOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                  </div>
                  <div class="labelTitleList" v-show="scope.row.isOpen">
                    <div 
                      class="item" 
                      v-for="(item, idx) in originCountryTitleCopy[scope.$index].children"
                      :key="idx"
                    >{{ item.name }}</div>
                  </div>
                </template>
              </div>
              <div v-else>
                <div class="contrastTableTitle" v-if="scope.row.value[index]" @click="clickOpenTable(scope.row, scope.$index)">
                  {{scope.row.value[index]}}
                  <i :class="!scope.row.isOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
                </div>
                <span v-if="!index" v-show="scope.row.isOpen">
                  <div class="screen" v-if="scope.row.formData">
                    <chat-select 
                      v-model="data[scope.row.key].dateType" 
                      size="small" 
                      style="width: 100%;"
                      :clearable="scope.row.key == 'supplierConparisonSearch' || scope.row.key == 'exportDataSearch'"
                      v-if="scope.row.formData.includes('dateType')"
                      @change="dataKanbanTypeClick(scope.row)">
                      <el-option
                        v-for="row in [
                          { name: $t('Dashboard.Monthly'), title: '0' },
                          {
                            name: $t('Dashboard.Quarterly'),
                            title: '1',
                          },
                          { name: $t('Dashboard.Yearly'), title: '2' },
                        ]"
                        :key="row.title"
                        :label="row.name"
                        :value="row.title"
                      />
                    </chat-select>
                    <template v-if="scope.row.formData.includes('skuNumber') || scope.row.formData.includes('skuProportion')">
                      <el-input-number
                        v-model="data[scope.row.key].skuNumber" 
                        size="small" 
                        controls-position="right" 
                        style="width: 100%;"
                        :min="0"
                        :placeholder="$t('Dashboard.quantity')"
                        :ref="'skuNumber'+scope.row.key"
                        @blur="() => {
                          data[scope.row.key].skuProportion = '';
                          $nextTick(() => {
                            dataKanbanTypeClick(scope.row)
                          })
                        }"
                        @keyup.enter.native="() => {
                          $refs['skuNumber'+scope.row.key][0].$refs.input.blur();
                        }" 
                      />
                      <el-input-number
                        v-model="data[scope.row.key].skuProportion" 
                        size="small" 
                        controls-position="right" 
                        style="width: 100%;"
                        :min="0"
                        :placeholder="$t('Dashboard.Proportion')+'%'"
                        :ref="'skuProportion'+scope.row.key"
                        @blur="() => {
                          data[scope.row.key].skuNumber = undefined;
                          $nextTick(() => {
                            dataKanbanTypeClick(scope.row)
                          })
                        }"
                        @keyup.enter.native="() => {
                          $refs['skuProportion'+scope.row.key][0].blur();
                        }" 
                      />
                    </template>
                    <chat-select
                      v-if="scope.row.formData.includes('skuCode') && modelValue != '2'"
                      v-model="data[scope.row.key].skuCode"
                      v-selectLazyLoad="selectTablePageLoad"
                      filterable
                      multiple
                      collapse-tags
                      size="small"
                      style="width: 100%;"
                      :placeholder="$t('Dashboard.Please select item no')"
                      @change="skuCodeChange(scope.row)"
                    >
                      <el-option
                        v-for="row in skuList"
                        :key="row.value"
                        :label="row.label"
                        :value="row.value"
                      />
                    </chat-select>
                    <template v-if="scope.row.formData.includes('stage')">
                      <chat-select
                        size="small"
                        v-model="data[scope.row.key].startStage"
                        clearable
                        style="width: 100%;"
                        :placeholder="$t('Dashboard.Matter')"
                        @change="dataKanbanTypeClick(scope.row)"
                      >
                        <el-option
                          v-for="item in orderStatusOptions.filter((item, index) => index < (data[scope.row.key].endStage - 1))"
                          :key="item.id"
                          :label="$t(item.label)"
                          :value="item.id"
                        />
                      </chat-select>
                      <chat-select
                        size="small"
                        v-model="data[scope.row.key].endStage"
                        clearable
                        style="width: 100%;"
                        :placeholder="$t('Dashboard.Matter')"
                        @change="dataKanbanTypeClick(scope.row)"
                      >
                        <el-option
                          v-for="item in orderStatusOptions.filter((item, index) => index > (data[scope.row.key].startStage - 1))"
                          :key="item.id"
                          :label="$t(item.label)"
                          :value="item.id"
                        />
                      </chat-select>
                    </template>
                    <el-date-picker
                      style="width: 100%;"
                      v-model="data[scope.row.key].time"
                      v-if="scope.row.formData.includes('time')"
                      type="daterange"
                      size="small"
                      :start-placeholder="$t('Dashboard.Startfrom')"
                      :end-placeholder="$t('Dashboard.Endoftime')"
                      :picker-options="pickerData"
                      @change="dataKanbanTypeClick(scope.row)"
                    />
                  </div>
                </span>
                <div v-else v-show="scope.row.isOpen">
                  <div  style="height: 300px;width:100%" :id="'echarts' + scope.row.type + scope.row.key + index"></div>
                  <div class="tableStatistics" v-if="scope.row.key === 'skuAmount_PieSearch'">
                    <div>
                      <div class="title">{{$t('Dashboard.Total')}}</div>
                      <span>{{ data[scope.row.key].chartTotal[index - 1] }}</span>
                    </div>
                    <div>
                      <div class="title">{{ $t('Dashboard.ProductNumber') }}</div>
                      <span>{{ data[scope.row.key].numberCountry[index - 1] }}</span>
                    </div>
                    <div>
                      <div class="title">{{ $t('Dashboard.Proportion') }}</div>
                      <span>{{ data[scope.row.key].skuProportionNum[index - 1] }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import { mapGetters } from "vuex";
import leftColumn from "../mixins/contrast";
import { pickerOptions, formatNum } from "@/utils/utils";
import { getUserCustomList } from "@/api/customer";
import { 
  getComparePurchasingAmount, 
  getCountryContrastDetail, 
  getCompareVendorDetail,
  getCompareSupplierComparison,
  getCompareSupplierAmount,
  getCompareSkuAmountPrice,
  getCompareSkuUnitPrice,
  getCompareExportData,
  getSkuParam,
  getCompareSkuData,
  getCompareOrderStatusSummary
} from "@/api/chart";

export default {
  mixins: [leftColumn],
  data() {
    return {
      pageLeftSize: 20,
      tableLoading: false,
      modelValue: '',
      selectLoading: false,
      data: {
        shipmentQuantitySearch: {
          dateType: '0',
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        supplierConparisonSearch: {
          dateType: null,
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        supplierAmountSearch: {
          dateType: '0',
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        skuAmount_PieSearch: {
          skuNumber: undefined,
          skuProportion: undefined,
          maxYValue: [],
          chartTotal: [0,0,0,0,0],
          skuProportionNum: [0,0,0,0,0],
          numberCountry: [0,0,0,0,0],
          time: [dayjs().startOf('year'), dayjs()],
        },
        skuAmount_BarSearch: {
          skuNumber: undefined,
          skuProportion: undefined,
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        skuUnitPriceSearch: {
          skuCode: [],
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        exportProportionSearch: {
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        exportDataSearch: {
          dateType: null,
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        orderStatusSummary_PieSearch: {
          startStage: 1,
          endStage: 7,
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
        orderStatusSummary_BarSearch: {
          startStage: 1,
          endStage: 7,
          maxYValue: [],
          time: [dayjs().startOf('year'), dayjs()],
        },
      },
      purchasingAmountData: [],
      supplierComparisonData: [],
      supplierAmountData: [],
      skuAmountPricePieData: [],
      skuAmountPriceBarData: [],
      skuUnitPriceData: [],
      exportProportionData: [],
      exportBarData: [],
      orderStatusSummaryPieData: [],
      orderStatusSummaryBarData: [],
      dashboardList: [0,1,2,3,4,5,6,7,8,9,10],
      dashboardObject: {
        1: 'orderStatusSummary',
        3: 'commodityPrice',
        4: 'supplierConparison',
        5: 'supplierAmount',
        6: 'exchangeRate',
        7: 'exportData',
        8: 'exportProportion',
        9: 'skuAmount',
        10: 'skuUnitPrice',
      },
      searchValue: '',
      currentSelectPage: 1,
      modelSelectOptions: [],
      checkboxValue: [],
      checkboxContrastList: [],
      originCountryTitle: [],
      originCountryTitleCopy: [],
      tableData: [],
      transTitle: [],
      transData: [],
      skuList: [],
      currentTableSelectPage: 1
    }
  },
  inject: ['getTimeConfig', 'getConfig'],
  computed: {
    ...mapGetters(["minuteCountry", "country", "chartColors", "orderStatusOptions", "companyCurrentType"]),
    i18n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    timeConfig() {
      return this.getTimeConfig();
    },
    artConfig() {
      return this.getConfig()
    },
    modelOptions() {
      return [{
        label: this.$t('xiaoxie.country'),
        value: '0',
      }, {
        label: this.companyCurrentType == '1' || this.companyCurrentType == '2' ? this.$t('xiaoxie.customer') : this.$t('Dashboard.supplier'),
        value: '1',
      }, {
        label: this.$t('Dashboard.Product'),
        value: '2',
      }]
    }
  },
  watch: {
    modelValue(val) {
      if(val === '') {
        this.modelSelectOptions = [];
        this.clearCheckedData();
      }
      this.setColumn();
    },
    transData: {
      handler() {
        this.$nextTick(() => {
          this.$refs.contrastTable.doLayout();
        })
      },
      deep: true
    },
    'artConfig.isNeat'(val) {
      this.configEchartsNeat()
    },
    'timeConfig.globalTimeContrast': {
      handler(val) {
        this.setTime(val, true)
      },
      deep: true
    }
  },
  mounted() {
    if(this.getConfig().modelContrastState) {
      this.modelValue = this.getConfig().modelContrastState;
      this.modelChange(this.getConfig().modelContrastState);
      setTimeout(() => {
        this.checkboxValue = this.getConfig().contrastSelectItem;
        this.checkBoxChange(this.getConfig().contrastSelectItem);
      }, 500)
    }
  },
  methods: {
    leftLoad() {
      this.pageLeftSize += 20;
    },
    headerClick(column) {
      if(this.modelValue == '0') {
        this.$router.push(`/dashboard/1?countryId=${this.transTitle[column.index].id}`);
      }
      if(this.modelValue == '1') {
        this.$router.push(`/dashboard/2?companyId=${this.transTitle[column.index].id}`);
      }
      if(this.modelValue == '2') {
        this.$router.push(`/dashboard/3?productId=${this.transTitle[column.index].id}`);
      }
    },
    clickOpenTable(row, index) {
      this.$set(this.transData, index, {
        ...this.transData[index],
        isOpen: !this.transData[index].isOpen
      })
    },
    configEchartsNeat() {
      for(let i in this.data) {
        this.setOptions(i);
      }
    },
    isTableColType(col) {
      if(typeof col === 'object') return col.type;
      else return 'string';
    },
    getListId() {
      if(this.modelValue == '0') {
        return this.checkboxContrastList.map(item => item.iso3);
      }
      if(this.modelValue == '1') {
        return this.checkboxContrastList.map(item => item.companyId);
      }
      if(this.modelValue == '2') {
        return this.checkboxContrastList.map(item => `${item.itemNo}\\001${item.type}\\001${item.size}`);
      }
    },
    getList() {
      if(!this.checkboxContrastList.length) return;
      let dataSort = []
      this.checkboxContrastList.forEach((item, index) => {
        let labelTitle = this.modelValue == '0' ? item.countryCn : this.modelValue == '1' ? item.aliasName : item.itemNo;
        let labelUrl = this.modelValue == '0' ? item.nationalFlag : this.modelValue == '1' ? "" : "";
        let columnId = this.modelValue == '0' ? item.iso3 : this.modelValue == '1' ? item.companyId : item.itemNo;
        this.transTitle.push({
          label: labelTitle, 
          url: labelUrl,
          minWidth: '320px', 
          id: columnId 
        })
        let obj = {};
        this.originCountryTitle.forEach(row => {
          obj[row.key] = item[row.key]
        })
        dataSort.push(obj)
      })
      this.tableData = dataSort;
      let matrixData = this.tableData.map((row) => {
        let arr = []
        for (let key in row) {
          arr.push(row[key])
        }
        return arr
      })
      this.transData = matrixData[0].map((col, i) => {
        let titleName = this.originCountryTitle[i]?.name;
        if(titleName) {
          return {
            id: i,
            idArr: this.getListId(),
            key: this.originCountryTitle[i].key+'Search',
            type: this.originCountryTitle[i]?.type,
            isOpen: true,
            value: [titleName, ...matrixData.map((item) => {
              return item[i]
            })]
          }
        }
      }).filter(item => item);

      let shipmentQuantityIndex = this.transData.findIndex(item => item.key === 'shipmentQuantitySearch')
      this.spliceColumnData(shipmentQuantityIndex, ['dateType', 'time'])

      let supplierConparisonIndex = this.transData.findIndex(item => item.key === 'supplierConparisonSearch')
      this.spliceColumnData(supplierConparisonIndex, ['dateType', 'time'])

      let supplierAmountIndex = this.transData.findIndex(item => item.key === 'supplierAmountSearch')
      this.spliceColumnData(supplierAmountIndex, ['time'])

      let skuAmount_PieIndex = this.transData.findIndex(item => item.key === 'skuAmount_PieSearch')
      this.spliceColumnData(skuAmount_PieIndex, ['skuNumber', 'skuProportion', 'time'])

      let skuAmount_BarIndex = this.transData.findIndex(item => item.key === 'skuAmount_BarSearch')
      this.spliceColumnData(skuAmount_BarIndex, ['skuNumber', 'skuProportion', 'time'])

      let skuUnitPriceIndex = this.transData.findIndex(item => item.key === 'skuUnitPriceSearch')
      this.spliceColumnData(skuUnitPriceIndex, ['skuCode', 'time'])

      let exportProportionIndex = this.transData.findIndex(item => item.key === 'exportProportionSearch')
      this.spliceColumnData(exportProportionIndex, ['time'])

      let exportDataIndex = this.transData.findIndex(item => item.key === 'exportDataSearch')
      this.spliceColumnData(exportDataIndex, ['time', 'dateType'])

      let orderStatusSummary_PieIndex = this.transData.findIndex(item => item.key === 'orderStatusSummary_PieSearch')
      this.spliceColumnData(orderStatusSummary_PieIndex, ['time', 'stage'])

      let orderStatusSummary_BarIndex = this.transData.findIndex(item => item.key === 'orderStatusSummary_BarSearch')
      this.spliceColumnData(orderStatusSummary_BarIndex, ['time', 'stage'])

      setTimeout(_ => {
        this.transData.forEach((e) => {
          if(e.type) {
            this.setEcharts(e.type, e.idArr, e.key)
          }
        })
        this.setFixedTableRowStyle()
        window.addEventListener('resize', () => {
          this.setFixedTableRowStyle()
        })
      }, 500)
    },
    spliceColumnData(index, formData) {
      if(index >= 0) {
        this.transData[index] = {
          ...this.transData[index],
          formData: formData,
        }
      }
    },
    selectTablePageLoad() {
      this.currentTableSelectPage++;
      this.getSkuParam();
    },
    getSkuParam() {
      const { 
        time
      } = this.data.skuUnitPriceSearch;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getSkuParam({ 
        startTime, 
        endTime, 
        skuStr: '',
        currentPage: this.currentTableSelectPage,
        pageSize: 20,
      }).then(
        (data) => {
          this.skuList = this.skuList.concat(data.list.map((item, index) => ({
            label: `${item.itemNo} | ${item.type} | ${item.size}`,
            value: JSON.stringify(item)
          })));
        }
      );
    },
    skuCodeChange({idArr, key}) {
      if(key === 'skuUnitPriceSearch') {
        this.getCompareSkuUnitPrice(idArr, key)
      }
    },
    dataKanbanTypeClick({idArr, key}) {
      this.$parent.isNeat = false;
      if(key === 'shipmentQuantitySearch') {
        this.getComparePurchasingAmount(idArr, key)
      }
      if(key === 'supplierConparisonSearch') {
        this.getCompareSupplierComparison(idArr, key)
      }
      if(key === 'supplierAmountSearch') {
        this.data[key].dateType = '0';
        this.getCompareSupplierAmount(idArr, key)
      }
      if(key === 'skuAmount_PieSearch') {
        this.getCompareSkuAmountPrice(idArr, key, 'Pie')
      }
      if(key === 'skuAmount_BarSearch') {
        this.getCompareSkuAmountPrice(idArr, key, 'Bar')
      }
      if(key === 'skuUnitPriceSearch') {
        if(this.modelValue != '2') {
          this.getSkuParam();
        }
        this.getCompareSkuUnitPrice(idArr, key)
      }
      if(key === 'exportProportionSearch') {
        this.getCompareExportData(idArr, key, 'exportProportionSearch')
      }
      if(key === 'exportDataSearch') {
        this.getCompareExportData(idArr, key, 'exportDataSearch')
      }
      if(key === 'orderStatusSummary_PieSearch') {
        this.getCompareOrderStatusSummary(idArr, key, 'Pie')
      }
      if(key === 'orderStatusSummary_BarSearch') {
        this.getCompareOrderStatusSummary(idArr, key, 'Bar')
      }
    },
    getComparePurchasingAmount(idArr, key) {
      const { dateType, time } = this.data.shipmentQuantitySearch;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getComparePurchasingAmount({
        comparisonList: idArr,
        comparisonType: this.modelValue,
        param: {
          dateType,
          startTime, 
          endTime
        }
      }).then((data) => {
        if(data) {
          const dataKeysMap = Object.keys(data).map((k) => data[k]);
          const dataMap = dataKeysMap.map(({data}) => {
            return {
              xAxis: data.map(row => row.date),
              data: data.map(row => {
                return Number(row.amount).toFixed(2);
              })
            }
          })
          this.purchasingAmountData = dataMap;
          this.setOptions(key);
        }
      })
    },
    getCompareSupplierComparison(idArr, key) {
      const { dateType, time } = this.data.supplierConparisonSearch;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getCompareSupplierComparison({
        comparisonList: idArr,
        comparisonType: this.modelValue,
        param: {
          dateType,
          startTime, 
          endTime,
        }
      }).then((data) => {
        if(data) {
          const dataKeysMap = Object.keys(data).map((k) => data[k]);
          const dataMap = dataKeysMap.map(({data}) => {
            let timeList = [];
            let seriesList = [];
            let customerArray = [];
            if(dateType) {
              let list = [];
              data.forEach((v) => {
                timeList.push(v.date);
                if (v.customerList) {
                  v.customerList = v.customerList.map((item) => ({
                    date: item.date,
                    value: Number(item.amount).toFixed(2),
                    name: item.aliasName || item.name
                  }));
                  list.push(...v.customerList);
                }
              });
              let aggregate = _.groupBy(list, "name");
              for(let i in aggregate) {
                customerArray.push(i)
              }
              customerArray.forEach(customer => {
                seriesList.push({
                  name: customer,
                  data: []
                })
              })
              seriesList.forEach((item) => {
                timeList.forEach((time, idx) => {
                  let data = list.filter(row => item.name == row.name && time == row.date)[0];
                  item.data[idx] = data?.value || 0;
                });
              });
            } else {
              seriesList = data.map(item => {
                timeList.push(item.aliasName || item.name)
                return Number(item.amount).toFixed(2);
              });
            }
            let indexOfOther = timeList.findIndex(item => item === 'other' || item === '其他');
            if(indexOfOther != -1) {
              seriesList.push(seriesList[indexOfOther]);
              seriesList.splice(indexOfOther, 1);
            }
            return {
              xAxis: timeList,
              data: seriesList
            }
          })
          this.supplierComparisonData = dataMap;
          this.setOptions(key);
        }
      })
    },
    getCompareSupplierAmount(idArr, key) {
      const { time } = this.data.supplierAmountSearch;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getComparePurchasingAmount({
        comparisonList: idArr,
        comparisonType: this.modelValue,
        param: {
          startTime, 
          endTime
        }
      }).then((data) => {
        const dataKeysMap = Object.keys(data).map((k) => data[k]);
        const dataMap = dataKeysMap.map(({data}) => {
          let total = 0;
          data.forEach(v => {
            total += Number(v.amount);
          })
          let arr = data.map((row) => {
            const { aliasName, amount, name } = row;
            return {
              value: Number(amount).toFixed(2),
              name: `${aliasName || name} ${Number((amount / total) * 100).toFixed(
                2
              )}% : ${formatNum(Number(amount).toFixed(2)) || "0"}(USD)`,
              sheName: aliasName || name,
              proportion: Number((amount / total) * 100).toFixed(2),
            };
          });
          let indexOfOther = arr.findIndex(item => item.sheName === 'other' || item.sheName === '其他');
          if(indexOfOther != -1) {
            arr.push(arr[indexOfOther]);
            arr.splice(indexOfOther, 1);
          }
          return {
            data: arr
          }
        })
        this.supplierAmountData = dataMap;
        this.setOptions(key);
      })
    },
    getCompareSkuAmountPrice(idArr, key, type) {
      const { skuNumber, skuProportion, time } = this.data[`skuAmount_${type}Search`];
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getCompareSkuAmountPrice({
        comparisonList: idArr,
        comparisonType: this.modelValue,
        param: {
          number: Number(skuNumber || 10).toFixed(0),
          proportion: Number(skuProportion || 0).toFixed(0),
          startTime, 
          endTime
        }
      }).then((data) => {
        const dataKeysMap = Object.keys(data).map((k) => data[k]);
        if(type === 'Pie') {
          const dataMap = dataKeysMap.map((data, index) => {
            let total = 0;
            let proportion = 0;
            let arr = _.orderBy(
              data,
              (v) => {
                return Number(v.amount);
              },
              'desc'
            )?.map((v, i) => {
              total += Number(v?.amount);
              proportion += v?.proportion;
              return {
                value: Number(v?.amount),
                amountProportion: v?.proportion,
                name: `${v?.size || ''} ${v?.type || ''} ${v?.itemNo} ${Number(
                  v?.proportion
                ).toFixed(2)}% : ${formatNum(Number(v?.amount).toFixed(2)) || "0"
                  }(USD)`,
                code: v?.itemNo,
                itemStyle: {
                  color: v?.itemNo == '其他' || v?.itemNo == 'other' ? "rgba(154, 163, 172, .5)" : null
                }
              };
            });
            this.$set(this.data[key].chartTotal, index, formatNum(Number(total).toFixed(2)) || '0');
            this.$set(this.data[key].skuProportionNum, index, Number(proportion).toFixed(2));
            this.$set(this.data[key].numberCountry, index, data?.length || '0');
            let indexOfOther = arr.findIndex(item => item.itemNo === 'other' || item.code === '其他')
            if(indexOfOther != -1) {
              arr.push(arr[indexOfOther]);
              arr.splice(indexOfOther, 1);
            }
            return {
              data: arr
            }
          })
          this.skuAmountPricePieData = dataMap;
          this.setOptions(key);
        } else {
          const dataMap = dataKeysMap.map((data) => {
            return {
              data: data.map(item => Number(item?.amount)).reverse(),
              yAxis: data.map(item => `${item?.size || ''} ${item?.type || ''} ${item?.itemNo}`).reverse(),
              amountProportion: data.map(item => `${Number(item?.proportion).toFixed(2)}%`)
            }
          })
          console.log(dataMap);
          this.skuAmountPriceBarData = dataMap;
          this.setOptions(key);
        }
      })
    },
    getCompareSkuUnitPrice(idArr, key) {
      const { time, skuCode } = this.data.skuUnitPriceSearch;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      let arr = this.modelValue == '2' ? idArr : skuCode;
      arr = arr.map(item => ({
        itemNo: item.split("\\001")[0],
        type: item.split("\\001")[1],
        size: item.split("\\001")[2],
      }))
      getCompareSkuUnitPrice({
        comparisonList: idArr,
        comparisonType: this.modelValue,
        param: {
          skuList: arr,
          startTime, 
          endTime
        }
      }).then((data) => {
        const dataKeysMap = Object.keys(data).map((k) => data[k]);
        const dataMap = dataKeysMap.map(item => {
          let arr = [];
          let currencyList = [];
          let list = [];
          item.forEach((vo) => {
            arr.push({
              ...vo,
              id: vo.itemNo+'|'+vo.type+'|'+vo.size
            });
          });
          let aggregate = _.groupBy(arr, 'id');
          for (let index in aggregate) {
            currencyList.push(index);
            list.push({
              name: index,
              data: aggregate[index].map((res) => {
                return [dayjs(res.date).format('YYYY-MM-DD'), res.unitPrice];
              }),
            });
          }
          let timeArr = list.map(item => {
            return item.data.map(row => row[0])
          }) || [];
          let xAixsArray = []
          if(timeArr.length) {
            xAixsArray = timeArr.reduce((a, b) => a.concat(b));
          }
          let xAixsSort = xAixsArray.sort((a, b) => Date.parse(a) - Date.parse(b));
          return {
            legendData: currencyList,
            xAixs: xAixsSort,
            data: list
          }
        })
        this.skuUnitPriceData = dataMap;
        this.setOptions(key);
      })
    },
    getCompareExportData(idArr, key, type) {
      const { time, dateType } = this.data[type];
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getCompareExportData({
        comparisonList: idArr,
        comparisonType: this.modelValue,
        param: {
          dateType,
          startTime, 
          endTime
        }
      }).then((data) => {
        if(data) {
          const dataKeysMap = Object.keys(data).map((k) => data[k]);
          if(type === 'exportProportionSearch') {
            const dataMap = dataKeysMap.map(({data}) => {
              let arr = []
              let total = 0;
              data.forEach((v) => {
                total += Number(v.amount);
              });
              arr = data?.map((row) => {
                const { country, amount } = row;
                let countryName = this.$isFindData(this.country, country, 'value', 'key');
                return {
                  value: Number(amount).toFixed(2),
                  countryName,
                  name: `${countryName} ${Number((amount / total) * 100).toFixed(
                    2
                  )}%: ${formatNum(Number(amount).toFixed(2)) || "0"}(USD)`,
                  proportion: Number((amount / total) * 100).toFixed(2),
                  itemStyle: {
                    color: countryName == '其他' || countryName == 'other' ? "rgba(154, 163, 172, .5)" : null
                  }
                };
              });
              let indexOfOther = arr.findIndex(item => item.countryName == '其他' || item.countryName == 'other')
              if(indexOfOther != -1) {
                arr.push(arr[indexOfOther]);
                arr.splice(indexOfOther, 1);
              }
              return {
                xAxis: data.map(row => row.date),
                data: arr
              }
            })
            this.exportProportionData = dataMap;
            this.setOptions(key);
          }
          if(type === 'exportDataSearch') {
            const dataMap = dataKeysMap.map(({data}) => {
              let timeList = [];
              let seriesList = [];
              let countryArray = [];
              if (this.data[key].dateType) {
                let list = [];
                data.forEach((v) => {
                  timeList.push(v.date);
                  if (v.countryList) {
                    v.countryList = v.countryList.map((item) => ({
                      ...item,
                      countryName: this.$isFindData(
                        this.country,
                        item.country,
                        "value",
                        "key"
                      ),
                    }));
                    list.push(...v.countryList);
                  }
                });
                let aggregate = _.groupBy(list, "countryName");
                let timeAggregate = _.groupBy(list, "date");
                for(let i in aggregate) {
                  countryArray.push(i)
                }
                countryArray.forEach(country => {
                  seriesList.push({
                    name: country,
                    data: []
                  })
                })
                seriesList.forEach((item) => {
                  timeList.forEach((time, idx) => {
                    let data = list.filter(row => item.name == row.countryName && time == row.date)[0];
                    item.data[idx] = data?.amount || 0;
                  });
                });
              } else {
                seriesList = data.map((v) => {
                  const { amount, country, date } = v;
                  let countryName = this.$isFindData(this.country, country, "value", "key")
                  timeList.push(date || countryName);
                  return {
                    value: Number(amount).toFixed(2),
                    name: countryName,
                    itemStyle: {
                      color: countryName == '其他' || countryName == 'other' ? "rgba(154, 163, 172, .5)" : null
                    }
                  }
                });
                let indexTimeOfOther = timeList.findIndex(item => item === 'other' || item === '其他')
                if(indexTimeOfOther != -1) {
                  timeList.push(seriesList[indexTimeOfOther].name);
                  timeList.splice(indexTimeOfOther, 1);
                }
              }
              let indexOfOther = seriesList.findIndex(item => item.name === 'other' || item.name === '其他')
              if(indexOfOther != -1) {
                seriesList.push(seriesList[indexOfOther]);
                seriesList.splice(indexOfOther, 1);
              }
              console.log(seriesList, timeList);
              return {
                xAxis: timeList,
                data: seriesList
              }
            })
            this.exportBarData = dataMap;
            this.setOptions(key);
          }
        }
      })
    },
    getCompareOrderStatusSummary(idArr, key, type) {
      const { startStage, endStage, time } = this.data[`orderStatusSummary_${type}Search`];
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      getCompareOrderStatusSummary({
        comparisonList: idArr,
        comparisonType: this.modelValue,
        param: {
          startStage,
          endStage,
          startTime, 
          endTime
        }
      }).then((data) => {
        const dataKeysMap = Object.keys(data).map((k) => data[k]);
        if(type === 'Pie') {
          const dataMap = dataKeysMap.map((item, index) => {
            let arr = _.orderBy(
              item,
              (v) => {
                return Number(v.days);
              },
              'desc'
            )?.map((v, i) => {
              return {
                value: Number(v?.days),
                proportion: v?.proportion || 0,
                name: `${this.$t(`${this.orderStatusOptions[i].label}`)} ${Number(v?.days).toFixed(1)}`
              };
            });
            return {
              data: arr
            }
          })
          this.orderStatusSummaryPieData = dataMap;
          this.setOptions(key);
        } else {
          const dataMap = dataKeysMap.map(item => {
            return {
              data: item.map(item => Number(item?.days)),
              xAxis: item.map((item, i) => this.$t(`${this.orderStatusOptions[i].label}`))
            }
          })
          this.orderStatusSummaryBarData = dataMap;
          this.setOptions(key);
        }
      })
    },
    setEcharts(type, idArr, key) {
      if(type === 'line') {
        if(key === 'shipmentQuantitySearch') {
          this.getComparePurchasingAmount(idArr, key);
        }
        if(key === 'skuUnitPriceSearch') {
          this.getCompareSkuUnitPrice(idArr, key)
        }
      }
      if(type === 'bar') {
        if(key === 'supplierConparisonSearch') {
          this.getCompareSupplierComparison(idArr, key);
        }
        if(key === 'exportDataSearch') {
          this.getCompareExportData(idArr, key, 'exportDataSearch');
        }
        if(key === 'skuAmount_BarSearch') {
          this.getCompareSkuAmountPrice(idArr, key, 'Bar');
        }
        if(key === 'orderStatusSummary_BarSearch') {
          this.getCompareOrderStatusSummary(idArr, key, 'Bar');
        }
      }
      if(type === 'pie') {
        if(key === 'supplierAmountSearch') {
          this.getCompareSupplierAmount(idArr, key);
        }
        if(key === 'skuAmount_PieSearch') {
          this.getCompareSkuAmountPrice(idArr, key, 'Pie');
        }
        if(key === 'orderStatusSummary_PieSearch') {
          this.getCompareOrderStatusSummary(idArr, key, 'Pie');
        }
        if(key === 'exportProportionSearch') {
          this.getCompareExportData(idArr, key, 'exportProportionSearch');
        }
      }
    },
    startContrast() {
      this.$nextTick(() => {
        this.getList();
      })
    },
    modelChange(value) {
      this.selectLoading = true;
      this.checkboxValue = [];
      this.skuList = [];
      this.currentSelectPage = 1;
      this.currentTableSelectPage = 1;
      if(!value.length) {
        this.selectLoading = false;
      }
      this.$parent.modelContrastState = value;
      if(value == '0') {
        this.modelSelectOptions = this.minuteCountry.map(item => ({
          label: item.countryName,
          value: item.iso3,
          image: item.nationalFlag,
          disabled: false
        }));
        this.selectLoading = false;
      }
      if(value == '1') {
        getUserCustomList({
          currentPage: 1,
          pageSize: 10000,
          companyType: this.companyCurrentType == '0' ? '1' : this.companyCurrentType == '1' ? '0' : this.companyCurrentType == '2' ? '1' : '0'
        }).then((data) => {
          this.modelSelectOptions = data.list.map(item => ({
            label: item.aliasName,
            value: item.companyId,
            disabled: false
          }));
        }).finally(() => this.selectLoading = false);
      }
      if(value == '2') {
        this.modelSelectOptions = [];
        this.getParentSkuList();
      }
    },
    selectPageLoad() {
      if(this.modelValue == '2') {
        this.currentSelectPage++;
        this.getParentSkuList();
      }
    },
    getParentSkuList() {
      getSkuParam({
        startTime: '',
        endTime: '',
        currentPage: this.currentSelectPage,
        pageSize: 20,
      }).then(data => {
        this.modelSelectOptions = this.modelSelectOptions.concat(data.list.map(item => ({
          label: `${item.itemNo} | ${item.type} | ${item.size}`,
          value: JSON.stringify(item),
          disabled: false
        })));
        this.selectLoading = false;
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if(row.type === 'search') {
        if(columnIndex == 1 || columnIndex == 2 || columnIndex == 3 || columnIndex == 4 || columnIndex == 5) {
          return [1, 20000]
        }
      }
    },
    cellClassName({row, column, rowIndex, columnIndex}) {
      column.index = columnIndex;
      if(row.type === 'search') {
        return 'fixed-cell'
      }
    },
    rowClassName({row, rowIndex}) {
      if(row.isOpen) {
        return 'openClass'
      }
    },
    setFixedTableRowStyle() {
      const widthTable = document.querySelector('.contrastTable')
      const fixedRowTable = document.querySelectorAll('.fixedRowTable')
      for(let i in fixedRowTable) {
        if(i === 'entries') return;
        fixedRowTable[i].setAttribute('style', `
          position: sticky;
          width: ${widthTable.clientWidth}px;
          overflow: hidden;
          left: 0;
          display: flex;
          justify-content: flex-end;
        `)
      }
    },
    clearCheckedData() {
      this.checkboxValue = [];
      this.checkboxContrastList = [];
    },
    checkBoxChange(value) {
      if(!value.length) {
        this.transTitle = [{label: '', url: '', minWidth: this.artConfig.isVipMode == 0 ? '140px' : '180px', type: "title"}];
        this.tableData = [];
        this.transData = [];
        this.skuList = [];
        this.currentTableSelectPage = 1;
        this.checkboxContrastList = [];
        return;
      }
      if(value.length > 5) {
        return;
      } else if(value.length >= 5) {
        this.modelSelectOptions.forEach(item => {
          if(value.includes(item.value)) return;
          item.disabled = true;
        })
      } else {
        this.modelSelectOptions.forEach(item => {
          item.disabled = false;
        })
      }
      this.$parent.contrastSelectItem = value;
      this.setTime(this.timeConfig.globalTimeContrast, false);
      this.setProjectTable(value);
    },
    setProjectTable(value) {
      this.tableLoading = true;
      if(this.modelValue != '2') {
        this.getSkuParam();
      }
      let startTime = dayjs(this.timeConfig.globalTimeContrast[0]).format('YYYY-MM-DD HH:mm:ss');
      let endTime = dayjs(this.timeConfig.globalTimeContrast[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss');
      if(this.modelValue == '0') {
        getCountryContrastDetail({
          list: value,
          startTime,
          endTime
        }).then((data) => {
          this.tableLoading = false;
          let arr = Object.keys(data).map((k) => ({
            ...data[k],
            countryInfo: {
              ...data[k],
              population: formatNum(String(data[k].population)),
              areaKm: formatNum(String(data[k].areaKm)),
              gdp: formatNum(Number(data[k].gdp).toFixed(2)),
              gdpAvg: formatNum(Number(data[k].gdpAvg).toFixed(2)),
              url: data[k].nationalMap
            },
            marketInfo: {
              ...data[k],
              total: formatNum(Number(data[k].totalAmount?.USD || 0).toFixed(2)),
            }
          }))
          this.setTableConfig(arr)
        }).catch(() => {
          this.tableLoading = false;
        })
      }
      if(this.modelValue == '1') {
        getCompareVendorDetail({
          list: value,
          startTime,
          endTime
        }).then((data) => {
          this.tableLoading = false;
          let arr = Object.keys(data).map((k) => data[k])
          arr = arr.map(item => ({
            ...item,
            customerInfo: item,
            marketInfo: {
              ...item,
              total: formatNum(Number(item.totalAmount?.USD || 0).toFixed(2))
            }
          }))
          this.setTableConfig(arr)
        }).catch(() => {
          this.tableLoading = false;
        })
      }
      if(this.modelValue == '2') {
        getCompareSkuData({
          list: value.map(item => JSON.parse(item)),
          startTime,
          endTime
        }).then((data) => {
          this.tableLoading = false;
          let arr = Object.keys(data).map((k) => ({
            ...data[k],
            productInfo: data[k],
            marketInfo: {
              ...data[k],
              total: formatNum(Number(data[k].totalAmount?.USD || 0).toFixed(2))
            }
          }))
          this.setTableConfig(arr)
        }).catch(() => {
          this.tableLoading = false;
        })
      }
    },
    setTableConfig(data) {
      if(this.modelValue == '2') {
        this.originCountryTitle = this.originCountryTitle.filter(item => item.name != '生产物流分析');
      } else {
        this.originCountryTitle = this.originCountryTitleCopy;
      }
      for(let i = this.originCountryTitle.length - 1; i >= 0; i--) {
        if(this.originCountryTitle[i].key !== 'shipmentQuantity') {
          if(this.originCountryTitle[i].type) {
            // console.log(this.dashboardList,this.originCountryTitle[i].key.split('_')[0])
            // if(!this.dashboardList.includes(this.originCountryTitle[i].key.split('_')[0])) {
            //   this.originCountryTitle.splice(i, 1)
            // }
          }
        }
        if(this.data[this.originCountryTitle[i].key+'Search']?.maxYValue.length > 0) {
          this.data[this.originCountryTitle[i].key+'Search'].maxYValue = []
        }
      }
      this.$parent.isNeat = false;
      this.$nextTick(() => {
        this.transTitle = [{label: '', url: '', minWidth: this.artConfig.isVipMode == 0 ? '140px' : '180px', type: "title"}];
        this.checkboxContrastList = data;
        this.getList();
      })
    },
    setTime(val, isUpdate) {
      for(let i in this.data) {
        this.data[i].time = val;
      }
      if(!isUpdate) return;
      if(!this.modelValue) return;
      this.setProjectTable(this.checkboxValue);
    }
  }
}
</script>

<style lang="less" scoped>
.contrast_card {
  padding: 16px 20px 0 20px;
  .selectTypeModel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    margin-bottom: 14px;
    /deep/ .el-select {
      width: 20%;
      .el-input__inner {
        background: #ffffff;
        border: none;
      }
    }
    .userSelect {
      width: 80%;
    }
  }
  /deep/.el-card {
    height: calc(100vh - 210px);
    .el-card__body {
      height: 100%;
      display: flex;
      gap: 20px;
      position: relative;
      padding: 0;
      .contrastTable {
        width: 100%;
        transition: all .3s;
        padding: 0 16px;
        .el-table__fixed {
          &::before {
            display: none;
          }
        }
        &::before {
          display: none;
        }
        .el-table {
          th.el-table__cell {
            background: #ffffff;
            .cell {
              padding-left: 0;
              padding-right: 0;
              font-size: 18px;
              font-weight: 500;
              color: #122545;
              line-height: 28px;
              cursor: pointer;
            }
          }
          tr {
            &.openClass {
              td {
                &.isBack {
                  padding-right: 20px;
                  .cell {
                    background: #F7F9FC;
                    padding: 16px;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
          td.el-table__cell {
            vertical-align: top;
            padding: 16px 0;
            .cell {
              padding: 0;
            }
          }
          .cell {
            width: 100%;
            height: 100%;
          }
        }
        &.shrink {
          width: 100%;
        }
      }
      .tableStatistics {
        position: absolute;
        top: 30%;
        right: 10%;
        text-align: left;
        .title {
          color: #2e3f4fa8;
          font-size: 12px;
          font-weight: 400;
        }
        font-size: 14px;
        color: #2e3f4f;
        font-weight: 500;
      }
    }
  }
}
.contrastTableTitle {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #122545;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.labelTitleList {
  margin-top: 19px;
  .item {
    font-size: 14px;
    line-height: 18px;
    color: #637381;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.infoDataBox {
  padding-top: 23px;
  background-size: 160px;
  background-position: center;
  background-repeat: no-repeat;
  .item {
    font-size: 14px;
    line-height: 18px;
    color: #2E2E3A;
    margin-bottom: 15px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.headerInfo {
  display: flex;
  align-items: center;
  padding-right: 20px;
  img {
    width: 24px;
    height: 18px;
    margin-right: 12px;
  }
}
.headerTitle {
  font-size: 16px;
}
.screen {
  display: flex;
  flex-direction: column;
}
/deep/.contrastTable tbody tr:hover > td  {
  background-color: #ffffff !important; 
}
/deep/ .hover-row {
  background: #ffffff !important;
  td {
    background: #ffffff !important;
  }
}
</style>

<style lang="less">
.fixed-cell {
  display: none;
  border-bottom: none !important;
  &:nth-child(1) {
    display: revert;
  }
  &:nth-child(2) {
    display: revert;
  }
}
</style>