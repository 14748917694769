<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">
        {{ $t("Dashboard.ExportProportion")+(`(${!artConfig.isAmountNumberMode ? $t('order.Amount') : $t('order.Quantity')})`) }}
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon v-if="artConfig.isVipMode == 0" icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onGetAmount()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen" v-if="artConfig.isVipMode == 0">
      <el-date-picker 
        style="width: 260px" 
        v-model="data.time" 
        type="daterange" 
        size="small"
        :start-placeholder="$t('Dashboard.Startfrom')" 
        :end-placeholder="$t('Dashboard.Endoftime')" 
        :picker-options="pickerData" />
    </div>
    <div :style="`position: relative;margin-top: 22px;`">
      <div
        :id="echartsViewId"
        class="exportDataPieChart"
        :style="`height: ${
          this.enlarge == false ? '300px' : '70vh'
        }`"
      />
      <ul
        :class="this.enlarge ? 'enlarge_statistics' : 'statistics'"
        style="z-index: 0"
      >
        <li>
          <div class="title">{{ !artConfig.isAmountNumberMode ? $t('Dashboard.Total') : $t('Dashboard.TotalNumber')}}</div>
          <span>{{ chartTotal }}</span>
        </li>
        <li>
          <div class="title">{{ $tc('Dashboard.CountryNumber', companyCurrentType) }}</div>
          <span>{{ numberCountry }}</span>
        </li>
        <li>
          <div class="title">{{ $t('Dashboard.Proportion') }}</div>
          <span>{{ proportion }}%</span>
        </li>
      </ul>
    </div>
  </el-card>
</template>
<script>
import _ from "lodash";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import { exportData } from "@/api/chart";
import { formatNum, pickerOptions } from "@/utils/utils";
export default {
  name: 'exportProportion',
  data() {
    return {
      myChart: null,
      echartsViewId: 'exportDataPieChart_',
      captureId: 'captureSveneProportion_',
      enlarge: false,
      exportLoading: false,
      chartTotal: 0,
      numberCountry: 0,
      proportion: 100,
      exportData: [],
      data: {
        country: null,
        customer: '',
        itemNo: '',
        type: '',
        size: '',
        time: [dayjs().startOf('year'), dayjs()]
      },
      chartLoading: false,
    };
  },
  inject: ['getConfig'],
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(['country', 'companyCurrentType']),
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.chartLoading = true;
        this.onGetAmount();
      },
      deep: true,
    },
    enlarge() {
      this.onPieChart(this.exportData || []);
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    'artConfig.isAmountNumberMode'() {
      this.onGetAmount();
    }
  },
  mounted() {
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document
          .getElementById(this.captureId)
          .classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.$t("Dashboard.ExportProportion");
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      let header = chartOption.series[0].data.map(item => item.namedialog);
      let data = [chartOption.series[0].data.map(item => `${item.proportion}%:${item.value}`)];
      let filename = this.$t("Dashboard.ExportProportion");
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    onGetAmount() {
      this.chartLoading = true;
      const { 
        time, 
        country, 
        customer, 
        itemNo,
        type,
        size 
      } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      exportData({
        startTime,
        endTime,
        country,
        customer,
        itemNo,
        type,
        size,
        queryType: this.artConfig.isAmountNumberMode
      })
        .then(({data}) => {
          this.exportData = data.map(item => ({
            ...item,
            countryName: this.$isFindData(this.country, item.iso3, 'value', 'key')
          }));
          this.onPieChart(this.exportData);
        })
        .finally(() => (this.chartLoading = false));
    },
    // 饼图
    onPieChart(list) {
      let total = 0;
      list.forEach((v) => {
        total += Number(v.amount);
      });
      let arr = list.map((item) => {
        const { countryName, amount, iso3 } = item;
        return {
          value: Number(amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2),
          iso3: iso3,
          name: `${countryName} ${Number((amount / total) * 100).toFixed(
            this.artConfig.isAmountNumberMode ? 0 : 2
          )}%：${formatNum(Number(amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2)) || "0"}${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}`,
          proportion: Number((amount / total) * 100).toFixed(2),
          namedialog: countryName,
          itemStyle: {
            color: countryName == '其他' || countryName == 'other' ? '#E3E9F1' : null
          }
        };
      });
      this.numberCountry = list.length;
      this.chartTotal = formatNum(Number(total).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2));
      const chartData = !this.enlarge
        ? {
          labelLine: {
            show: false,
          },
          label: {
            show: false,
            position: "center",
          },
        }
        : {};
      this.myChart.setOption(
        {
          tooltip: {
            trigger: 'item',
            confine: true,
            formatter: function (params) {
              var htmlStr = `
                <div class="echartsTooltipPieBox">
                  <div class="content">
                    <div class="label">${params.name.split("：")[0]}</div>
                    <div class="value">${params.name.split("：")[1]}</div>
                  </div>
                </div>
              `;
              return htmlStr;
            },
          },
          legend: {
            left: 'center',
            bottom: '0',
            type: 'scroll',
          },
          series: [
            {
              type: 'pie',
              left: '-25%',
              bottom: "15%",
              radius: '90%',
              avoidLabelOverlap: false,
              data: arr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              ...chartData,
            },
          ],
        },
        true
      );
      this.myChart.on("legendselectchanged", (params) => {
        let proportionValue = 0;
        let chartTotal = 0;
        arr.map((v) => {
          const { name, proportion, value } = v;
          for (let key in params?.selected) {
            if (name == key && params?.selected[key]) {
              proportionValue += Number(proportion);
              chartTotal += Number(value);
            }
          }
        });
        let objectis = Object.values(params.selected).filter((item) => item);
        this.numberCountry = objectis.length;
        this.proportion = proportionValue.toFixed(2);
        this.chartTotal = formatNum(Number(chartTotal).toFixed(2));
      });
      this.myChart.off('click');
      this.myChart.on('click', (params) => {
        console.log(params)
        if(params.data.iso3) {
          if(params.data.iso3 == "other") return;
          this.$confirm(this.$tc(
            'Dashboard.confirmSeeMessageFn',
            params.data.namedialog
          ), this.$t('Tips'), {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }).then(() => {
            this.$router.push(`/dashboard/1?countryId=${params.data.iso3}`);
          }).catch(() => {});
        }
      });
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.exportDataPieChart {
  width: 100%;
  height: 350px;
}

.tips {
  width: 100px;
  height: 100px;
}
</style>
