<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">{{ title }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon 
          :icon="contrastActive ? 'el-icon-minus' : 'el-icon-plus'" 
          :tooltipText="$t('addContrast')"
          @click="setContrast()"
        />
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onGetAmount()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen">
      <div class="left_screen">
        <div
          v-for="(item, index) in [
            { name: $t('Dashboard.Monthly'), title: '0' },
            {
              name: $t('Dashboard.Quarterly'),
              title: '1',
            },
            { name: $t('Dashboard.Yearly'), title: '2' },
          ]"
          :key="index"
          @click="onActive(item.title)"
          :class="`${data.dateType == item.title ? 'active' : ''}`"
        >
          {{ item.name }}
        </div>
      </div>
      <el-date-picker
        style="width: 260px"
        v-model="data.time"
        type="daterange"
        size="small"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
    </div>
    <div class="chart-wrapper" style="margin-top: 22px">
      <div
        :id="echartsViewId"
        class="conparisonChartBox"
        :style="`height: ${this.enlarge == false ? '300px' : '70vh'}`"
      />
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import _ from "lodash";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
import { supplierComparison } from "@/api/chart";
import { pickerOptions } from "@/utils/utils";

export default {
  name: 'supplierConparison',
  data() {
    return {
      myChart: null,
      contrastActive: false,
      echartsViewId: 'conparisonChartBox_',
      captureId: 'captureFour_',
      enlarge: false,
      countDatas: [],
      echartsData: [],
      data: {
        country: null,
        customer: '',
        itemNo: '',
        type: '',
        size: '',
        time: [dayjs().startOf('year'), dayjs()],
        dateType: '',
      },
      chartLoading: false,
      title: 'Supplier Comparison',
    };
  },
  inject: ['getConfig'],
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters(["companyCurrentType"]),
    i8n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(newVal, olVal) {
        if(this.$route.name != 'Dashboard') return;
        this.chartLoading = true;
        this.onGetAmount();
      },
      deep: true,
    },
    i8n() {
      this.onGetTitle();
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    'artConfig.isAmountNumberMode'() {
      this.onGetTitle();
      this.onGetAmount();
    },
    enlarge() {
      this.setEchartsOption(this.echartsData, this.data.dateType);
    }
  },
  mounted() {
    this.onGetTitle();
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    setContrast() {
      let arrIndex = this.$parent.$parent.$parent.contrastConfig.findIndex(item => item.name === 'supplierComparison')
      if(arrIndex !== -1) {
        this.$parent.$parent.$parent.contrastConfig.splice(arrIndex, 1);
        this.contrastActive = false;
        return;
      }
      if(this.$parent.$parent.$parent.contrastConfig.length >= 2) return;
      this.contrastActive = true;
      this.$parent.$parent.$parent.contrastConfig.push({
        name: "supplierComparison",
        title: this.title,
        echartsApi: supplierComparison,
        params: {
          iso3: this.data.iso3,
          companyId: this.data.companyId,
          itemNo: this.data.itemNo,
          dateType: this.data.dateType,
          queryType: this.artConfig.isAmountNumberMode
        },
        options: this.myChart.getOption()
      })
    },
    onActive(title) {
      if (this.data.dateType == title) {
        this.data.dateType = '';
      } else {
        this.data.dateType = title;
      }
    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document.getElementById(this.captureId).classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document.getElementById(this.captureId).classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.title;
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      let header = [
        ...chartOption.xAxis[0].data
      ];
      // let 
      let data = chartOption.series.map(item => {
        return item.data;
      });
      if(this.data.dateType) {
        header.unshift("");
        chartOption.series.forEach((item, index) => {
          data[index].unshift(item.name);
        });
      }
      let filename = this.title;
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    onGetTitle() {
      if (this.companyCurrentType == '1') {
        this.title = this.$t('Dashboard.PurchaseComparison')+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`);
      } else {
        this.title = this.$t('Dashboard.CustomerComparison')+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`);
      }
    },
    onGetAmount() {
      this.chartLoading = true;
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      const { 
        dateType, 
        time,  
        country, 
        customer, 
        itemNo,
        type,
        size  
      } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      supplierComparison({ 
        ...(dateType ? { dateType } : {}),
        startTime, 
        endTime, 
        country, 
        customer,
        itemNo,
        type,
        size,
        queryType: this.artConfig.isAmountNumberMode
      }).then(({data, statistics}) => {
        this.echartsData = data;
        this.setEchartsOption(data, this.data.dateType);
        this.chartLoading = false;
        this.countDatas = [{
          name: "",
          data: [
            [this.$t("Dashboard.maxValue"), statistics.max.value, statistics.max.keys.join("，")],
            [this.$t("Dashboard.minValue"), statistics.min.value, statistics.min.keys.join("，")],
            [this.$t("Dashboard.avgValue"), statistics.avg, `${startTime}~${endTime}`]
          ]
        }]
      }).finally(() => (this.chartLoading = false));
    },
    setEchartsOption(data, dateType) {
      let timeList = [];
      let seriesList = [];
      let customerArray = [];
      if(dateType) {
        let list = [];
        data.forEach((v) => {
          timeList.push(v.date);
          if (v.customerList) {
            let arr = v.customerList.map((item) => ({
              date: item.date,
              value: Number(item.amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2),
              name: item.aliasName || item.name
            }));
            list.push(...arr);
          }
        });
        let aggregate = _.groupBy(list, "name");
        for(let i in aggregate) {
          customerArray.push(i)
        }
        customerArray.forEach(customer => {
          seriesList.push({
            name: customer,
            data: []
          })
        })
        seriesList.forEach((item) => {
          timeList.forEach((time, idx) => {
            let data = list.filter(row => item.name == row.name && time == row.date)[0];
            item.data[idx] = data?.value || 0;
          });
        });
      } else {
        seriesList = data.map(item => {
          timeList.push(item.aliasName || item.name)
          return Number(item.amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2);
        })
      }
      const seriesMark = !dateType && this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {}
      this.myChart.setOption(
        {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            enterable: true, // 鼠标是否可进入提示框浮层中
            hideDelay: 60, // 浮层隐藏的延迟
            confine: true,
            formatter: (params) => {
              var htmlStr = '';
              for (var i = 0; i < params.length; i++) {
                if(params[i].value != 0) {
                  htmlStr += `
                    <div class="content">
                      ${params[i].marker}
                      <div class="value">${dateType ? params[i].seriesName : params[i].axisValue}：${params[i].value}${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}</div>
                    </div>
                  `;
                }
              }
              return `
                <div>
                  ${ !dateType ? "" : params[0].axisValue } 
                </div>
                <div class="echartsTooltipBox">
                  ${htmlStr}
                </div>
                ${dateType ? `<div class="echartsChatTotal">
                  total：${params.map(item => Number(item.value)).reduce(function (prev, cur) {
                      return prev + cur;
                  }, 0).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2)}
                </div>` : ''}
              `;
            }
          },
          grid: [
            {
              top: '5%',
              left: '3%',
              right: '3%',
              bottom: '14%',
              containLabel: true,
            },
          ],
          xAxis: [
            {
              type: 'category',
              data: timeList,
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLine: {
                lineStyle: { color: '#B0B8BF' },
              },
            },
            {
              gridIndex: 1,
              show: false,
            },
          ],
          yAxis: {
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
              show: false,
            },
          },
          dataZoom: [
            {
              show: true,
              type: 'slider',
              left: 'center',
              bottom: '0%',
              start: 0,
              end: 100,
            },
          ],
          series: dateType
            ? seriesList.map((vo) => ({
                ...vo,
                type: "bar",
                stack: "vistors",
                emphasis: {
                  focus: 'series'
                },
                ...seriesMark,
              }))
            : [{ data: seriesList, type: 'bar', barWidth: '60%', ...seriesMark }],
        },
        true
      );
      this.myChart.off('click');
      this.myChart.on('click', (params) => {
        if(!this.data.dateType) {
          const listItem = data[params.dataIndex];
          if(listItem?.companyId) {
            this.$confirm(this.$tc(
              'Dashboard.confirmSeeMessageFn', 
              listItem.aliasName || listItem.name
            ), this.$t('Tips'), {
              confirmButtonText: this.$t('Confirm'),
              cancelButtonText: this.$t('Cancel'),
              type: 'warning'
            }).then(() => {
              let companyId = listItem.companyId;
              this.$router.push(`/dashboard/2?countryId=${companyId}`);
            }).catch(() => {});
          }
        }
      });
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>
.conparisonChartBox {
  width: 100%;
  height: 350px;
}
</style>
