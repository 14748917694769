<template>
  <el-card 
    class="Commodity" 
    :class="isVipMode != 0 ? 'vipCard' : ''" 
    v-loading="chartLoading">
    <div class="left_title" style="cursor: auto;">
      <div class="title">{{ $t("Dashboard.statisticsCustomerMarket") }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf()">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
          </template>
        </toolTipIcon>
      </div>
    </div>
    <div id="exportCustomerStatisticsPdf" class="countryInfoBox" v-if="topData">
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.CompanyName') }}</div>
        <div class="content line1">
          {{ topData.aliasName }}
        </div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.CompanyEmail') }}</div>
        <div class="content line1">{{topData.email}}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.CompanyContact') }}</div>
        <div class="content line1">{{topData.contact}}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.VATNO') }}</div>
        <div class="content line1">{{topData.vat}}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.Country') }}</div>
        <div class="content line1">{{topData.country}}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.Address') }}</div>
        <div class="content line2" :title="topData.address">{{topData.address}}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{ $t('Dashboard.Website') }}</div>
        <div class="content line1">{{topData.website}}</div>
      </div>
      <div class="customerImage">
        <el-image :src="topData.companyLogoUrl" fit="cover" />
      </div>
    </div>
  </el-card>
</template>
<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { getCompanyEmployeeList } from "@/api/customer";
export default {
  name: "statistics",
  data() {
    return {
      echartsViewId: "exportCustomerStatisticsPdf",
      customerValue: "",
      exportLoading: false,
      topData: null,
      chartLoading: false,
      year: dayjs().format("YYYY") - 1,
      season: Math.ceil(dayjs().format("MM") / 3) - 1 || 4,
      mapImageComputed: ''
    };
  },
  inject: ['getConfig'],
  watch: {
    customerValue(val) {
      this.getCustomerData(val)
    },
  },
  mounted() {
    // this.onBasicStatistics();
  },
  computed: {
    ...mapGetters(["country"]),
    isVipMode() {
      return this.getConfig().isVipMode;
    }
  },
  methods: {
    getCustomerData(val) {
      console.log(val)
      getCompanyEmployeeList(val).then((data) => {
        if (!data) return;
        this.topData = data;
      }).catch((error) => {});
    },
    //导出(pdf)
    savePdf() {
      this.$refs.echartsBtns.style.display = "none";
      setTimeout(() => {
        // setAttribute 需要一定时间生效
        html2canvas(document.getElementById("exportCustomerStatisticsPdf"), {
          useCORS: true
        }).then((canvas) => {
          this.$refs.echartsBtns.style.removeProperty('display');
          const oImg = new Image();
          oImg.setAttribute('crossOrigin', 'anonymous')
          oImg.src = canvas.toDataURL(); // 导出图片
          let dataURL = canvas.toDataURL("image/png");
          // 下载图片
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = oImg.src;
          // 设置下载标题
          a.download = "我的文件";
          a.click();
        });
      }, 200);
    },
  },
};
</script>
<style lang="less" scoped>
.customerImage {
  width: 100%;
  height: 120px;
  background: #F7F9FC;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  .el-image {
    width: auto;
    height: 100%;
  }
}
</style>
