<template>
  <el-dialog 
    v-if="contrastConfig.length"
    :title="`${contrastConfig[0].title}-${contrastConfig.length > 1 ? contrastConfig[1].title : ''}`"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
    v-move-outside>
    <el-date-picker
      style="width: 260px"
      v-model="data.time"
      size="small"
      type="daterange"
      :start-placeholder="$t('Dashboard.Startfrom')"
      :end-placeholder="$t('Dashboard.Endoftime')"
      :picker-options="pickerData"
    />
    <div id="main1" class="echartsBox"></div>
	  <div id="main2" class="echartsBox"></div>
  </el-dialog>
</template>

<script>
import dayjs from "dayjs";
import * as echarts from "echarts";
import { pickerOptions } from "@/utils/utils";
import { mapGetters } from "vuex";

export default {
  name: "contrastDialog",
  data() {
    return {
      myChart: null,
      dialogVisible: false,
      data: {
        time: [dayjs().startOf('year'), dayjs()],
      },
      contrastConfig: []
    }
  },
  inject: ['getConfig'],
  computed: {
    ...mapGetters(["country"]),
    companyType() {
      return this.getConfig().companyType;
    },
    pickerData() {
      return pickerOptions();
    },
  },
  watch: {
    data: {
      handler() {
        this.getEcharts();
      },
      deep: true
    },
    dialogVisible(val) {
      if(val) {
        this.$nextTick(() => {
          this.getEcharts()
        })
      }
    }
  },
  methods: {
    getEcharts() {
      this.contrastConfig.forEach((item, index) => {
        this.$echartsInit(document.getElementById("main"+(index+1))).dispose();
        const { time } = this.data;
        let startTime = time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD') : '';
        let endTime = time && time?.length ? dayjs(time[1]).format('YYYY-MM-DD') : '';
        startTime = startTime+' 00:00:00';
        endTime = endTime+' 23:59:59';
        let myChart = this.$echartsInit(document.getElementById("main"+(index+1)));
        let params = {}
        if(params) {
          params = item.params
        }
        item.echartsApi({startTime, endTime, ...params}).then((respone) => {
          let data = Array.isArray(respone) ? respone : respone.data;
          if(item.name === 'bdiIndexNumber' || item.name === 'commodityPrice') {
            let arr = [];
            data.forEach((vo) => arr.push(...vo.data));
            let aggregate = _.groupBy(arr, 'code');
            let seriesList = [];
            for (let index in aggregate) {
              seriesList.push({
                name: index,
                data: aggregate[index].map((v) => {
                  return {
                    name: v.name,
                    value: v.value,
                  };
                }),
              });
            }
            item.options.grid[0].right = '0'
            item.options.grid[0].top = '30'
            item.options.grid[0].left = '10%'
            item.options.grid[0].bottom = '15%'
            item.options.grid[0].containLabel = false
            // item.options.legend = null
            item.options.dataZoom = null
            item.options.xAxis[0].data = data.map((vo) => dayjs(vo.date).format('YYYY-MM-DD'))
            item.options.series = seriesList.map((vo) => {
              return {
                ...vo,
                type: 'line',
                smooth: true,
                symbol: 'none',
                // areaStyle: {},
                showSymbol: false,
                animationEasing: 'cubicInOut',
              };
            })
            myChart.setOption(item.options)
          }
          if(item.name === 'purchasAmount') {
            let amountList = [];
            let dateList = [];
            data?.forEach((v, i) => {
              if (this.companyType === '2') {
                amountList.push(Number(v.orderNumber).toFixed(params.queryType ? 0 : 2));
              } else {
                amountList.push(Number(v.amount).toFixed(params.queryType ? 0 : 2));
              }
              dateList.push(v.date);
            });
            item.options.grid[0].right = '0'
            item.options.grid[0].top = '10'
            item.options.grid[0].left = '10%'
            item.options.grid[0].bottom = '15%'
            item.options.grid[0].containLabel = false
            item.options.legend = null
            item.options.dataZoom = null
            item.options.xAxis[0].data = dateList.length ? dateList : ['No information'];
            item.options.series[0].data = amountList.length ? amountList : ['0'];
            myChart.setOption(item.options)
          }
          if(item.name === 'pmiIndexNumber') {
            let arr = [];
            let shis = data.filter(item => item.data.length > 0)[0];
            data.forEach(item => {
              if(!item.data.length) {
                item.data = shis.data.map(row => ({
                  ...row,
                  index: null
                }))
              }
            })
            data.forEach((vo) => {
              arr.push(...vo.data)
            });
            let aggregate = _.groupBy(arr, 'nameCn');
            let seriesList = [];
            for (let index in aggregate) {
              seriesList.push({
                name: index,
                data: aggregate[index]?.map((v) => {
                  return v?.index;
                }),
              });
            }
            item.options.grid[0].right = '0'
            item.options.grid[0].top = '30'
            item.options.grid[0].left = '10%'
            item.options.grid[0].bottom = '15%'
            item.options.grid[0].containLabel = false
            item.options.dataZoom = null
            item.options.xAxis[0].data = data.map((vo) => {
              return dayjs(vo.updateDate).format(this.updateFreq === '1' ? 'YYYY.MM.DD' : 'YYYY.MM');
            });
            item.options.series = seriesList.map((vo) => {
              return {
                ...vo,
                type: 'line',
                smooth: true,
                symbol: 'none',
                showSymbol: false,
                connectNulls: true,
                animationEasing: 'cubicInOut'
              };
            });
            myChart.setOption(item.options)
          }
          if(item.name === 'supplierComparison') {
            let timeList = [];
            let seriesList = [];
            let customerArray = [];
            if(params.dateType) {
              let list = [];
              data.forEach((v) => {
                timeList.push(v.date);
                if (v.customerList) {
                  let arr = v.customerList.map((item) => ({
                    date: item.date,
                    value: Number(item.amount).toFixed(params.queryType ? 0 : 2),
                    name: item.aliasName || item.name
                  }));
                  list.push(...arr);
                }
              });
              let aggregate = _.groupBy(list, "name");
              for(let i in aggregate) {
                customerArray.push(i)
              }
              customerArray.forEach(customer => {
                seriesList.push({
                  name: customer,
                  data: []
                })
              })
              seriesList.forEach((item) => {
                timeList.forEach((time, idx) => {
                  let data = list.filter(row => item.name == row.name && time == row.date)[0];
                  item.data[idx] = data?.value || 0;
                });
              });
            } else {
              seriesList = data.map(item => {
                timeList.push(item.aliasName || item.name)
                return Number(item.amount).toFixed(params.queryType ? 0 : 2);
              })
            }

            item.options.grid[0].right = '0'
            item.options.grid[0].top = '10'
            item.options.grid[0].left = '10%'
            item.options.grid[0].bottom = '15%'
            item.options.grid[0].containLabel = false
            item.options.legend = null
            item.options.dataZoom = null
            item.options.xAxis[0].data = timeList;
            item.options.series = params.dateType
              ? seriesList.map((vo) => ({
                  ...vo,
                  type: "bar",
                  stack: "vistors",
                  emphasis: {
                    focus: 'series'
                  }
                }))
              : [{ data: seriesList, type: 'bar', barWidth: '60%' }];
            myChart.setOption(item.options)
          }
          if(item.name === 'exchangeRate') {
            const currencyList = data.currencyList;
            const datas = data.data;
            let aggregate = _.groupBy(datas, 'currency');
            let list = [];
            for (let index in aggregate) {
              if (index != 'null') {
                list.push({
                  name: index,
                  data: aggregate[index].map((res) => {
                    return [
                      dayjs(res.date).format('YYYY-MM-DD'),
                      Number(res.exchangeRate).toFixed(2),
                    ];
                  }),
                });
              }
            }
            item.options.grid[0].right = '0'
            item.options.grid[0].top = '30'
            item.options.grid[0].left = '10%'
            item.options.grid[0].bottom = '15%'
            item.options.grid[0].containLabel = false
            item.options.dataZoom = null
            item.options.legend.data = currencyList;
            item.options.series = list.map((res) => {
              return {
                ...res,
                type: 'line',
                smooth: true,
                symbol: 'none',
                // areaStyle: {},
                showSymbol: false,
                animationEasing: 'cubicInOut',
              };
            });
            myChart.setOption(item.options)
          }
          if(item.name === 'exportData') {
            let datas = data.map(item => ({
              ...item,
              countryName: this.$isFindData(this.country, item.iso3, 'value', 'key')
            }));

            let timeList = [];
            let seriesList = [];
            let countryArray = [];
            if (params.dateType) {
              let list = [];
              datas.forEach((v) => {
                timeList.push(v.date);
                if (v.countryList) {
                  v.countryList = v.countryList.map((item) => ({
                    ...item,
                    countryName: this.$isFindData(
                      this.country,
                      item.country,
                      "value",
                      "key"
                    ),
                  }));
                  list.push(...v.countryList);
                }
              });
              let aggregate = _.groupBy(list, "countryName");
              for(let i in aggregate) {
                countryArray.push(i)
              }
              countryArray.forEach(country => {
                seriesList.push({
                  name: country,
                  data: []
                })
              })
              seriesList.forEach((item) => {
                timeList.forEach((time, idx) => {
                  let data = list.filter(row => item.name == row.countryName && time == row.date)[0];
                  item.data[idx] = data?.amount || 0;
                });
              });
            } else {
              seriesList = datas.map((v) => {
                timeList.push(v.countryName);
                return Number(v.amount).toFixed(params.queryType ? 0 : 2);
              });
            }

            item.options.grid[0].right = '0'
            item.options.grid[0].top = '10'
            item.options.grid[0].left = '10%'
            item.options.grid[0].bottom = '15%'
            item.options.grid[0].containLabel = false
            item.options.legend = null
            item.options.dataZoom = null
            item.options.xAxis[0].data = timeList;
            item.options.series = params.dateType
              ? seriesList.map((vo) => {
                  return {
                    ...vo,
                    stack: 'vistors',
                    type: 'bar',
                    barWidth: '60%'
                  };
                })
              : [{ data: seriesList, type: 'bar', barWidth: '60%' }];
            myChart.setOption(item.options)
          }
          if(item.name === 'skuUnitPrice') {
            let arr = [];
            let currencyList = [];
            let list = [];
            data.forEach((vo) => {
              arr.push({
                ...vo,
                id: vo.itemNo+' | '+vo.type+' | '+vo.size
              });
            });
            let aggregate = _.groupBy(arr, 'id');
            for (let index in aggregate) {
              currencyList.push(index);
              list.push({
                name: index,
                data: aggregate[index].map((res) => {
                  return [dayjs(res.date).format('YYYY-MM-DD'), res.unitPrice];
                }),
              });
            }
            let timeArr = list.map(item => {
              return item.data.map(row => row[0])
            })
            let xAixsArray = [];
            if(data.length) {
              xAixsArray = timeArr.reduce((a, b) => a.concat(b));
            }
            let xAixsSort = xAixsArray.sort((a, b) => Date.parse(a) - Date.parse(b))
            item.options.grid[0].right = '0'
            item.options.grid[0].top = '40'
            item.options.grid[0].left = '10%'
            item.options.grid[0].bottom = '15%'
            item.options.grid[0].containLabel = false
            item.options.dataZoom = null
            item.options.legend.data = currencyList;
            item.options.xAxis[0].data = [...new Set(xAixsSort)];
            item.options.series = list.map((res) => {
              return {
                ...res,
                type: 'scatter',
                symbolSize: 8
              };
            });
            myChart.setOption(item.options)
          }
        })
      })
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.echartsBox {
  width: 100%;
  height: 250px;
}
/deep/.el-dialog__body {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  .el-date-editor {
    margin-bottom: 15px;

  }
}
</style>