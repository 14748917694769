<template>
  <el-card
    class="Commodity"
    :ref="captureId"
    v-loading="exportLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">
        {{ $t("Dashboard.SKU(Amount)") }}
        <div class="echartsTypeTabs" v-if="artConfig.isVipMode == 0">
          <div class="item" :class="isPieChart ? 'active' : ''" @click="isPieChart = true">
            <i class="iconfont icon-shujukanban1"></i>
          </div>
          <div class="item" :class="!isPieChart ? 'active' : ''" @click="isPieChart = false">
            <i class="iconfont icon-shujukanban"></i>
          </div>
        </div>
        <el-tooltip v-if="artConfig.isVipMode == 0" class="item" effect="dark" placement="top">
          <i class="el-icon-info"></i>
          <template #content>
            数量：例如(10)，那么展示sku总额占比前10的sku型号的金额信息
            <br />
            比例：例如(10)，那么展示sku总额占比超过10%的sku型号的金额信息
          </template>
        </el-tooltip>
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <div 
          class="screen" 
          style="margin-top: 0;margin-right: 10px;"
          v-if="artConfig.isVipMode != 0">
          <el-input-number
            v-model="skuNumberValue" 
            size="small" 
            controls-position="right" 
            style="width: 120px" 
            :min="0"
            ref="skuNumber"
            :placeholder="$t('Dashboard.quantity')"
            @blur="() => {
              skuProportionValue = undefined
              data.skuProportion = ''
              data.skuNumber = skuNumberValue
            }"
            @keyup.enter.native="() => {
              $refs.skuNumber.$refs.input.blur();
            }" 
          />
          <el-input-number
            v-model="skuProportionValue" 
            size="small" 
            controls-position="right" 
            style="width: 120px" 
            ref="skuProportion"
            :placeholder="$t('Dashboard.Proportion')+'%'"
            @blur="(e) => {
              skuNumberValue = undefined
              data.skuNumber= 0
              data.skuProportion = skuProportionValue
            }"
            @keyup.enter.native="() => {
              $refs.skuProportion.blur();
            }"
          />
        </div>
        <toolTipIcon v-if="artConfig.isVipMode == 0" icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onskuAmountList()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen" v-if="artConfig.isVipMode == 0">
      <el-input-number
        v-model="skuNumberValue" 
        size="small" 
        controls-position="right" 
        style="width: 120px" 
        :min="0"
        ref="skuNumber"
        :placeholder="$t('Dashboard.quantity')"
        @blur="() => {
          skuProportionValue = undefined
          data.skuProportion = ''
          data.skuNumber = skuNumberValue
        }"
        @keyup.enter.native="() => {
          $refs.skuNumber.$refs.input.blur();
        }" 
      />
      <el-input-number
        v-model="skuProportionValue" 
        size="small" 
        controls-position="right" 
        style="width: 120px" 
        ref="skuProportion"
        :placeholder="$t('Dashboard.Proportion')+'%'"
        @blur="(e) => {
          skuNumberValue = undefined
          data.skuNumber= 0
          data.skuProportion = skuProportionValue
        }"
        @keyup.enter.native="() => {
          $refs.skuProportion.blur();
        }"
      />
      <el-date-picker style="width: 260px" type="daterange" v-model="data.time" size="small"
        :start-placeholder="$t('Dashboard.Startfrom')" :end-placeholder="$t('Dashboard.Endoftime')"
         :picker-options="pickerData" />
    </div>
    <div style="position: relative;margin-top: 22px;">
      <template v-if="artConfig.isVipMode == 0">
        <div
          :id="echartsViewPieId"
          class="skuAmountHistogram"
          :style="`height: ${
            enlarge == false ? '300px' : '70vh'
          }; opacity: ${isPieChart ? '1' : '0'}; z-index: ${
            isPieChart ? '2' : '1'
          }; position: absolute;`"
        />
        <ul
          :class="enlarge ? 'enlarge_statistics' : 'statistics'"
          :style="`opacity: ${isPieChart ? '1' : '0'}; z-index: 0;`"
        >
          <li>
            <div class="title">{{$t('Dashboard.Total')}}</div>
            <span>{{ chartTotal }}</span>
          </li>
          <li>
            <div class="title">{{ $t('Dashboard.ProductNumber') }}</div>
            <span>{{ numberCountry }}</span>
          </li>
          <li>
            <div class="title">{{ $t('Dashboard.Proportion') }}</div>
            <span>{{ skuProportion }}%</span>
          </li>
        </ul>
        <div
          :id="echartsViewBarId"
          class="skuAmountHistogram"
          :style="`height: ${
            enlarge == false ? '300px' : '70vh'
          }; opacity: ${!isPieChart ? '1' : '0'}; z-index: ${
            !isPieChart ? '2' : '1'
          };`"
        />
      </template>
      <div class="chatFlex" :class="enlarge ? 'noFlex' : ''" v-else>
        <div
          :id="echartsViewBarId"
          class="Bar"
          :style="`height: ${
            enlarge == false ? '300px' : '70vh'
          };`"
        />
        <div class="Pie">
          <div
            :id="echartsViewPieId"
            class="skuAmountHistogram"
            :style="`height: ${
              enlarge == false ? '300px' : '70vh'
            };`"
          />
          <ul :class="enlarge ? 'enlarge_statistics' : 'statistics'">
            <li>
              <div class="title">{{$t('Dashboard.Total')}}</div>
              <span>{{ chartTotal }}</span>
            </li>
            <li>
              <div class="title">{{ $t('Dashboard.ProductNumber') }}</div>
              <span>{{ numberCountry }}</span>
            </li>
            <li>
              <div class="title">{{ $t('Dashboard.Proportion') }}</div>
              <span>{{ skuProportion }}%</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import * as echarts from "echarts";
import html2canvas from "html2canvas";
import { skuAmount } from "@/api/chart";
import { formatNum, pickerOptions, new_date } from "@/utils/utils";
import { orderBy } from "lodash";
export default {
  name: 'skuAmount',
  components: {},
  data() {
    return {
      chart: null,
      chartTwo: null,
      echartsViewPieId: 'skuAmountPieChart_',
      echartsViewBarId: 'echartsViewBarId_',
      captureId: 'captureEghte_',
      enlarge: false,
      exportLoading: false,
      chartTotal: 0,
      isPieChart: true,
      numberCountry: 0,
      skuProportion: 0,
      skuNumberValue: undefined,
      skuProportionValue: undefined,
      skuAmountData: [],
      data: {
        country: null,
        customer: '',
        itemNo: '',
        type: '',
        size: '',
        time: [dayjs().startOf('year'), dayjs()],
        skuNumber: undefined,
        skuProportion: ''
      },
    };
  },
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  inject: ['getConfig'],
  computed: {
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj, oldObj) {
        if(this.$route.name != 'Dashboard') return;
        this.exportLoading = true;
        this.onskuAmountList();
      },
      deep: true,
    },
    enlarge() {
      this.onPieChart(this.skuAmountData);
      this.onHistogram(this.skuAmountData);
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.chart.resize();
        this.chartTwo.resize();
      }, 1);
    }
  },
  mounted() {
    this.echartsViewPieId = this.echartsViewPieId + this.echartsType;
    this.echartsViewBarId = this.echartsViewBarId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.chart) {
      this.chart.resize();
    }
    if(this.chartTwo) {
      this.chartTwo.resize();
    }
  },
  methods: {
    onEnlarge() {
      if (!this.enlarge) {
        this.enlarge = true;
        document
          .getElementById(this.captureId)
          .classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.chart.resize();
        this.chartTwo.resize();
      }, 1);
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            this.exportLoading = false;
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.$t("Dashboard.SKU(Amount)");
            a.click();
          });
        }, 200);
        return;
      }
      if(!this.isPieChart) {
        let chartOption = this.chartTwo.getOption();
        let header = ["", this.$t("Dashboard.SKU(Amount)")];
        let data = chartOption.series[0].data.map((item, index) => [chartOption.yAxis[0].data[index], item]);
        let filename = this.$t("Dashboard.SKU(Amount)");
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      } else {
        let chartOption = this.chart.getOption();
        let header = ["", this.$t("Dashboard.SKU(Amount)")+this.$t("Dashboard.Proportion")];
        let data = chartOption.series[0].data.map(item => [item.namedialog, `${item.amountProportion}%:${item.value}`]);
        let filename = this.$t("Dashboard.SKU(Amount)");
        this.$export_json_to_excel({
          header,
          data,
          filename,
        });
      }
    },
    onskuAmountList() {
      this.exportLoading = true;
      const { 
        time, 
        skuNumber, 
        skuProportion, 
        country, 
        customer, 
        itemNo,
        type,
        size 
      } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      this.chart = this.$echartsInit(document.getElementById(this.echartsViewPieId));
      skuAmount({
        startTime,
        endTime,
        number: Number(skuNumber || (skuProportion ? 0 : 10)).toFixed(0),
        proportion: Number(skuProportion || 0).toFixed(0),
        country,
        customer,
        itemNo,
        type,
        size
      })
        .then((data) => {
          this.skuAmountData = data;
          this.onPieChart(this.skuAmountData);
          this.onHistogram(this.skuAmountData);
          this.exportLoading = false;
        })
        .finally(() => (this.exportLoading = false));
    },
    onPieChart(data = []) {
      let total = 0;
      let proportion = 0;
      let arr = orderBy(
        data,
        (v) => {
          return Number(v.amount);
        },
        'desc'
      )?.map((v) => {
        total += Number(v.amount);
        proportion += v.proportion;
        return {
          value: Number(v.amount),
          code: v.itemNo,
          amountProportion: v?.proportion,
          name: `${v?.size || ''} ${v?.type || ''} ${v?.itemNo} ${Number(
            v?.proportion
          ).toFixed(2)}%：${formatNum(Number(v?.amount).toFixed(2)) || "0"
            }(USD)`,
          namedialog: `${v?.itemNo}|${v?.size || ''}|${v?.type || ''}`
        };
      });
      let indexOfOther = arr.findIndex(item => item.code == '其他' || item.code == 'other')
      if(indexOfOther != -1) {
        arr.push(arr[indexOfOther]);
        arr.splice(indexOfOther, 1);
      }
      this.chartTotal = formatNum(Number(total).toFixed(2)) || '0';
      this.skuProportion = Number(proportion).toFixed(2);
      this.numberCountry = data?.length || 0;
      const chartData = !this.enlarge
        ? {
          labelLine: {
            show: false,
          },
          label: {
            show: false,
            position: "center",
          },
        }
        : {};
      this.chart.setOption(
        {
          tooltip: {
            trigger: 'item',
            confine: true,
            formatter: function (params) {
              var htmlStr = `
                <div class="echartsTooltipPieBox">
                  <div class="content">
                    <div class="label">${params.name.split("：")[0]}</div>
                    <div class="value">${params.name.split("：")[1]}</div>
                  </div>
                </div>
              `;
              return htmlStr;
            },
          },
          legend: {
            left: 'center',
            bottom: '0',
            type: 'scroll',
          },
          series: [
            {
              type: 'pie',
              left: '-25%',
              bottom: "15%",
              radius: '90%',
              avoidLabelOverlap: false,
              data: arr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
              ...chartData,
            },
          ],
        },
        true
      );
      this.chart.on("legendselectchanged", (params) => {
        let proportionValue = 0;
        let chartTotal = 0;
        arr.map((v) => {
          const { name, amountProportion, value } = v;
          for (let key in params?.selected) {
            if (name == key && params?.selected[key]) {
              proportionValue += Number(amountProportion);
              chartTotal += Number(value);
            }
          }
        });
        let objectis = Object.values(params.selected).filter((item) => item);
        this.numberCountry = objectis.length;
        this.skuProportion = proportionValue.toFixed(2);
        this.chartTotal = formatNum(Number(chartTotal).toFixed(2));
      });
      this.chart.off('click');
      this.chart.on('click', (params) => {
        if(this.artConfig.isVipMode == 1) return;
        if(params.data.code && params.data.code != 'other') {
          this.$confirm(this.$tc(
            'Dashboard.confirmSeeMessageFn',
            params.data.namedialog
          ), this.$t('Tips'), {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }).then(() => {
            this.$router.push(`/dashboard/3?productId=${params.data.namedialog}`);
          }).catch(() => {});
        }
      });
      // chart.setOption
      window.addEventListener("resize", () => this.chart.resize());
    },
    onHistogram(data) {
      this.chartTwo = this.$echartsInit(document.getElementById(this.echartsViewBarId));
      this.chartTwo.setOption({
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: [
          {
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '14%',
            containLabel: true,
          },
          {},
        ],
        xAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: { color: '#B0B8BF' },
            show: false,
          },
        },
        yAxis: [
          {
            type: 'category',
            splitNumber: 10,
            data: data.map((v) => {
              return `${v?.itemNo}|${v?.size}|${v?.type}:${Number(
                v?.proportion
              ).toFixed(2)}%`;
            }),
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLabel: {
              formatter: (value) => {
                if(!this.enlarge) {
                  return value.length > 20 ? value.slice(0, 20)+'...' : value;
                }
                return value;
              }
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
            },
            // triggerEvent: true
          },
          { gridIndex: 1, show: false },
        ],
        dataZoom: [
          {
            show: true,
            type: 'slider',
            left: 'center',
            bottom: '0%',
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            data: data.map((v) => {
              return Number(v?.amount);
            }).reverse(),
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: 2.64,
              },
            },
          },
        ],
      });
      this.chartTwo.off('click');
      this.chartTwo.on('click', (params) => {
        if(this.artConfig.isVipMode == 1) return;
        const name = params.name.split(":")[0];
        if(name != 'null' && name) {
          this.$confirm(this.$tc(
            'Dashboard.confirmSeeMessageFn',
            ""
          )+name, this.$t('Tips'), {
            confirmButtonText: this.$t('Confirm'),
            cancelButtonText: this.$t('Cancel'),
            type: 'warning'
          }).then(() => {
            this.$router.push(`/dashboard/3?productId=${name}`);
          }).catch(() => {});
        }
      });
      // this.chartTwo.on('mouseover', (params) => {
      //   console.log(params);

      // });
      // this.chartTwo.on('mouseout', (params) => {

      // });
      window.addEventListener("resize", () => this.chartTwo.resize());
    }
  },
};
</script>
<style lang="less" scoped>
.chatFlex {
  display: flex;
  justify-content: space-between;
  &.noFlex {
    display: block;
    .Pie {
      width: 100%;
      margin-top: 20px;
    }
    .Bar {
      width: 100%;
    }
  }
  .Pie {
    position: relative;
    width: 49%;
  }
  .Bar {
    width: 49%;
  }
}

#skuAmountPieChart {
  width: 100%;
  height: 350px;
}

.skuAmountHistogram {
  width: 100%;
  height: 350px;
}
</style>

<style lang="less">
.skuAmountLabel {
  cursor: pointer;
}
</style>
