import { render, staticRenderFns } from "./orderStatusSummary.vue?vue&type=template&id=54d464df&scoped=true&"
import script from "./orderStatusSummary.vue?vue&type=script&lang=js&"
export * from "./orderStatusSummary.vue?vue&type=script&lang=js&"
import style0 from "./orderStatusSummary.vue?vue&type=style&index=0&id=54d464df&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d464df",
  null
  
)

export default component.exports