<template>
  <div 
    class="pickerSelect" 
    :style="{
      'width': width
    }"
    @click.stop>
    <div 
      class="selectInputBox" 
      :class="{
        active: showPicker
      }" 
      @click="isShowPicker">
      <template v-if="type == 'year'">
        {{ values[0] }}{{ $t('Dashboard.Yearly') }}
        <div class="center">VS</div>
        {{ values[1] }}{{ $t('Dashboard.Yearly') }}
      </template>
      <template v-if="type == 'seasonSame' || type == 'seasonRing'">
        {{ values[0] }}{{ $t('Dashboard.Yearly') }}Q{{ values[1] }}
        <div class="center">VS</div>
        {{ values[2] }}{{ $t('Dashboard.Yearly') }}Q{{ values[3] }}
      </template>
      <i class="arrow iconfont icon-a-Polygon3"></i>
    </div>
    <div class="pickerBox" :class="showPicker ? 'active' : ''">
      <div class="flexBox">
        <div class="left">
          <swiper
            ref="mySwiperLeft"
            :options="{
              direction : 'vertical',
              slidesPerView : 3,
              spaceBetween: 4,
              centeredSlides: true
            }"
            @slideChange="slideLeftChange"
          >
            <swiper-slide
              v-for="(item, index) in yearArray"
              :key="index"
              @click.native="goLeftItem(index)"
            >
              {{ item }}
            </swiper-slide>
          </swiper>
          <swiper
            v-if="type == 'seasonSame' || type == 'seasonRing'"
            ref="mySwiperSeasonLeft"
            :options="{
              direction : 'vertical',
              slidesPerView : 3,
              spaceBetween: 4,
              centeredSlides: true
            }"
            @slideChange="slideSeasonLeftChange"
          >
            <swiper-slide
              v-for="(item, index) in seasonOptions"
              :key="index"
              @click.native="goLeftItem(index)"
            >
              Q{{ item }}
            </swiper-slide>
          </swiper>
        </div>
        <div class="center">VS</div>
        <div class="right">
          <swiper
            ref="mySwiperRight"
            :options="{
              direction : 'vertical',
              slidesPerView : 3,
              spaceBetween: 4,
              centeredSlides: true
            }"
            @slideChange="slideRightChange"
          >
            <swiper-slide
              v-for="(item, index) in yearArray.filter(item => Number(item) >= values[0])"
              :key="index"
              @click.native="goRightItem(index)"
            >
              {{ item }}
            </swiper-slide>
          </swiper>
          <swiper
            v-if="type == 'seasonSame' || type == 'seasonRing'"
            ref="mySwiperSeasonRight"
            :options="{
              direction : 'vertical',
              slidesPerView : 3,
              spaceBetween: 4,
              centeredSlides: true
            }"
            @slideChange="slideSeasonRightChange"
          >
            <swiper-slide
              v-for="(item, index) in seasonOptions"
              :key="index"
              @click.native="goLeftItem(index)"
            >
              Q{{ item }}
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pickerSelect",
  props: {
    type: {
      type: String,
      default: "year"
    },
    valueQuarter: {
      type: Array,
      default: null
    },
    hideBack: {
      type: Boolean,
      default: false
    },
    width: {
      type: String | Number,
      default: "100%"
    }
  },
  data() {
    return {
      values: [],
      showPicker: false,
      yearArray: this.$getGradeData(1960),
      seasonOptions: [1,2,3,4],
    }
  },
  mounted() {
    this.$refs.mySwiperLeft.swiper.slideTo(2);
    this.$refs.mySwiperRight.swiper.slideTo(1);
    window.addEventListener('click', (e) => {
      this.showPicker = false;
    })
  },
  watch: {
    valueQuarter: {
      handler(val) {
        this.values = JSON.parse(JSON.stringify(this.valueQuarter));
      },
      deep: true,
      immediate: true
    },
    showPicker(val) {
      if(!val) {
        this.$emit("update:valueQuarter", this.values);
      } else {
        this.$nextTick(() => {
          this.$refs.mySwiperLeft.swiper.slideTo(this.yearArray.findIndex(item => item == this.values[0]));
          if(this.type == 'year') {
            this.$refs.mySwiperRight.swiper.slideTo(this.yearArray.findIndex(item => item == this.values[1]));
          } else {
            this.$refs.mySwiperSeasonRight.swiper.slideTo(this.seasonOptions.findIndex(item => item == this.values[3]));
            this.$refs.mySwiperSeasonLeft.swiper.slideTo(this.seasonOptions.findIndex(item => item == this.values[1]));
            this.$refs.mySwiperRight.swiper.slideTo(this.yearArray.findIndex(item => item == this.values[2]));
          }
        })
      }
    }
  },
  methods: {
    clear() {
      this.$emit("update:valueQuarter", [new Date().getFullYear() - 1, new Date().getFullYear()]);
      this.$refs.mySwiperLeft.swiper.slideTo(2);
      this.$refs.mySwiperRight.swiper.slideTo(1);
    },
    isShowPicker() {
      this.showPicker = !this.showPicker;
    },
    goLeftItem(index) {
      this.$refs.mySwiperLeft.swiper.slideTo(index);
    },
    goRightItem(index) {
      this.$refs.mySwiperRight.swiper.slideTo(index);
    },
    slideSeasonLeftChange() {
      this.values[1] = this.seasonOptions[this.$refs.mySwiperSeasonLeft.swiper.activeIndex];
    },
    slideSeasonRightChange() {
      this.values[3] = this.seasonOptions[this.$refs.mySwiperSeasonRight.swiper.activeIndex];
    },
    slideLeftChange() {
      this.values[0] = this.yearArray[this.$refs.mySwiperLeft.swiper.activeIndex];
    },
    slideRightChange() {
      if(this.type == 'year') {
        this.values[1] = this.yearArray[this.$refs.mySwiperRight.swiper.activeIndex]
      } else {
        this.values[3] = this.yearArray[this.$refs.mySwiperRight.swiper.activeIndex]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pickerSelect {
  position: relative;
}
.selectInputBox {
  width: 100%;
  height: 32px;
  background: #F7F9FC;
  border-radius: 4px;
  cursor: pointer;
  transition: border .2s;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 14px;
  font-size: 12px;
  font-weight: 600;
  color: #637381;
  position: relative;
  white-space: nowrap;
  .arrow {
    position: absolute;
    right: 10px;
    font-size: 12px;
    transform: scale(.4) rotate(180deg);
    transition: all .2s;
  }
  .center {
    color: #076F49;
  }
  &.active {
    border: 1px solid #C7DDD8;
    .arrow {
      transform: scale(.4) rotate(0deg);
    }
  }
}
.pickerBox {
  // width: 228px;
  height: 0;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #ffffff;
  position: fixed;
  z-index: 1;
  margin-top: 10px;
  transition: all .2s;
  overflow: hidden;
  padding: 0;
  opacity: 0;

  &.active {
    height: 115px;
    padding: 8px;
    opacity: 1;
  }
  .flexBox {
    display: flex;
    align-items: center;
    gap: 10px;
    .left {
      display: flex;
      gap: 4px;
    }
    .right {
      display: flex;
      gap: 4px;
    }
    .center {
      font-size: 12px;
      font-weight: 600;
      line-height: normal;
      color: #076F49;
    }
  }
  /deep/ .swiper-container {
    height: 99px;
  }
  /deep/ .swiper-slide {
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 22px;
    font-size: #122545;
    border-radius: 4px;
    cursor: pointer;
    &.swiper-slide-active {
      background: #F7F9FC;
      font-weight: 700;
    }
  }
}
</style>

<style lang="less">
.pickerOption {
  width: 228px;
  height: 144px;
}
</style>