<template>
  <div>
    <div class="basticInfo">
      <statistics ref="statisticsCountryMarket" type="country" />
      <countryStatistics ref="countryStatistics" />
    </div>
    <div class="Statistical_cart_box">
      <div class="top">
        <supplierVipData ref="componentTotal1" echartsType="1" />
        <supplierAmount ref="componentTotal2" echartsType="1" />
      </div>
      <div class="bottom">
        <exportProportion ref="componentTotal3" echartsType="1" />
        <skuAmount ref="componentTotal4" echartsType="1" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCountryDetail } from "@/api/chart";
import purchasAmount from '../components/purchasAmount.vue';
import statistics from '../components/statisticsVipCountryMarket.vue';
import countryStatistics from "../components/countryStatistics.vue"
import skuUnitPrice from '../components/skuUnitPrice.vue';
import skuAmount from '../components/skuAmount.vue';
import exchangeRate from '../components/exchangeRate.vue';
import commodityPrice from '../components/commodityPrice.vue';
import exportData from '../components/exportData.vue';
import exportProportion from '../components/exportProportion.vue';
import supplierAmount from '../components/supplierAmount.vue';
import supplierConparison from '../components/supplierConparison.vue';
import bdiIndexNumber from "../components/bdiIndexNumber.vue";
import orderStatusSummary from "../components/orderStatusSummary.vue";
import pmiIndexNumber from "../components/pmiIndexNumber.vue";
import supplierVipData from "../components/supplierVipData.vue";

export default {
  name: 'dashboardCountry',
  components: {
    purchasAmount,
    statistics,
    countryStatistics,
    supplierConparison,
    supplierAmount,
    exportData,
    exportProportion,
    commodityPrice,
    exchangeRate,
    skuAmount,
    skuUnitPrice,
    bdiIndexNumber,
    orderStatusSummary,
    pmiIndexNumber,
    supplierVipData
  },
  data() {
    return {
      dashboardList: [11, 5, 8, 9],
      dashboardObject: {
        0: pmiIndexNumber,
        1: orderStatusSummary,
        2: bdiIndexNumber,
        3: commodityPrice,
        4: supplierConparison,
        5: supplierAmount,
        6: exchangeRate,
        7: exportData,
        8: exportProportion,
        9: skuAmount,
        10: skuUnitPrice,
        11: supplierVipData
      }
    }
  },
  inject: ['getConfig', 'getTimeConfig'],
  computed: {
    ...mapGetters([]),
    actConfig() {
      return this.getConfig();
    },
    timeConfig() {
      return this.getTimeConfig();
    },
  },
  mounted() {
    this.setCountryValue(this.actConfig.countryId);
  },
  watch: {
    'actConfig.countryId'(val, old) {
      this.setCountryValue(val, this.actConfig.companyType)
    },
    'timeConfig.globalTimeCountry': {
      handler(val) {
        this.$nextTick(() => {
          this.setTime(val, true);
        })
      },
      deep: true
    }
  },
  methods: {
    setTime(val, globalTime) {
      if(globalTime) {
        this.$refs.statisticsCountryMarket.data.time = val;
      }
      this.$refs.componentTotal1.data.time = val;
      this.$refs.componentTotal2.data.time = val;
      this.$refs.componentTotal3.data.time = val;
      this.$refs.componentTotal4.data.time = val;
    },
    setCountryValue(countryId) {
      getCountryDetail(countryId).then((data) => {
        if(data) {
          this.$nextTick(() => {
            this.setTime(this.timeConfig.globalTimeCountry, false);
            this.$refs.statisticsCountryMarket.data = {
              ...this.$refs.statisticsCountryMarket.data,
              country: countryId,
              time: this.timeConfig.globalTimeCountry
            };
            this.$refs.countryStatistics.topData = data;
            this.$refs.componentTotal1.data.country = countryId;
            this.$refs.componentTotal2.data.country = countryId;
            this.$refs.componentTotal3.data.country = countryId;
            this.$refs.componentTotal4.data.country = countryId;
          })
          return;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.empty_box {
  margin: 20px;
}
</style>