import request from '@/utils/request';
import axios from "axios";
//首页订单圆形图表
export function orderChart(params) {
    return request({
        url: '/dashboard/customerOrderCount',
        method: 'get',
        params,
    });
}
// 数据统计表
export function basicStatistics(params) {
    return request({
        url: '/dashboard/basicStatistics',
        method: 'get',
        params,
    });
}
// 订单金额年同比
export function basicYearStatistics(params) {
    return request({
        url: '/dashboard/orderAmountYearOnYear',
        method: 'get',
        params,
    });
}
// 订单金额季度同比
export function basicSeasonStatistics(params) {
    return request({
        url: '/dashboard/orderAmountQuarterOnQuarter',
        method: 'get',
        params,
    });
}
//Monthly Purchas Amount图表
export function getAmount(params) {
    return request({
        url: '/dashboard/monthlyPurchasingAmount',
        method: 'get',
        params,
    });
}
//Supplier Conparison图表
export function supplierComparison(params) {
    return request({
        url: '/dashboard/supplierComparison',
        method: 'get',
        params,
    });
}
//Supplier(Amount)图表
export function supplierAmount(data) {
    return request({
        url: '/dataKanban/supplierAmount',
        method: 'post',
        data: data,
    });
}
//Export data图表
export function exportData(params) {
    return request({
        url: '/dashboard/exportData',
        method: 'get',
        params,
    });
}
//Commodity Price图表
export function statisticalChart(params) {
    return request({
        url: '/manage/dashboard/mysteel',
        method: 'get',
        params,
    });
}
//Exchange rate图表
export function exchangeRate(params) {
    return request({
        url: '/manage/dashboard/exchangeRate',
        method: 'get',
        params,
    });
}
//SKU Amount图表
export function skuAmount(params) {
    return request({
        url: '/dashboard/skuAmount',
        method: 'get',
        params,
    });
}
//skuUnitPrice图表
export function skuUnitPriceList(data) {
    return request({
        url: '/dashboard/skuUnitPrice',
        method: 'post',
        data,
    });
}
//获取sku参数数据
export function getSkuParam(params) {
    return request({
        url: '/order/sku/pageParam',
        method: 'get',
        params,
    });
}
//公司基础统计信息
export function getCompanyBasicStatistics() {
    return request({
        url: '/file/companyStorageInfo',
        method: 'get',
    });
}
//公司各类型文件占用空间饼图
export function getFileSpacePie(params) {
    return request({
        url: '/file/companySpaceDistribution',
        method: 'get',
        params,
    });
}
//公司成员统计信息
export function getCompanyMemberStatistics(params) {
    return request({
        url: '/file/companyMemberFileInfo',
        method: 'get',
        params,
    });
}
//更改数据看板图表顺序
export function changeChartOrder(data) {
    return request({
        url: '/businessUser/updateChartOrder',
        method: 'post',
        data: data,
    });
}

// 获取国家详情
export function getCountryDetail(iso3) {
    return request({
        url: '/manage/country/detailByIso3',
        method: 'get',
        params: {
            iso3
        },
    });
}

// 获取国家对比
export function getCountryContrastDetail(data) {
    return request({
        url: '/dashboard/comparison/compareCountry',
        method: 'post',
        data,
    });
}

// 获取客户对比
export function getCompareVendorDetail(data) {
    return request({
        url: '/dashboard/comparison/compareCustomer',
        method: 'post',
        data,
    });
}

// 采购金额金额对比
export function getComparePurchasingAmount(data) {
    return request({
        url: '/dashboard/comparison/monthlyPurchasingAmount',
        method: 'post',
        data,
    });
}

// 采购对比
export function getCompareSupplierComparison(data) {
    return request({
        url: '/dashboard/comparison/supplierComparison',
        method: 'post',
        data,
    });
}

// 供应商金额对比
export function getCompareSupplierAmount(data) {
    return request({
        url: '/dashboardCompare/compareSupplierAmount',
        method: 'post',
        data: data,
    });
}

// sku金额对比
export function getCompareSkuAmountPrice(data) {
    return request({
        url: '/dashboard/comparison/skuAmount',
        method: 'post',
        data,
    });
}

// sku单价对比
export function getCompareSkuUnitPrice(data) {
    return request({
        url: '/dashboard/comparison/skuUnitPrice',
        method: 'post',
        data,
    });
}

// 出口数据对比
export function getCompareExportData(data) {
    return request({
        url: '/dashboard/comparison/exportData',
        method: 'post',
        data,
    });
}

// 客户top3
export function getCompanyTop3(params) {
    return request({
        url: '/dashboard/customerOrderTop',
        method: 'get',
        params,
    });
}

// 国家top3
export function getCountryTop3(params) {
    return request({
        url: '/dashboard/countryOrderTop',
        method: 'get',
        params,
    });
}

// 产品top3
export function getCiSkuTop3(params) {
    return request({
        url: '/dashboard/skuOrderTop',
        method: 'get',
        params,
    });
}

// 产品数据对比
export function getCompareSkuData(data) {
    return request({
        url: '/dashboard/comparison/compareSku',
        method: 'post',
        data,
    });
}

// 获取sku指标
export function getSkuSearchList(data) {
    return request({
        url: '/businessSku/getSku',
        method: 'post',
        data,
    });
}

// 生产物流分析
export function orderStatusSummary(params) {
    return request({
        url: '/dashboard/orderStatusSummary',
        method: 'get',
        params,
    });
}

// 生产物流分析对比
export function getCompareOrderStatusSummary(data) {
    return request({
        url: '/dashboard/comparison/orderStatusSummary',
        method: 'post',
        data,
    });
}

// pmi列表数据
export function getPmiListApi(params) {
    return request({
        url: '/manage/pmi/getList',
        method: 'get',
        params,
    }); 
}

// pmi数据分析
export function getPmiDataApi(params) {
    return request({
        url: '/manage/dashboard/getPmiIndex',
        method: 'get',
        params,
    }); 
}

// 获取平均最大最小数量
export function getCountData(data, type) {
    return request({
        url: `/dataKanbanCount/${type}`,
        method: 'POST',
        data,
    });
}

// 获取AI对话列表
export function getDialogueListApi(params) {
    return request({
        url: '/chatAiTopic/page',
        method: 'get',
        params,
    }); 
}

// 发送AI消息
export function sendEcholaAIMessage(data) {
    return request({
        url: `/chatAI/chat`,
        method: 'POST',
        data,
    });
}

// 获取聊天内容
export function getEcholaAIMessage(params) {
    return request({
        url: '/chatAiDialogue/page',
        method: 'get',
        params,
    }); 
}

// 删除对话
export function deleteDialogueApi(id) {
    return request({
        url: `/chatAiTopic/delete`,
        method: 'get',
        params: {
            id
        },
    });
}
