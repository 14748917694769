<template>
  <el-card class="Commodity" v-loading="chartLoading" :id="captureId">
    <div class="left_title">
      <div class="title">
        {{ $t("Dashboard.Exportdata")+(`(${!artConfig.isAmountNumberMode ? $t('order.Amount') : $t('order.Quantity')})`) }}
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon 
          :icon="contrastActive ? 'el-icon-minus' : 'el-icon-plus'" 
          :tooltipText="$t('addContrast')"
          @click="setContrast()"
        />
        <toolTipIcon icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onGetAmount()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen">
      <div class="left_screen">
        <div
          v-for="(item, index) in [
            { name: $t('Dashboard.Monthly'), title: '0' },
            {
              name: $t('Dashboard.Quarterly'),
              title: '1',
            },
            { name: $t('Dashboard.Yearly'), title: '2' },
          ]"
          :key="index"
          @click="onActive(item.title)"
          :class="`${data.dateType == item.title ? 'active' : ''}`"
        >
          {{ item.name }}
        </div>
      </div>
      <el-date-picker
        style="width: 230px"
        v-model="data.time"
        type="daterange"
        size="small"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
    </div>
    <div :style="`position: relative;margin-top: 22px;`">
      <!-- 柱形图 -->
      <div
        :id="echartsViewId"
        class="exportDataChartBox"
        :style="`height: ${enlarge == false ? '300px' : '70vh'}`"
      />
    </div>
  </el-card>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { exportData } from "@/api/chart";
import { pickerOptions } from "@/utils/utils";

export default {
  name: "exportData",
  data() {
    return {
      myChart: null,
      contrastActive: false,
      echartsViewId: "exportDataChartBox_",
      captureId: "captureSvene_",
      enlarge: false,
      exportLoading: false,
      chartTotal: 0,
      numberCountry: 0,
      echartsData: [],
      countDatas: [],
      data: {
        country: null,
        customer: "",
        itemNo: "",
        type: "",
        size: "",
        time: [dayjs().startOf("year"), dayjs()],
        dateType: "",
      },
      chartLoading: false,
    };
  },
  inject: ["getConfig"],
  props: {
    isHeader: {
      type: Boolean,
      default: true,
    },
    echartsType: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["country", "companyCurrentType"]),
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    },
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.chartLoading = true;
        this.onGetAmount();
      },
      deep: true,
    },
    "artConfig.echarts_type"() {
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    "artConfig.isAmountNumberMode"() {
      this.onGetAmount();
    },
    enlarge() {
      this.setEchartsOption(this.echartsData, this.data.dateType);
    },
  },
  mounted() {
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if (this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    setContrast() {
      let arrIndex = this.$parent.$parent.$parent.contrastConfig.findIndex(
        (item) => item.name === "exportData"
      );
      if (arrIndex !== -1) {
        this.$parent.$parent.$parent.contrastConfig.splice(arrIndex, 1);
        this.contrastActive = false;
        return;
      }
      if (this.$parent.$parent.$parent.contrastConfig.length >= 2) return;
      this.contrastActive = true;
      this.$parent.$parent.$parent.contrastConfig.push({
        name: "exportData",
        title: this.$t("Dashboard.Exportdata")+(`(${!this.artConfig.isAmountNumberMode ? this.$t('order.Amount') : this.$t('order.Quantity')})`),
        echartsApi: exportData,
        params: {
          companyType: this.companyCurrentType,
          iso3: this.data.iso3,
          companyId: this.data.companyId,
          itemNo: this.data.itemNo,
          dateType: this.data.dateType,
          queryType: this.artConfig.isAmountNumberMode
        },
        options: this.myChart.getOption(),
      });
    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document
          .getElementById(this.captureId)
          .classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true,
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL("image/png");
            // 下载图片
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = "我的文件";
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      let header = [
        ...chartOption.xAxis[0].data
      ];
      // let 
      let data = chartOption.series.map(item => {
        return item.data;
      });
      if(this.data.dateType) {
        header.unshift("");
        chartOption.series.forEach((item, index) => {
          data[index].unshift(item.name);
        });
      }
      let filename = this.$t("Dashboard.Exportdata");
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    onGetAmount() {
      this.chartLoading = true;
      const { dateType, time, country, customer, itemNo, type, size } =
        this.data;
      let startTime =
        time && time?.length
          ? dayjs(time[0]).format("YYYY-MM-DD HH:mm:ss")
          : "";
      let endTime =
        time && time?.length
          ? dayjs(time[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "";
      this.myChart = this.$echartsInit(
        document.getElementById(this.echartsViewId)
      );
      exportData({
        ...(dateType ? { dateType } : {}),
        startTime,
        endTime,
        country,
        customer,
        itemNo,
        type,
        size,
        queryType: this.artConfig.isAmountNumberMode
      })
        .then(({ data, statistics }) => {
          this.echartsData = data.map((item) => ({
            ...item,
            countryName: this.$isFindData(
              this.country,
              item.iso3,
              "value",
              "key"
            ),
          }));
          this.setEchartsOption(this.echartsData, dateType);
          this.countDatas = [
            {
              name: "",
              data: [
                [
                  this.$t("Dashboard.maxValue"),
                  statistics.max.value,
                  statistics.max.keys.join("，"),
                ],
                [
                  this.$t("Dashboard.minValue"),
                  statistics.min.value,
                  statistics.min.keys.join("，"),
                ],
                [
                  this.$t("Dashboard.avgValue"),
                  statistics.avg,
                  `${startTime}~${endTime}`,
                ],
              ],
            },
          ];
        })
        .finally(() => (this.chartLoading = false));
    },
    setEchartsOption(data, dateType) {
      let timeList = [];
      let seriesList = [];
      let countryArray = [];
      if (dateType) {
        let list = [];
        data.forEach((v) => {
          timeList.push(v.date);
          if (v.countryList) {
            v.countryList = v.countryList.map((item) => ({
              ...item,
              countryName: this.$isFindData(
                this.country,
                item.country,
                "value",
                "key"
              ),
            }));
            list.push(...v.countryList);
          }
        });
        let aggregate = _.groupBy(list, "countryName");
        for(let i in aggregate) {
          countryArray.push(i)
        }
        countryArray.forEach(country => {
          seriesList.push({
            name: country,
            data: []
          })
        })
        seriesList.forEach((item) => {
          timeList.forEach((time, idx) => {
            let data = list.filter(row => item.name == row.countryName && time == row.date)[0];
            item.data[idx] = data?.amount || 0;
          });
        });
      } else {
        seriesList = data.map((v) => {
          timeList.push(v.countryName);
          return Number(v.amount).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2);
        });
      }
      const seriesMark =
        !dateType && this.enlarge
          ? {
              markPoint: {
                data: [
                  {
                    type: "max",
                    name: "最大值",
                  },
                  {
                    type: "min",
                    name: "最小值",
                  },
                ],
              },
              markLine: {
                // 平均值
                data: [
                  {
                    type: "average",
                    name: "平均值",
                  },
                ],
              },
            }
          : {};
      this.myChart.setOption(
        {
          tooltip: {
            trigger: "axis",
            confine: true,
            axisPointer: {
              type: "shadow",
            },
            formatter: (params) => {
              var htmlStr = "";
              for (var i = 0; i < params.length; i++) {
                if(params[i].value != 0) {
                  htmlStr += `
                    <div class="content">
                      ${params[i].marker}
                      <div class="value">${this.data.dateType ? `${params[i].seriesName}:` : ''}${params[i].value}${!this.artConfig.isAmountNumberMode ? '(USD)' : ''}</div>
                    </div>
                  `;
                }
              }
              return `
                <div>${params[0].axisValue}</div>
                <div class="echartsTooltipBox">
                  ${htmlStr}
                </div>
                ${dateType ? `<div class="echartsChatTotal">
                  total：${params.map(item => Number(item.value)).reduce(function (prev, cur) {
                      return prev + cur;
                  }, 0).toFixed(this.artConfig.isAmountNumberMode ? 0 : 2)}
                </div>` : ''}
              `;
            },
          },
          grid: [
            {
              top: "5%",
              left: "3%",
              right: "3%",
              bottom: "14%",
              containLabel: true,
            },
            {},
          ],
          xAxis: [
            {
              type: "category",
              data: timeList,
              axisTick: {
                alignWithLabel: true,
                show: false,
              },
              axisLine: {
                lineStyle: { color: "#B0B8BF" },
              },
              // axisLabel: {
              //   interval: 0,
              //   rotate: 40,
              // },
            },
            { gridIndex: 1, show: false },
          ],
          yAxis: {
            type: "value",
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: { color: "#B0B8BF" },
              show: false,
            },
          },
          dataZoom: [
            {
              show: true,
              type: "slider",
              left: "center",
              bottom: "0%",
              start: 0,
              end: 100,
            },
          ],
          series: dateType
            ? seriesList.map((vo) => ({
                ...vo,
                type: "bar",
                stack: "vistors",
                emphasis: {
                  focus: 'series'
                },
                ...seriesMark,
              }))
            : [
                {
                  data: seriesList,
                  type: "bar",
                  barWidth: "60%",
                  ...seriesMark,
                },
              ],
        },
        true
      );
      this.myChart.off("click");
      this.myChart.on("click", (params) => {
        if (!this.data.dateType) {
          if (data[params.dataIndex]) {
            if (data[params.dataIndex].iso3 == "other") return;
            this.$confirm(
              this.$tc(
                "Dashboard.confirmSeeMessageFn",
                data[params.dataIndex].countryName
              ),
              this.$t("Tips"),
              {
                confirmButtonText: this.$t("Confirm"),
                cancelButtonText: this.$t("Cancel"),
                type: "warning",
              }
            )
              .then(() => {
                let iso3 = data[params.dataIndex].iso3;
                this.$router.push(`/dashboard/1?countryId=${iso3}`);
              })
              .catch(() => {});
          }
        }
      });
      window.addEventListener("resize", () => this.myChart.resize());
    },
    onActive(title) {
      if (this.data.dateType == title) {
        this.data.dateType = "";
      } else {
        this.data.dateType = title;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.exportDataChartBox {
  width: 100%;
  height: 350px;
}

.tips {
  width: 100px;
  height: 100px;
}
</style>
