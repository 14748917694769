<template>
  <div>
    <div class="basticInfo">
      <statistics ref="statisticsCustomerMarket" type="customer" />
      <customerStatistics ref="customerStatistics" />
    </div>
    <div class="Statistical_cart_box">
      <div class="top">
        <supplierVipData ref="componentTotal1" echartsType="2" />
        <supplierAmount ref="componentTotal2" echartsType="2" />
      </div>
      <div class="bottom">
        <exportProportion ref="componentTotal3" echartsType="2" />
        <skuAmount ref="componentTotal4" echartsType="2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import purchasAmount from '../components/purchasAmount.vue';
import statistics from '../components/statisticsVipCountryMarket.vue';
import customerStatistics from "../components/customerStatistics.vue"
import skuUnitPrice from '../components/skuUnitPrice.vue';
import skuAmount from '../components/skuAmount.vue';
import exchangeRate from '../components/exchangeRate.vue';
import commodityPrice from '../components/commodityPrice.vue';
import exportData from '../components/exportData.vue';
import exportProportion from '../components/exportProportion.vue';
import supplierAmount from '../components/supplierAmount.vue';
import supplierConparison from '../components/supplierConparison.vue';
import bdiIndexNumber from "../components/bdiIndexNumber.vue";
import orderStatusSummary from "../components/orderStatusSummary.vue";
import pmiIndexNumber from "../components/pmiIndexNumber.vue";
import supplierVipData from "../components/supplierVipData.vue";

export default {
  name: 'dashboardCustomer',
  components: {
    purchasAmount,
    statistics,
    customerStatistics,
    supplierConparison,
    supplierAmount,
    exportData,
    exportProportion,
    commodityPrice,
    exchangeRate,
    skuAmount,
    skuUnitPrice,
    bdiIndexNumber,
    orderStatusSummary,
    pmiIndexNumber,
    supplierVipData,
  },
  data() {
    return {
      dashboardList: [11, 5, 8, 9],
      dashboardObject: {
        0: pmiIndexNumber,
        1: orderStatusSummary,
        2: bdiIndexNumber,
        3: commodityPrice,
        4: supplierConparison,
        5: supplierAmount,
        6: exchangeRate,
        7: exportData,
        8: exportProportion,
        9: skuAmount,
        10: skuUnitPrice,
        11: supplierVipData
      }
    }
  },
  inject: ['getConfig', 'getTimeConfig'],
  computed: {
    ...mapGetters([]),
    actConfig() {
      return this.getConfig();
    },
    timeConfig() {
      return this.getTimeConfig();
    },
  },
  mounted() {
    this.setCustomerValue(this.actConfig.companyId);
  },
  watch: {
    'actConfig.companyId'(val) {
      this.setCustomerValue(val)
    },
    'timeConfig.globalTimeCustomer': {
      handler(val) {
        this.setTime(val, true);
      },
      deep: true
    }
  },
  methods: {
    setCustomerValue(companyId) {
      if(companyId) {
        this.$nextTick(() => {
          this.setTime(this.timeConfig.globalTimeCustomer, false);
          this.$refs.statisticsCustomerMarket.data = {
            ...this.$refs.statisticsCustomerMarket.data,
            customer: companyId,
            time: this.timeConfig.globalTimeCustomer
          };
          this.$refs.customerStatistics.customerValue = companyId;
          this.$refs.componentTotal1.data.customer = companyId;
          this.$refs.componentTotal2.data.customer = companyId;
          this.$refs.componentTotal3.data.customer = companyId;
          this.$refs.componentTotal4.data.customer = companyId;
        })
      }
    },
    setTime(val, globalTime) {
      if(globalTime) {
        this.$refs.statisticsCustomerMarket.data.time = val;
      }
      this.$refs.componentTotal1.data.time = val;
      this.$refs.componentTotal2.data.time = val;
      this.$refs.componentTotal3.data.time = val;
      this.$refs.componentTotal4.data.time = val;
    }
  }
}
</script>

<style lang="less" scoped>
</style>