<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">{{ $t('Dashboard.Exchangerate') }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <div 
          class="screen" 
          style="margin-top: 0;margin-right: 10px;" 
          v-if="artConfig.isVipMode != 0">
          <el-select
            class="m-2"
            placeholder="Select"
            size="small"
            appendToBody
            style="width: 150px"
            v-model="data.currency"
          >
            <el-option
              v-for="item in exchangeRate"
              :key="item.value"
              :label="item.key"
              :value="item.value"
            />
          </el-select>
        </div>
        <toolTipIcon 
          v-if="artConfig.isVipMode == 0"
          :icon="contrastActive ? 'el-icon-minus' : 'el-icon-plus'" 
          :tooltipText="$t('addContrast')"
          @click="setContrast()"
        />
        <toolTipIcon 
          v-if="artConfig.isVipMode == 0"
          icon="iconfont icon-download" 
          :tooltipText="$t('ExportFile')" 
          isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="onExchangeRate()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen" v-if="artConfig.isVipMode == 0">
      <el-select
        class="m-2"
        placeholder="Select"
        size="small"
        appendToBody
        style="width: 150px"
        v-model="data.currency"
      >
        <el-option
          v-for="item in exchangeRate"
          :key="item.value"
          :label="item.key"
          :value="item.value"
        />
      </el-select>
      <el-date-picker
        style="width: 260px"
        v-model="data.time"
        type="daterange"
        size="small"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
    </div>
    <el-row style="background: #fff; margin-top: 22px">
      <div
        :id="echartsViewId"
        class="exchangeRate"
        :style="`height: ${enlarge == false ? '300px' : '70vh'}`"
      />
    </el-row>
  </el-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { exchangeRate, getCountData } from '../../../api/chart';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { pickerOptions } from '../../../utils/utils';
export default {
  name: 'exchangeRate',
  components: {},
  data() {
    return {
      myChart: null,
      contrastActive: false,
      echartsViewId: 'exchangeRate_',
      captureId: 'captureSix_',
      enlarge: false,
      exportLoading: false,
      echartsData: [],
      countDatas: [],
      data: { 
        currency: 'CNY', 
        time: [dayjs().startOf('year'), dayjs()],
        country: null
      },
      chartLoading: false,
    };
  },
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  inject: ['getConfig'],
  computed: {
    ...mapGetters(['exchangeRate']),
    i18n() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.chartLoading = true;
        this.onExchangeRate();
      },
      deep: true,
    },
    'artConfig.echarts_type'() {
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    enlarge() {
      this.setEchartsOption(this.echartsData);
    }
  },
  mounted() {
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    setContrast() {
      let arrIndex = this.$parent.$parent.$parent.contrastConfig.findIndex(item => item.name === 'exchangeRate')
      if(arrIndex !== -1) {
        this.$parent.$parent.$parent.contrastConfig.splice(arrIndex, 1);
        this.contrastActive = false;
        return;
      }
      if(this.$parent.$parent.$parent.contrastConfig.length >= 2) return;
      this.contrastActive = true;
      this.$parent.$parent.$parent.contrastConfig.push({
        name: "exchangeRate",
        title: this.$t('Dashboard.Exchangerate'),
        echartsApi: exchangeRate,
        params: {
          currency: this.data.currency
        },
        options: this.myChart.getOption()
      })
    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document
          .getElementById(this.captureId).classList
          .add(
            'echarts_amplify'
          );
        document
          .getElementById('app')
          .setAttribute('style', 'overflow-y: hidden;');
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId).classList.remove("echarts_amplify");
        document
          .getElementById('app')
          .setAttribute('style', 'overflow-y: bolck;');
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = this.$t('Dashboard.Exchangerate');
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      console.log(chartOption);
      let header = [
        "",
        ...chartOption.series.map(item => item.name)
      ];
      let data = [];
      chartOption.series[0].data.forEach((item, index) => {
        data.push([item[0]])
      })
      data.forEach((item, index) => {
        header.forEach((row, idx) => {
          if(row) {
            item[idx] = chartOption.series[idx - 1].data[index][1];
          }
        })
      })
      let filename = this.$t('Dashboard.Exchangerate');
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    onExchangeRate() {
      this.chartLoading = true;
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      const { currency, time } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      exchangeRate({ currency, startTime, endTime })
        .then(({data: { data, currencyList }, statistics}) => {
          this.echartsData = data;
          this.setEchartsOption(this.echartsData, currencyList);
          this.chartLoading = false;
          for(let i in statistics) {
            this.countDatas.push({
              name: i,
              data: [
                [this.$t("Dashboard.maxValue"), statistics[i].max.value, statistics[i].max.keys.join("，")],
                [this.$t("Dashboard.minValue"), statistics[i].min.value, statistics[i].min.keys.join("，")],
                [this.$t("Dashboard.avgValue"), statistics[i].avg, `${startTime}~${endTime}`]
              ]
            })
          }
        })
        .finally(() => (this.chartLoading = false));
    },
    setEchartsOption(data, currency) {
      let aggregate = _.groupBy(data, 'currency');
      let list = [];
      for (let index in aggregate) {
        if (index != 'null') {
          list.push({
            name: index,
            data: aggregate[index].map((res) => {
              return [
                dayjs(res.date).format('YYYY-MM-DD'),
                Number(res.exchangeRate).toFixed(2),
              ];
            }),
          });
        }
      }
      const seriesMark = this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {}
      this.myChart.setOption({
        grid: [
          {
            top: 35,
            left: '2%',
            right: '7%',
            bottom: '14%',
            containLabel: true,
          },
        ],
        tooltip: {
          trigger: 'axis',
          confine: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          nameTextStyle: {},
          axisTick: {
            alignWithLabel: true,
            show: false,
          },
          axisLine: {
            lineStyle: { color: '#B0B8BF' },
          },
          axisLabel: {},
        },
        yAxis: {
          type: 'value',
          scale: true,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: { color: '#B0B8BF' },
            show: false,
          },
        },
        legend: {
          data: currency,
          selected: {
            'HKD': false,
            'GBP': false,
            'JPY': false,
          }
        },
        series: list.map((res) => {
          return {
            ...res,
            type: 'line',
            smooth: true,
            symbol: 'none',
            // areaStyle: {},
            showSymbol: false,
            animationEasing: 'cubicInOut',
            ...seriesMark
          };
        }),
        dataZoom: [
          {
            show: true,
            type: 'slider',
            left: 'center',
            bottom: '0%',
            start: 0,
            end: 100,
          },
        ],
      }, true);
      window.addEventListener('resize', () => {
        this.myChart.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>
#exchangeRate {
  width: 100%;
  height: 350px;
}
.Commodity.fullscreen .exchangeRate {
  width: 100%;
  height: 800px !important;
}
</style>
