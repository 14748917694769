<template>
  <div>
    <div v-if="productValue">
      <div class="basticInfo">
        <productStatistics ref="productStatistics" />
        <statistics ref="statisticsMarket" type="product" />
        <purchasAmount ref="componentProductTotals" echartsType="3" />
      </div>
      <draggable
        v-model="dashboardList"
        animation="200"
        ghostClass="ghost"
        group="people"
        handle=".Commodity .left_title"
        @end="updateDraggable"
      >
        <transition-group class="Statistical_cart_box">
          <div v-for="(item, index) in dashboardList" :key="item">
            <keep-alive>
              <component
                :is="dashboardObject[item]"
                ref="componentProductTotal"
                echartsType="3"
              ></component>
            </keep-alive>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div v-else class="empty_box">
      <el-card>
        <el-empty 
          :image="$emptyImage"
          :image-size="90"
        ></el-empty>
      </el-card>
    </div>
    <el-badge 
      v-if="contrastConfig.length"
      :value="contrastConfig.length" 
      :hidden="!contrastConfig.length" 
      class="contrastDialogBadge">
      <div class="contrastDialogBadgeBox" @click="seeContrastDialog"></div>
    </el-badge>
    <contrastDialog ref="contrastDialog" />
  </div>
</template>

<script>
import purchasAmount from '../components/purchasAmount.vue';
import statistics from '../components/statisticsCountryMarket.vue';
import productStatistics from "../components/productStatistics.vue"
import skuUnitPrice from '../components/skuUnitPrice.vue';
import skuAmount from '../components/skuAmount.vue';
import exchangeRate from '../components/exchangeRate.vue';
import commodityPrice from '../components/commodityPrice.vue';
import exportData from '../components/exportData.vue';
import exportProportion from '../components/exportProportion.vue';
import supplierAmount from '../components/supplierAmount.vue';
import supplierConparison from '../components/supplierConparison.vue';
import bdiIndexNumber from "../components/bdiIndexNumber.vue";
import pmiIndexNumber from "../components/pmiIndexNumber.vue";
import contrastDialog from '../dialog/contrastDialog.vue';
import { mapGetters } from 'vuex';
import { setPersonalization } from "@/api/common"
export default {
  name: 'dashboardCountry',
  components: {
    purchasAmount,
    statistics,
    productStatistics,
    supplierConparison,
    supplierAmount,
    exportData,
    exportProportion,
    commodityPrice,
    exchangeRate,
    skuAmount,
    skuUnitPrice,
    bdiIndexNumber,
    pmiIndexNumber,
    contrastDialog
  },
  data() {
    return {
      productValue: null,
      dashboardList: [],
      dashboardObject: {
        0: pmiIndexNumber,
        2: bdiIndexNumber,
        3: commodityPrice,
        4: supplierConparison,
        5: supplierAmount,
        6: exchangeRate,
        7: exportData,
        8: exportProportion,
        9: skuAmount,
        10: skuUnitPrice,
      },
      contrastConfig: []
    }
  },
  inject: ['getConfig', 'getTimeConfig'],
  computed: {
    ...mapGetters(["chartOrder", "personalization"]),
    actConfig() {
      return this.getConfig();
    },
    timeConfig() {
      return this.getTimeConfig();
    },
  },
  mounted() {
    this.setProductValue(this.actConfig.productId);
    this.$router.push("/dashboard/0")
  },
  watch: {
    chartOrder: {
      handler(val) {
        this.dashboardList = val.product.filter(item => item != 1);
      },
      deep: true,
      immediate: true
    },
    'actConfig.productId'(val) {
      this.setProductValue(val)
    },
    'timeConfig.globalTimeProduct': {
      handler(val) {
        this.setTime(val, true);
      },
      deep: true
    }
  },
  methods: {
    updateDraggable() {
      setPersonalization({
        ...this.personalization,
        dashboardOrder: `${this.chartOrder.all}|${this.chartOrder.country}|${this.chartOrder.product}|${this.dashboardList}`
      })
    },
    seeContrastDialog() {
      this.$refs.contrastDialog.dialogVisible = true;
      this.$refs.contrastDialog.contrastConfig = this.contrastConfig;
    },
    setProductValue(productId) {
      this.productValue = JSON.parse(productId || "{}");
      if(productId) {
        this.$nextTick(() => {
          this.setTime(this.timeConfig.globalTimeProduct, false);
          this.$refs.statisticsMarket.data = {
            ...this.$refs.statisticsMarket.data,
            itemNo: this.productValue.itemNo,
            size:  this.productValue.size,
            type:  this.productValue.type,
            time: this.timeConfig.globalTimeProduct
          };
          this.$refs.productStatistics.productValue = this.productValue;
          this.$refs.componentProductTotals.data = {
            ...this.$refs.componentProductTotals.data,
            itemNo: this.productValue.itemNo,
            size:  this.productValue.size,
            type:  this.productValue.type,
          }
          for (let i = 0; i < this.$refs.componentProductTotal.length; i++) {
            const element = this.$refs.componentProductTotal[i];
            console.log(element)
            if(element.echartsViewId === "skuUnitPrice_3") {
              element.data = {
                ...element.data,
                skuCode: [JSON.stringify(this.productValue)]
              };
              return;
            }
            element.data = {
              ...element.data,
              itemNo: this.productValue.itemNo,
              size:  this.productValue.size,
              type:  this.productValue.type,
            };
          }
        })
      }
    },
    setTime(val, globalTime) {
      if(globalTime) {
        this.$refs.statisticsMarket.data.time = val;
      }
      this.$refs.componentProductTotals.data.time = val;
      for (let i = 0; i < this.$refs.componentProductTotal.length; i++) {
        const element = this.$refs.componentProductTotal[i];
        element.data.time = val;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.empty_box {
  margin: 20px;
}
</style>