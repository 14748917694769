<template>
  <el-card 
    class="Commodity" 
    :class="isVipMode != 0 ? 'vipCard' : ''" 
    v-loading="chartLoading">
    <div class="left_title" style="cursor: auto;">
      <div class="title" v-if="topData">
        {{i18n == 'zh' ? topData.countryCn : topData.countryEn}}
        <img class="countryImage" :src="topData.nationalFlag" alt="">
      </div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon v-if="isVipMode == 0" icon="iconfont icon-download" :tooltipText="$t('ExportFile')" isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf()">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
          </template>
        </toolTipIcon>
      </div>
    </div>
    <div 
      id="exportCountryStatisticsPdf" 
      class="countryInfoBox" 
      v-if="topData && mapImageComputed" 
      :style="{
        'background-image': `url(${topData.nationalMap})`,
        'background-size': mapImageComputed
      }">
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Capital')}}</div>
        <div class="content">
          {{i18n == 'zh' ? topData.capitalCn : topData.capitalEn}}
        </div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Area')}}</div>
        <div class="content">{{ topData.areaKm }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Population')}}</div>
        <div class="content">{{ topData.population }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Currency')}}</div>
        <div class="content">{{ topData.currency }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Politics')}}</div>
        <div class="content">{{ topData.politics }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.GdpTotal')}}</div>
        <div class="content">{{ topData.gdp }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.GDP per capita')}}</div>
        <div class="content">{{ topData.gdpAvg }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Calling code')}}</div>
        <div class="content">{{ topData.callingcode }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Official languages')}}</div>
        <div class="content">{{ topData.language }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Province')}}</div>
        <div class="content" :title="topData.province">{{ topData.province }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Organization')}}</div>
        <div class="content" :title="topData.organization">{{ topData.organization }}</div>
      </div>
      <div class="infoItem">
        <div class="label">{{$t('Dashboard.Religion')}}</div>
        <div class="content" :title="topData.religion">{{ topData.religion }}</div>
      </div>
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import html2canvas from "html2canvas";

export default {
  name: "statistics",
  data() {
    return {
      echartsViewId: "exportCountryStatisticsPdf",
      exportLoading: false,
      topData: null,
      countryValue: "",
      chartLoading: false,
      year: dayjs().format("YYYY") - 1,
      season: Math.ceil(dayjs().format("MM") / 3) - 1 || 4,
      mapImageComputed: ''
    };
  },
  inject: ['getConfig'],
  props: [],
  computed: {
    i18n() {
      return this.$i18n.locale;
    },
    isVipMode() {
      return this.getConfig().isVipMode;
    }
  },
  watch: {
    topData: {
      handler() {
        this.computedImgSize();
      },
      deep: true
    }
  },
  methods: {
    computedImgSize() {
      let imgObj = new Image();
      imgObj.src = this.topData?.nationalMap || '';
      imgObj.onload = () => {
        if(imgObj.width > imgObj.height) this.mapImageComputed = '65%';
        if(imgObj.width < imgObj.height) this.mapImageComputed = '50%';
      };
    },
    //导出(pdf)
    savePdf() {
      this.$refs.echartsBtns.style.display = "none";
      setTimeout(() => {
        // setAttribute 需要一定时间生效
        html2canvas(document.getElementById("exportCountryStatisticsPdf"), {
          useCORS: true
        }).then((canvas) => {
          this.$refs.echartsBtns.style.removeProperty('display');
          const oImg = new Image();
          oImg.setAttribute('crossOrigin', 'anonymous')
          oImg.src = canvas.toDataURL(); // 导出图片
          let dataURL = canvas.toDataURL("image/png");
          // 下载图片
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = oImg.src;
          // 设置下载标题
          a.download = "我的文件";
          a.click();
        });
      }, 200);
    },
  },
};
</script>
<style lang="less" scoped>
</style>
