
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  methods: {
    //导出(pdf)
    savePdf(val) {
      const loading = this.$loading({
        lock: true,
        text: this.$t('Files.ExportLoading'),
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255)',
        customClass: 'loadingPdf'
      });
      if(val === 'png') {
        this.exportLoading = true;
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(this.$refs.exportPdf, {
            useCORS: true
          }).then((canvas) => {
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL("image/png");
            // 下载图片
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = "我的文件";
            a.click();
            this.exportLoading = false;
            loading.close();
          });
        }, 200);
        return;
      }
      this.exportLoading = true;
      let viewList = [];
      let echartsTypeRefs = ""
      if (this.echarts_type === "0") {
        echartsTypeRefs = "All";
      }
      if (this.echarts_type === "1") {
        echartsTypeRefs = "Country";
      }
      if (this.echarts_type === "2") {
        echartsTypeRefs = "Customer";
      }
      if (this.echarts_type === "3") {
        echartsTypeRefs = "Product";
      }
      for (let i in this.$refs['dashboard'+echartsTypeRefs].$refs) {
        if (i !== "contrastDialog") {
          if (!Array.isArray(this.$refs['dashboard'+echartsTypeRefs].$refs[i])) {
            if (i === "componentTotal" || i === "componentCountryTotals" || i === 'componentCustomerTotals' || i === 'componentProductTotals') {
              viewList.push({
                id: i,
                echartsType: "line",
                name: this.$refs['dashboard'+echartsTypeRefs].$refs[i].$el.innerText
                  .split("\n")[0]
                  .trim(),
                refs: this.$refs['dashboard'+echartsTypeRefs].$refs[i],
              });
            } else {
              viewList.push({
                id: i,
                name: this.$refs['dashboard'+echartsTypeRefs].$refs[i].$el.innerText
                  .split("\n")[0]
                  .trim(),
                refs: this.$refs['dashboard'+echartsTypeRefs].$refs[i],
              });
            }
          } else {
            this.$refs['dashboard'+echartsTypeRefs].$refs[i].forEach((row, index) => {
              let echartsType = "";
              if (row.myChart) {
                console.log(row.myChart);
                if (Array.isArray(row.myChart._model.option.series)) {
                  if(row.myChart._model.option.series.length) {
                    echartsType = row.myChart._model.option.series[0].type;
                  } else {
                    echartsType = "void"
                  }
                } else {
                  echartsType = row.myChart._model.option.series.type;
                }
              } else {
                if(row.chart) {
                  if (row.isPieChart) {
                    if (Array.isArray(row.chart._model.option.series)) {
                      echartsType = row.chart._model.option.series[0].type;
                    } else {
                      echartsType = row.chart._model.option.series.type;
                    }
                  } else {
                    if (Array.isArray(row.chartTwo._model.option.series)) {
                      echartsType = row.chartTwo._model.option.series[0].type;
                    } else {
                      echartsType = row.chartTwo._model.option.series.type;
                    }
                  }
                }
              }
              viewList.push({
                id: i + "_" + index,
                echartsType,
                name: row.$el.innerText.split("\n")[0].trim(),
                refs: row,
              });
            });
          }
        }
      }
      viewList = viewList.map((item, index) => ({
        ...item,
        type:
          item.id.split("_")[0] === `component${echartsTypeRefs === 'All' ? '' : echartsTypeRefs}Total` ||
          item.id === `component${echartsTypeRefs === 'All' ? '' : echartsTypeRefs}Totals`
            ? "echarts"
            : "text",
        sortId: index,
      })).filter(item => item.echartsType != 'void');
      setTimeout(async () => {
        let viewListMap = await new Promise(async (resolve, reject) => {
          let arr = [];
          await new Promise((res, rej) => {
            viewList.forEach((item) => {
              if (item.type !== "text") {
                item.refs.onEnlarge();
              }
              setTimeout(() => {
                html2canvas(
                  document.getElementById(
                    item.refs.echartsViewId ||
                      (item.refs?.isPieChart
                        ? item.refs.echartsViewPieId
                        : item.refs.echartsViewBarId)
                  ),
                  {
                    useCORS: true,
                    onclone: async (element) => {},
                  }
                ).then(async (canvas) => {
                  let pageData = canvas.toDataURL("image/png");
                  let imgObj = new Image();
                  imgObj.src = pageData;
                  let promiseHeight = await new Promise((resolve) =>{
                    imgObj.onload = () => {
                      console.log(imgObj.width+' '+imgObj.height+' '+imgObj.height*0.2+' '+Number(imgObj.height*0.2))
                      resolve(190*imgObj.height/imgObj.width)
                    }
                  })
                  arr.push({
                    ...item,
                    promiseHeight,
                    image: pageData,
                  });
                  if (viewList.length === arr.length) {
                    res("");
                  }
                  if (item.type !== "text") {
                    item.refs.onEnlarge();
                  }
                });
              }, 3000);
            });
          });
          resolve(arr);
        });
        viewListMap = viewListMap.sort((a, b) => a.sortId - b.sortId);
        var PDF = new jsPDF();
        PDF.setFont("msyh");
        const recursion = async (index = 0) => {
          if(index === viewListMap.length - 1) return;
          console.log(index)
          let item = viewListMap[index];
          if (index) {
            PDF.addPage();
          }
          PDF.setFontSize(30);
          PDF.setTextColor(92, 92, 92);
          PDF.text(item.name, 10, 20);
          PDF.addImage(item.image, "JPEG", 5, 30, 200, item.promiseHeight);
          if (item.type === "echarts") {
            let time1 = this.$dayjs(item.refs.data.time[0]).format("YYYY-MM-DD");
            let time2 = this.$dayjs(item.refs.data.time[1]).format("YYYY-MM-DD");
            PDF.setFontSize(22);
            PDF.setTextColor(92, 92, 92);
            PDF.text(this.$t("Dashboard.Date Range")+':', 10, item.promiseHeight + 40);
            PDF.text(time1, 50, item.promiseHeight + 40);
            PDF.text("~", 98.2, item.promiseHeight + 40);
            PDF.text(time2, 105, item.promiseHeight + 40);
            if (item.echartsType === "pie") {
              let rightArray = item.refs.$el
                .querySelector(".statistics")
                .innerText.split("\n");
              PDF.setFontSize(18);
              PDF.setTextColor(144, 147, 153);
              PDF.text(rightArray[0].trim(), 20, item.promiseHeight + 60);
              PDF.text(rightArray[2].trim(), 20, item.promiseHeight + 100);
              PDF.text(rightArray[4].trim(), 20, item.promiseHeight + 140);
              PDF.setFontSize(23);
              PDF.setTextColor(51, 51, 51);
              PDF.text(rightArray[1].trim(), 20, item.promiseHeight + 75);
              PDF.text(rightArray[3].trim(), 20, item.promiseHeight + 115);
              PDF.text(rightArray[5].trim(), 20, item.promiseHeight + 155);
            } else {
              if(item.refs.countDatas && item.refs.countDatas.length > 0) {
                let startY = item.promiseHeight + 60;
                let first = null
                item.refs.countDatas.forEach((row, idx) => {
                  // if(idx === 2) {
                  //   startY = 0;
                  // }
                  // if(idx >= 3) {
                  //   startY = (item.promiseHeight + 60 - 30 * (idx - (2+(idx - ((idx*2)/((idx*2)/((Number.isInteger(idx/2) ? idx/2 : idx)))))))) / idx;
                  // }
                  PDF.autoTable({
                    //添加表格
                    startX: 20, //pdf-x轴位置 水平
                    startY: first ? (first.finalY - 170) + startY : startY, //pdf-y轴位置 垂直
                    // pageBreak: first ? 'always' : 'auto',
                    head: [
                      //表格头
                      [
                        row.name,
                        this.$t('Dashboard.numberValue'),
                        this.$t('Dashboard.CorrespondingXCoordinate'),
                      ],
                    ],
                    columnStyles: { 
                      1: { cellWidth: 50 } 
                    },
                    body: row.data,
                    styles: { font: "msyh", fontSize: 14 }, //设置表格字体
                    headStyles: {
                      //设置表头样式
                      fillColor: "#FFE4C4", //背景颜色
                      textColor: "#333", //文字颜色
                      minCellHeight: 10,
                      valign: 'middle',
                    },
                    bodyStyles: {
                      minCellHeight: 30,
                      valign: 'middle',
                    }
                  });
                  first = PDF.autoTable.previous;
                })
              }
            }
          }
          recursion(index+1);
        }
        recursion(0);
        PDF.save("数据看板报表.pdf");
        this.exportLoading = false;
        loading.close();
      }, 200);
    },
  }
}