<template>
  <el-card
    class="Commodity"
    v-loading="chartLoading"
    :id="captureId"
  >
    <div class="left_title">
      <div class="title">{{ $t("Dashboard.CommodityPrice") }}</div>
      <div class="echartsBtns" ref="echartsBtns">
        <toolTipIcon 
          v-if="artConfig.isVipMode == 0"
          :icon="contrastActive ? 'el-icon-minus' : 'el-icon-plus'" 
          :tooltipText="$t('addContrast')"
          @click="setContrast()"
        />
        <toolTipIcon 
          v-if="artConfig.isVipMode == 0"
          icon="iconfont icon-download" 
          :tooltipText="$t('ExportFile')" 
          isPopover>
          <template slot="popperMenuItem">
            <div class="popperMenuItem" @click="savePdf('png')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportPng') }}</span>
            </div>
            <div class="popperMenuItem" @click="savePdf('excel')">
              <i class="el-icon-download" />
              <span>{{ $t('Files.ExportExcel') }}</span>
            </div>
          </template>
        </toolTipIcon>
        <toolTipIcon 
          icon="iconfont icon-shuaxin" 
          :tooltipText="$t('Refresh')"
          @click="getSkuSearchList()"
        />
        <toolTipIcon 
          :icon="`iconfont ${ enlarge ? 'icon-shouqi' : 'icon-zuidahua' }`" 
          :tooltipText="enlarge ? $t('retract') : $t('expand')"
          @click="onEnlarge()"
        />
      </div>
    </div>
    <div class="screen" v-if="artConfig.isVipMode == 0">
      <el-select
        size="small"
        v-model="data.codes"
        filterable
        clearable
        multiple
        appendToBody
        collapse-tags
        style="width: 270px;"
        :placeholder="$t('Dashboard.Matter')"
      >
        <el-option
          v-for="item in skuList"
          :key="item.value"
          :label="item.key"
          :value="item.value"
        />
      </el-select>
      <el-date-picker
        style="width: 260px"
        v-model="data.time"
        size="small"
        type="daterange"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
    </div>
    <div style="margin-top: 22px">
      <div
        :id="echartsViewId"
        class="commodityPrice"
        :style="`height: ${enlarge == false ? '300px' : '70vh'}`"
      ></div>
    </div>
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import { pickerOptions } from "@/utils/utils";
import { statisticalChart, getSkuSearchList, getCountData } from "@/api/chart";
export default {
  name: 'commodityPrice',
  components: {},
  data() {
    return {
      myChart: null,
      enlarge: false,
      contrastActive: false,
      echartsViewId: 'commoditysPrice_',
      captureId: 'capturesThree_',
      isFullscreen: false, // 是否为全屏状态,
      echartsData: [],
      countDatas: [],
      data: {
        codes: [],
        time: [dayjs().startOf('year'), dayjs()],
        country: null
      },
      chartLoading: false,
      skuList: [],
      codes: []
    };
  },
  props: {
    echartsType: {
      type: String,
      default: ""
    }
  },
  inject: ['getConfig'],
  computed: {
    ...mapGetters(['mysteelRawMaterial']),
    in18() {
      return this.$i18n.locale;
    },
    pickerData() {
      return pickerOptions();
    },
    artConfig() {
      return this.getConfig();
    }
  },
  watch: {
    data: {
      handler(obj) {
        if(this.$route.name != 'Dashboard') return;
        this.chartLoading = true;
        this.getSkuSearchList();
      },
      deep: true,
    },
    in18() {},
    'artConfig.echarts_type'() {
      this.myChart.resize();
    },
    enlarge() {
      this.setEchartsOption(this.echartsData);
    }
  },
  mounted() {
    this.echartsViewId = this.echartsViewId + this.echartsType;
    this.captureId = this.captureId + this.echartsType;
  },
  activated() {
    if(this.myChart) {
      this.myChart.resize();
    }
  },
  methods: {
    setContrast() {
      let arrIndex = this.$parent.$parent.$parent.contrastConfig.findIndex(item => item.name === 'commodityPrice')
      if(arrIndex !== -1) {
        this.$parent.$parent.$parent.contrastConfig.splice(arrIndex, 1);
        this.contrastActive = false;
        return;
      }
      if(this.$parent.$parent.$parent.contrastConfig.length >= 2) return;
      this.contrastActive = true;
      this.$parent.$parent.$parent.contrastConfig.push({
        name: "commodityPrice",
        title: this.$t("Dashboard.CommodityPrice"),
        params: {
          codes: "ID00411607,ID00411608"
        },
        echartsApi: statisticalChart,
        options: this.myChart.getOption()
      })
    },
    getSkuSearchList() {
      this.skuList = this.mysteelRawMaterial;
      this.codes = this.skuList.map(item => item.value);
      this.onStatisticalChart();
    },
    onEnlarge() {
      if (this.enlarge == false) {
        this.enlarge = true;
        document
          .getElementById(this.captureId)
          .classList.add("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: hidden;");
      } else {
        this.enlarge = false;
        document
          .getElementById(this.captureId)
          .classList.remove("echarts_amplify");
        document
          .getElementById("app")
          .setAttribute("style", "overflow-y: bolck;");
      }
      setTimeout(() => {
        this.myChart.resize();
      }, 1);
    },
    //导出(pdf)
    savePdf(type) {
      if(type == 'png') {
        this.$refs.echartsBtns.style.display = "none";
        setTimeout(() => {
          // setAttribute 需要一定时间生效
          html2canvas(document.getElementById(this.captureId), {
            useCORS: true
          }).then((canvas) => {
            this.$refs.echartsBtns.style.removeProperty('display');
            const oImg = new Image();
            oImg.src = canvas.toDataURL(); // 导出图片
            let dataURL = canvas.toDataURL('image/png');
            // 下载图片
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.href = oImg.src;
            // 设置下载标题
            a.download = '我的文件';
            a.click();
          });
        }, 200);
        return;
      }
      let chartOption = this.myChart.getOption();
      let header = [
        "",
        ...chartOption.series.map(item => item.name)
      ];
      let data = [];
      chartOption.xAxis[0].data.forEach((item, index) => {
        data.push([item])
      })
      data.forEach((item, index) => {
        header.forEach((row, idx) => {
          if(row) {
            item[idx] = chartOption.series[idx - 1].data[index].value;
          }
        })
      })
      let filename = this.$t("Dashboard.CommodityPrice");
      this.$export_json_to_excel({
        header,
        data,
        filename,
      });
    },
    onStatisticalChart() {
      this.chartLoading = true;
      this.myChart = this.$echartsInit(document.getElementById(this.echartsViewId));
      const { codes, time, } = this.data;
      let startTime =
        time && time?.length ? dayjs(time[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      let endTime =
        time && time?.length ? dayjs(time[1]).endOf("day").format('YYYY-MM-DD HH:mm:ss') : '';
      statisticalChart({ 
        codes: codes.length ? codes.join(",") : this.codes.join(","), 
        startTime, 
        endTime 
      })
        .then(({data, statistics}) => {
          this.echartsData = data;
          this.setEchartsOption(data);
          this.chartLoading = false;
          this.countDatas = [{
            name: "",
            data: [
              [this.$t("Dashboard.maxValue"), statistics.max.value, statistics.max.keys.join("，")],
              [this.$t("Dashboard.minValue"), statistics.min.value, statistics.min.keys.join("，")],
              [this.$t("Dashboard.avgValue"), statistics.avg, `${startTime}~${endTime}`]
            ]
          }]
        })
        .finally(() => (this.chartLoading = false));
    },
    setEchartsOption(data) {
      let arr = [];
      data.forEach((vo) => arr.push(...vo.data));
      let aggregate = _.groupBy(arr, 'code');
      let seriesList = [];
      for (let index in aggregate) {
        seriesList.push({
          name: `${aggregate[index][0].cpShortName}-${aggregate[index][0].mqName}-${index}`,
          data: aggregate[index].map((v) => {
            return {
              name: v.name,
              value: v.value,
            };
          }),
        });
      }
      const seriesMark = this.enlarge ? {
        markPoint: {
          data: [
            {
              type: 'max',
              name: '最大值'
            },
            {
              type: 'min',
              name: '最小值'
            }
          ]
        },
        markLine: { // 平均值
          data: [{
              type: 'average',
              name: '平均值'
          }]
        },
      } : {}
      this.myChart.setOption(
        {
          grid: [
            {
              top: 50,
              left: '2%',
              right: '7%',
              bottom: '14%',
              containLabel: true,
            },
          ],
          tooltip: {
            trigger: 'axis',
            confine: true,
            formatter: function (params, ticket, callback) {
              var htmlStr = '';
              for (var i = 0; i < params.length; i++) {
                htmlStr += `
                  <div class="content">
                    ${params[i].marker}
                    <div class="value">${params[i].seriesName}：${params[i].value}(USD)</div>
                  </div>
                `;
              }
              return `
                <div>${params[0].axisValue}</div>
                <div class="echartsTooltipBox">
                  ${htmlStr}
                </div>
              `;
            },
          },
          yAxis: {
            type: 'value',
            scale: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
              show: false,
            },
          },
          xAxis: {
            data: data.map((vo) => {
              return dayjs(vo.date).format('YYYY-MM-DD');
            }),
            // type: 'category',
            boundaryGap: false,
            nameTextStyle: {},
            axisTick: {
              alignWithLabel: true,
              show: false,
            },
            axisLine: {
              lineStyle: { color: '#B0B8BF' },
            },
            axisLabel: {},
          },
          legend: {
            type: 'scroll',
            data: seriesList.map((v) => v.name),
          },
          series: seriesList.map((vo) => {
            return {
              ...vo,
              type: 'line',
              smooth: true,
              symbol: 'none',
              // areaStyle: {},
              showSymbol: false,
              animationEasing: 'cubicInOut',
              ...seriesMark
            };
          }),
          dataZoom: [
            {
              show: true,
              type: 'slider',
              left: 'center',
              bottom: '0%',
              start: 0,
              end: 100,
            },
          ],
        },
        true
      );

      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    }
  },
};
</script>
<style lang="less" scoped>
#commodityPrice {
  width: 100%;
  height: 350px;
}

.Commodity.fullscreen .commodityPrice {
  width: 100%;
  height: 800px !important;
}
</style>
